import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OperationComponent implements OnInit {

  @Input() project;
  @Input() budget;

  public loadedData = false

  private operations = []
  private tempOperationData = [];
  public operationRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 5;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor() { }

  retrieveOperations() {
    if (this.project) {
      for (let b = 0; b < this.project.budgets.length; b++) {
        for (let o = 0; o < this.project.budgets[b].operations.length; o++) {
          this.operations.push(this.project.budgets[b].operations[o]);
        }
      }
    }

    if (this.budget) {
      this.operations = this.budget.operations
    }

    this.loadedData = true
    this.tempOperationData = this.operations;
    this.operationRows = this.operations;
    this.exportCSVData = this.operationRows.map(({ proofs, status, bankOperation_id, bankOperation_document, budget_id, ...item }) => item);
  }

  ngOnInit(): void {
    this.retrieveOperations()
  }

  filterOperations(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempOperationData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.operationRows = temp;
    let listToExport = this.operationRows.map(({ proofs, status, bankOperation_id, bankOperation_document, budget_id, ...item }) => item);
    this.exportCSVData = listToExport
  }

  getAll() {
    this.operationRows = this.operations;
  }

  filterByLabel(label) {
    const val = label.toLowerCase();
    const temp = this.tempOperationData.filter(function (d) {
      return d.label.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.operationRows = temp;
  }

}