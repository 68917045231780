<div class="text-center" *ngIf="!currentBudget">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="currentBudget">
    <div>
        <div class="text-center">
            <h1 class="mt-1">Cash payement</h1>
        </div>
        <div class='row justify-content-center'>
            <div class="card col-3 me-1">
                <div class="card-body pb-50">
                    <div class='row'>
                        <div class='col-6'>
                            <h6>Cash balance</h6>
                            <h4 class="fw-bolder mb-1">{{ currentBudget.cash_amount }} Dt</h4>
                        </div>
                        <div class='col-6'>
                            <img class='float-end' width="100"
                                src="https://img.freepik.com/premium-vector/banknote-gold-coins-with-silver-coins_383392-209.jpg?w=740 " />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card col-3 mx-1">
                <div class="card-body pb-50 ">
                    <div class='row'>
                        <div class='col-6'>
                            <h6>Bank balance</h6>
                            <h4 class="fw-bolder mb-1">{{ currentBudget.bank_amount }} Dt</h4>
                        </div>
                        <div class='col-6'>
                            <img class='float-end' width="80"
                                src="https://img.freepik.com/vecteurs-premium/cartes-credit-icone-carte-debit-credit-vecteur-vues-avant-arriere-systeme-technologie-paiement-sans-contact-maquettes-vectorielles-carte-credit-payer-acheter_435184-640.jpg?w=1060" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <h4>Please provide the operation informations: </h4>
        </div>
        <div class="card-body">
            <form [formGroup]="createPayementOperationForm" class="form form-horizontal"
                (ngSubmit)="confirmOperation()">
                <div class="col-12">
                    <label>Choose the budget to receive:</label>
                    <div class="row justify-content-center text-center">
                        <div class="card col-3" *ngFor="let budget of budgets">
                            <label>
                                <input type="radio" value="{{budget.id}}" class="card-input-element"
                                    formControlName="budget_receiver" />
                                <div class="card card-input border-secondary bg-light p-1">
                                    <h4>{{ budget.title }}</h4>
                                    In this budget you have
                                    <span><b>{{ budget.cash_amount }} DT</b> in cash </span>
                                    and
                                    <span><b>{{ budget.cash_amount + budget.bank_amount }} DT</b> in total.</span>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Operation name:</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="text" class="form-control" formControlName="title"
                                [ngClass]="{ 'is-invalid': payementOperationSubmitted && p_o.title.errors }" />
                            <div *ngIf="payementOperationSubmitted && p_o.title.errors" class="invalid-feedback">
                                <div *ngIf="p_o.title.errors.required">Operation name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Note</label>
                        </div>
                        <div class="col-sm-6">
                            <textarea class="form-control" formControlName="note"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Operation reference (cheque or transfer number):</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="text" class="form-control" formControlName="reference"
                                [ngClass]="{ 'is-invalid': payementOperationSubmitted && p_o.reference.errors }" />
                            <div *ngIf="payementOperationSubmitted && p_o.reference.errors" class="invalid-feedback">
                                <div *ngIf="p_o.reference.errors.required">Operation reference is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Domain</label>
                        </div>
                        <div class="col-sm-6">
                            <select formControlName="domain" class="form-control"
                                [ngClass]="{ 'is-invalid': payementOperationSubmitted && p_o.domain.errors }">
                                <ng-container *ngFor="let subdomain of subdomains">
                                    <optgroup label="{{subdomain.domain.title}}">
                                        <option value="{{subdomain.domain.id}}">
                                            {{ subdomain.title }}
                                        </option>
                                    </optgroup>
                                </ng-container>
                            </select>
                            <div *ngIf="payementOperationSubmitted && p_o.domain.errors" class="invalid-feedback">
                                <div *ngIf="p_o.domain.errors.required">Domain is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Operation date</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="date" class="form-control" formControlName="operation_date" />
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Amount</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="number" class="form-control" formControlName="amount"
                                [ngClass]="{ 'is-invalid': payementOperationSubmitted && p_o.amount.errors }" />
                            <div *ngIf="payementOperationSubmitted && p_o.amount.errors" class="invalid-feedback">
                                <div *ngIf="p_o.amount.errors.required">Amount is required</div>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
                <app-proof objectType="budget" [objectId]="currentBudget.id"
                    (checkProof)="handleProof($event)"></app-proof>
                <div class="card-footer text-right">
                    <button type="submit" class="btn btn-success" [disabled]="loadingSubmitCashPayement">
                        <span *ngIf="loadingSubmitCashPayement" class="spinner-border spinner-border-sm mr-1"></span>
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>