import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { DomainService } from 'app/service/domain/domain.service';
import { AlertService } from 'app/service/alert/alert.service';

@Component({
  selector: 'app-domain-datatable',
  templateUrl: './domain-datatable.component.html',
  styleUrls: ['./domain-datatable.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DomainDatatableComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);

  public currentDomain

  public domains;
  private tempDomainData = [];
  public domainRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 5;


  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private domainService: DomainService,
  ) { }

  calculateExpensesInDomain(operations) {
    let sum = 0
    for (let o = 0; o < operations.length; o++) {
      sum += parseFloat(operations[o].amount)
    }
    return sum
  }

  retrieveDomains(): void {
    this.domainService.getDomainsByAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.domains = data;
          this.tempDomainData = this.domains;
          this.domainRows = this.domains;
          this.exportCSVData = this.domains;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.domainService.refreshDomain.subscribe(() => {
      this.retrieveDomains()
    })
    this.retrieveDomains();
  }

  filterDomains(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempDomainData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.domainRows = temp;
  }
}
