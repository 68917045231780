<div class="card">
    <div class="card-header">
        <h4 style="color: #FF649D">New budget for association: </h4>
    </div>
    <div class="card-body">
        <form [formGroup]="createBudgetForm" class="form form-horizontal">
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Title</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="text" class="form-control" formControlName="title" placeholder="Title"
                                [ngClass]="{ 'is-invalid': budgetSubmitted && b.title.errors }" />
                            <div *ngIf="budgetSubmitted && b.title.errors" class="invalid-feedback">
                                <div *ngIf="b.title.errors.required">Title is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Type</label>
                        </div>
                        <div class="col-sm-6">
                            <ng-select formControlName="type"
                                [ngClass]="{ 'is-invalid': budgetSubmitted && b.type.errors }">
                                <ng-option value="cash">Cash</ng-option>
                                <ng-option value="bank">Bank</ng-option>
                                <ng-option value="cash_bank">Cash and Bank</ng-option>
                            </ng-select>
                            <div *ngIf="budgetSubmitted && b.type.errors" class="invalid-feedback">
                                <div *ngIf="b.type.errors.required">Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12"
                    *ngIf="createBudgetForm.get('type').valid && createBudgetForm.get('type').value != 'cash'">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Account</label>
                        </div>
                        <div class="col-sm-6">
                            <ng-select formControlName="account"
                                [required]="createBudgetForm.get('type').valid && createBudgetForm.get('type').value != 'cash'"
                                [ngClass]="{ 'is-invalid': budgetSubmitted && b.account.errors }">
                                <ng-option *ngFor="let account of accounts" value="{{account.id}}">
                                    {{ account.bank_name }}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="budgetSubmitted && b.account.errors" class="invalid-feedback">
                                <div *ngIf="b.account.errors.required">Account is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Amount</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="number" class="form-control" formControlName="initial_amount"
                                placeholder="Amount"
                                [ngClass]="{ 'is-invalid': budgetSubmitted && b.initial_amount.errors }" />
                            <div *ngIf="budgetSubmitted && b.initial_amount.errors" class="invalid-feedback">
                                <div *ngIf="b.initial_amount.errors.required">Amount is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="card-footer text-right">
            <button type="submit" class="btn btn-primary" [disabled]="loadingSubmitBudget" (click)="submitBudget()">
                <span *ngIf="loadingSubmitBudget" class="spinner-border spinner-border-sm mr-1"></span>
                Submit
            </button>
        </div>
    </div>
</div>