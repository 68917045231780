import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { ActivityService } from 'app/service/activity/activity.service';
import { AlertService } from 'app/service/alert/alert.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  @Input() project;

  public loadedData = false
  public currentActivity

  public activities;
  private tempActivityData = [];
  public activityRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 5;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public activityForm: FormGroup;
  public activitySubmitted = false
  public loadingSubmitActivity = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private activityService: ActivityService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private alertService: AlertService
  ) { }

  // convenience getter for easy access to activity form fields
  get a() {
    return this.activityForm.controls;
  }

  calculateExpensesInActivity(operations) {
    let sum = 0
    for (let o = 0; o < operations.length; o++) {
      sum += parseFloat(operations[o].amount)
    }
    return sum
  }

  deleteActivity(res, id): void {
    if (res.value == true) {
      this.activityService.delete(id)
        .subscribe({
          next: (data) => {
            console.log(data)
          },
          error: (e) => console.error(e)
        });
    }
  }

  updateActivity() {
    this.activitySubmitted = true
    if (this.activityForm.invalid) {
      return
    } else {
      this.loadingSubmitActivity = true
      this.activityService.update(this.currentActivity.id, this.a.title.value, this.a.description.value)
        .subscribe({
          next: (response) => {
            this.activitySubmitted = false
            this.loadingSubmitActivity = false
            this.modalService.dismissAll()
            this.activityForm.reset()
          },
          error: (e) => {
            this.loadingSubmitActivity = false
            this.activitySubmitted = false
            console.error(e)
          }
        });
    }
  }

  createActivity() {
    this.activitySubmitted = true
    if (this.activityForm.invalid) {
      return
    } else {
      this.loadingSubmitActivity = true
      this.activityService.create(this.project, this.a.title.value, this.a.description.value)
        .subscribe({
          next: (response) => {
            this.activitySubmitted = false
            this.loadingSubmitActivity = false
            this.modalService.dismissAll()
            this.activityForm.reset()
          },
          error: (e) => {
            this.loadingSubmitActivity = false
            this.activitySubmitted = false
            console.error(e)
          }
        });
    }
  }

  submitActivity() {
    if (this.currentActivity) {
      this.updateActivity()
    } else {
      this.createActivity()
    }
  }

  retrieveActivities(): void {
    this.activityService.getActivitiesOfProject(this.project)
      .subscribe({
        next: (data) => {
          this.activities = data;
          this.loadedData = true
          this.tempActivityData = this.activities;
          this.activityRows = this.activities;
          this.exportCSVData = this.activities;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.activityService.refreshActivity.subscribe(() => {
      this.retrieveActivities()
    })
    this.retrieveActivities();

    //activity
    this.activityForm = this._formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  modalOpen(modalForm, activity) {
    this.currentActivity = null
    this.activityForm.reset()
    this.modalService.open(modalForm);
    if (activity) {
      this.currentActivity = activity
      this.activityForm.patchValue({
        title: activity.title,
        description: activity.description,
      });
    }
  }

  filterActivities(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempActivityData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.activityRows = temp;
  }

  onSelectActivity({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
  }

  confirmDeleteAlert(activity) {
    let text = ''
    let operationName = ''
    if (activity.operations.length != 0) {
      operationName = activity.operations.map(function (item) { return item.title; }).join(", ");
      this.alertService.errorAlert(`Cant delete ${activity.title}`, `This line is related to ${operationName} operations !`)
    } else {
      Swal.fire({
        title: `Are you sure you want to delete ${activity.title}?`,
        text: 'You wont be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(res => this.deleteActivity(res, activity.id));
    }
  }

}
