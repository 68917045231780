import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BudgetService {

  private _refreshBudget = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshBudget() {
    return this._refreshBudget;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/budget`);
  }

  getBudgetsOfAssociation(association_id) {
    return this._httpClient.get(`${environment.apiUrl}/liste/budget_by_association/${association_id}`);
  }

  getBudgetsByAccount(account) {
    return this._httpClient.post(`${environment.apiUrl}/liste/budget_By_Account`, { account });
  }

  getBudget(id) {
    return this._httpClient.get(`${environment.apiUrl}/budget/${id}`);
  }

  create(association, account, project, funder, title, type, initial_amount) {
    let current_amount = 0; let cash_amount = 0; let bank_amount = 0
    if (!project) {
      current_amount = initial_amount
      if (type == 'cash') {
        cash_amount = initial_amount
      } else {
        bank_amount = initial_amount
      }
    }

    return this._httpClient
      .post(`${environment.apiUrl}/create/budget`, { association, account, project, funder, title, type, initial_amount, current_amount, cash_amount, bank_amount })
      .pipe(
        tap(() => {
          this._refreshBudget.next()
        })
      );
  }

  update(id, account, funder, title, initial_amount) {
    return this._httpClient
      .put(`${environment.apiUrl}/update/budget/${id}`, { account, funder, title, initial_amount })
      .pipe(
        tap(() => {
          this._refreshBudget.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/delete/budget/${id}`)
      .pipe(
        tap(() => {
          this._refreshBudget.next()
        })
      );
  }
}
