import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { AccountService } from 'app/service/account/account.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);

  public accounts;
  public currentAccount
  private tempAccountData = [];
  public accountRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 5;

  public accountForm: FormGroup;
  public accountSubmitted = false
  public loadingSubmitAccount = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder
  ) { }

  // convenience getter for easy access to account form fields
  get a() {
    return this.accountForm.controls;
  }

  deleteAccount(res, id) {
    if (res.value == true) {
      this.accountService.delete(id)
        .subscribe({
          next: (response) => {
          },
          error: (e) => console.error(e)
        });
    }
  }

  updateAccount() {
    this.accountSubmitted = true
    if (this.accountForm.invalid) {
      console.log(this.accountForm)
      return
    } else {
      this.loadingSubmitAccount = true
      this.accountService.update(this.currentAccount.id, this.a.bank_name.value)
        .subscribe({
          next: (response) => {
            this.accountSubmitted = false
            this.loadingSubmitAccount = false
            this.modalService.dismissAll()
            this.accountForm.reset()
          },
          error: (e) => {
            this.loadingSubmitAccount = false
            this.accountSubmitted = false
            console.error(e)
          }
        });
    }
  }

  createAccount() {
    this.accountSubmitted = true
    if (this.accountForm.invalid) {
      return
    } else {
      this.loadingSubmitAccount = true
      this.accountService.create(this.currentUser.association_id, this.a.bank_name.value, this.a.initial_balance.value)
        .subscribe({
          next: (response) => {
            this.accountSubmitted = false
            this.loadingSubmitAccount = false
            this.modalService.dismissAll()
            this.accountForm.reset()
          },
          error: (e) => {
            this.loadingSubmitAccount = false
            this.accountSubmitted = false
            console.error(e)
          }
        });
    }
  }

  submitAccount() {
    if (this.currentAccount) {
      this.updateAccount()
    } else {
      this.createAccount()
    }
  }

  retrieveAccounts(): void {
    this.accountService.getAccountsByAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.accounts = data;
          this.tempAccountData = this.accounts;
          this.accountRows = this.accounts;
          this.exportCSVData = this.accounts;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.accountService.refreshAccount.subscribe(() => {
      this.retrieveAccounts()
    })
    this.retrieveAccounts();

    //account
    this.accountForm = this._formBuilder.group({
      bank_name: ['', [Validators.required]],
      initial_balance: [''],
    });
  }

  modalOpen(modalForm, account) {
    this.currentAccount = null
    this.accountForm.reset()
    this.modalService.open(modalForm);
    if (account) {
      this.currentAccount = account
      this.accountForm.patchValue({
        bank_name: account.bank_name,
        initial_balance: account.initial_balance,
      });
    }
  }

  filterAccounts(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempAccountData.filter(function (d) {
      if (d.bank_name.toLowerCase().includes(val) || d.initial_balance == val) {
        return d
      }
    });
    this.accountRows = temp;
  }

  confirmDeleteAccount(account) {
    Swal.fire({
      title: `Are you sure you want to delete ${account.bank_name}?`,
      text: 'You wont be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteAccount(res, account.id));
  }
}
