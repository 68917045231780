<div class="text-center" *ngIf="!currentReview">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div class="row" *ngIf="currentReview">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3 style="color: #FF649D">{{ currentReview.title }}</h3>
                <!-- <input type="button" value="Download PDF" class="btn btn-outline-primary" (click)="SavePDF()"> -->
            </div>
            <div class="card-body col-12 row" #content>
                <div class="col-6">
                    <h3 class="text-center bg-secondary bg-lighten-5 p-1">Incomes (+)</h3>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Reference</th>
                                <th>Date</th>
                                <th>Title</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let income of currentReview.incomes">
                                <td>{{ income.reference }}</td>
                                <td>{{ income.date }}</td>
                                <td>{{ income.title }}</td>
                                <td class="text-right">{{ (income.amount).toFixed(2) }} DT</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="4" class="text-right">Total: <b>{{ (totalIncomes).toFixed(2) }} DT</b></td>
                        </tfoot>
                    </table>
                </div>
                <div class="col-6">
                    <h3 class="text-center bg-secondary bg-lighten-5 p-1">Expenses (-)</h3>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Reference</th>
                                <th>Date</th>
                                <th>Title</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let expense of currentReview.expenses">
                                <td>{{ expense.reference }}</td>
                                <td>{{ expense.date }}</td>
                                <td>{{ expense.title }}</td>
                                <td class="text-right">{{ (expense.amount).toFixed(2) }} DT</td>
                            </tr>
                        </tbody>
                        <td colspan="4" class="text-right">Total: <b>{{ (totalExpenses).toFixed(2) }} DT</b></td>
                    </table>
                </div>
            </div>
            <hr>
            <h2 class="text-center text-{{marginColor}} mb-3">Final margin: <b>{{ (margin).toFixed(2) }} DT</b></h2>
        </div>
    </div>
</div>

<!-- material card -->
<!-- material table -->
<!-- <div class="row" id="basic-table">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3 style="color: #FF649D">Association review for {{ year }}</h3>
                <div class="btn-group">
                    <div ngbDropdown>
                        <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle budget-dropdown"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle
                            rippleEffect>
                            {{ year }}
                        </button>
                        <div ngbDropdownMenu>
                            <a ngbDropdownItem href="javascript:void(0);">{{ year }}</a>
                            <a ngbDropdownItem href="javascript:void(0);">2019</a>
                            <a ngbDropdownItem href="javascript:void(0);">2018</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Reference</th>
                                <th>Cost</th>
                                <th>Category</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let material of materials">
                                <td>{{ material.title }}</td>
                                <td>{{ material.description }}</td>
                                <td>{{ material.reference }}</td>
                                <td>{{ material.cost }} DT</td>
                                <td>{{ material.category }}</td>
                                <td class="overflow-hidden">
                                    <div ngbDropdown container="body">
                                        <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle
                                            data-toggle="dropdown">
                                            <i data-feather="more-vertical"></i>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <a ngbDropdownItem href="javascript:void(0);" routerLink="/"><i
                                                    data-feather="edit-2" class="mr-50"></i><span>Edit</span></a><a
                                                ngbDropdownItem href="javascript:void(0);"><i data-feather="trash"
                                                    class="mr-50"></i><span>Delete</span></a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!--/ material table -->