<div *ngIf="loadedData">
    <div>
        <div class="text-center">
            <h1 class="mt-1">Cash</h1>
        </div>
        <div class='row justify-content-center'>
            <div class="card col-3 me-1">
                <div class="card-body pb-50 ">
                    <div class='row'>
                        <div class='col-6'>
                            <h6>Total Bank accounts amount</h6>
                            <h4 class="fw-bolder mb-1">{{ sumOfBankAmount }} Dt</h4>
                        </div>
                        <div class='col-6'>
                            <img class='float-end' width="100"
                                src="https://img.freepik.com/vecteurs-premium/cartes-credit-icone-carte-debit-credit-vecteur-vues-avant-arriere-systeme-technologie-paiement-sans-contact-maquettes-vectorielles-carte-credit-payer-acheter_435184-640.jpg?w=1060" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card col-3 mx-1">
                <div class="card-body pb-50 ">
                    <div class='row'>
                        <div class='col-6'>
                            <h6>Total cash amount</h6>
                            <h4 class="fw-bolder mb-1">{{ sumOfCashAmount }} Dt</h4>
                        </div>
                        <div class='col-6'>
                            <img class='float-end' width="80"
                                src="https://img.freepik.com/premium-vector/banknote-gold-coins-with-silver-coins_383392-209.jpg?w=740 " />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <h4>Please provide the operation informations: </h4>
        </div>
        <div class="card-body">
            <form [formGroup]="createOperationForm" class="form form-horizontal" (ngSubmit)="confirmOperation()">
                <div class="form-group">
                    <div class="col-sm-3 col-form-label">
                        <label>Choose a budget:</label>
                    </div>
                    <div class="row justify-content-center text-center">
                        <div class="card col-3 mr-1 budgetOption" *ngFor="let budget of currentProject.budgets">
                            <label>
                                <input type="radio" value="{{ budget.id }}" class="card-input-element"
                                    formControlName="budget"
                                    [ngClass]="{ 'is-invalid': operationSubmitted && o.budget.errors }"
                                    (change)="handleChosenBudget(budget.id, budget.initial_amount)" />
                                <div class="card card-input border-secondary bg-light p-1">
                                    <h4>{{ budget.title }}</h4>
                                    In this budget you have
                                    <span><b>{{ budget.current_amount }} DT</b> in total.</span>
                                </div>
                                <div *ngIf="operationSubmitted && o.budget.errors" class="invalid-feedback">
                                    <div *ngIf="o.budget.errors.required">Budget is required</div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div *ngIf="operationSubmitted && o.budget.errors" class="invalid-feedback">
                        <div *ngIf="o.budget.errors.required">Title is required</div>
                    </div>
                </div>
                <div class="progress-wrapper col-5 mb-1" *ngIf="sumOfMoneySlices">
                    <div class="mb-25">Progress&hellip; {{calculateProgress }}%</div>
                    <ngb-progressbar type="primary" [value]="calculateProgress"></ngb-progressbar>
                </div>
                <div class="progress-wrapper col-5 mb-1" *ngIf="currentBudget && sumOfMoneySlices == 0">
                    <div class="mb-25">Progress&hellip; 0%</div>
                    <ngb-progressbar type="primary" [value]="0"></ngb-progressbar>
                </div>
                <!-- ngb-alert component -->
                <ngb-alert [type]="'primary'" class="ml-1" [dismissible]="false"
                    *ngIf="currentBudget && sumOfMoneySlices == 0">
                    <div class="alert-body">you did not give a slice, you have {{ remainingSlice }} DT to donate.
                    </div>
                </ngb-alert>
                <!--/ ngb-alert component -->
                <!-- ngb-alert component -->
                <ngb-alert [type]="'primary'" class="ml-1" [dismissible]="false"
                    *ngIf="currentBudget && sumOfMoneySlices != 0">
                    <div class="alert-body">you have given <strong>{{ (sumOfMoneySlices).toFixed(2) }}</strong> DT
                        and you have <strong>{{ (remainingSlice).toFixed(2) }}</strong> DT left to pay.</div>
                </ngb-alert>
                <!--/ ngb-alert component -->

                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>Operation name:</label>
                            </div>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" formControlName="title"
                                    [ngClass]="{ 'is-invalid': operationSubmitted && o.title.errors }" />
                                <div *ngIf="operationSubmitted && o.title.errors" class="invalid-feedback">
                                    <div *ngIf="o.title.errors.required">Title is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>Note</label>
                            </div>
                            <div class="col-sm-6">
                                <textarea class="form-control" formControlName="note"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>Operation reference (cheque or transfer number):</label>
                            </div>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" formControlName="reference"
                                    [ngClass]="{ 'is-invalid': operationSubmitted && o.reference.errors }" />
                                <div *ngIf="operationSubmitted && o.reference.errors" class="invalid-feedback">
                                    <div *ngIf="o.reference.errors.required">Reference is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>Transaction type</label>
                            </div>
                            <div class="col-sm-6">
                                <ng-select formControlName="transaction_type"
                                    [ngClass]="{ 'is-invalid': operationSubmitted && o.transaction_type.errors }">
                                    <ng-option *ngFor="let transactionType of transactionTypes"
                                        value="{{transactionType.value}}">
                                        {{transactionType.value}}
                                    </ng-option>
                                </ng-select>
                                <div *ngIf="operationSubmitted && o.transaction_type.errors" class="invalid-feedback">
                                    <div *ngIf="o.transaction_type.errors.required">Transaction type is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>Domain</label>
                            </div>
                            <div class="col-sm-6">
                                <select formControlName="domain" class="form-control"
                                    [ngClass]="{ 'is-invalid': operationSubmitted && o.domain.errors }">
                                    <ng-container *ngFor="let subdomain of subdomains">
                                        <optgroup label="{{subdomain.domain.title}}">
                                            <option value="{{subdomain.domain.id}}">
                                                {{ subdomain.title }}
                                            </option>
                                        </optgroup>
                                    </ng-container>
                                </select>
                                <div *ngIf="operationSubmitted && o.domain.errors" class="invalid-feedback">
                                    <div *ngIf="o.domain.errors.required">Domain is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>Operation date</label>
                            </div>
                            <div class="col-sm-6">
                                <input format-value="yyyy-MM-ddTHH:mm" type="date" class="form-control"
                                    formControlName="operation_date" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>Amount</label>
                            </div>
                            <div class="col-sm-6">
                                <input type="number" class="form-control" formControlName="amount"
                                    [ngClass]="{ 'is-invalid': operationSubmitted && o.amount.errors }" />
                                <div *ngIf="operationSubmitted && o.amount.errors" class="invalid-feedback">
                                    <div *ngIf="o.amount.errors.required">Amount is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label">
                                <label>Bank operation proof</label>
                            </div>
                            <div class="col-sm-6">
                                <div class="input-group input-group-merge">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" formControlName="bank_proof"
                                            [ngClass]="{ 'is-invalid': operationSubmitted && o.bank_proof.errors }"
                                            (change)="getBankProof($event)" />
                                        <label class="custom-file-label">Choose a file</label>
                                        <div *ngIf="operationSubmitted && o.bank_proof.errors" class="invalid-feedback">
                                            <div *ngIf="o.bank_proof.errors.required">Proof is required</div>
                                        </div>
                                    </div>
                                    <img *ngIf="logo" id="logo" src="" width="40" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-proof objectType="project" [objectId]="currentProject.id"
                    (checkProof)="handleProof($event)"></app-proof>
                <div class="card-footer text-right">
                    <button type="submit" class="btn btn-success" [disabled]="loadingOperation">
                        <span *ngIf="loadingOperation" class="spinner-border spinner-border-sm mr-1"></span>
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>