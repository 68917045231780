import { Component, OnInit } from '@angular/core';

import { environment } from 'environments/environment';

import { AuthenticationService } from 'app/auth/service';
import { FunderService } from 'app/service/funder/funder.service';
import { BudgetService } from 'app/service/budget/budget.service';

@Component({
  selector: 'app-funder-dashboard',
  templateUrl: './funder-dashboard.component.html',
  styleUrls: ['./funder-dashboard.component.scss']
})
export class FunderDashboardComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  filePlacement = environment.FilePlacementUrl

  loadedData = false
  currentFunder

  constructor(
    private  authenticationService: AuthenticationService,
    private funderService: FunderService,
    private budgetService: BudgetService,
  ) { }

  getFunderData() {
    this.funderService.getFunder(this.currentUser.funder_id)
      .subscribe({
        next: (data) => {
          this.currentFunder = data;
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.budgetService.refreshBudget.subscribe(() => {
      this.getFunderData()
    })
    this.getFunderData()
  }

}
