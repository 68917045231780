import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  private _refreshDomain = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshDomain() {
    return this._refreshDomain;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/domain`);
  }

  getDomainsByAssociation(id) {
    return this._httpClient.get(`${environment.apiUrl}/liste/domain_by_association/${id}`);
  }

  getDomain(id) {
    return this._httpClient.get(`${environment.apiUrl}/domain/${id}`);
  }

  create(association, title, description) {
    return this._httpClient
      .post(`${environment.apiUrl}/create/domain`, { association, title, description })
      .pipe(
        tap(() => {
          this._refreshDomain.next()
        })
      );
  }

  update(id, title, description) {
    return this._httpClient
      .put(`${environment.apiUrl}/update/domain/${id}`, { title, description })
      .pipe(
        tap(() => {
          this._refreshDomain.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/delete/domain/${id}`)
      .pipe(
        tap(() => {
          this._refreshDomain.next()
        })
      );
  }
}
