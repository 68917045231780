<div class="card" *ngIf="loadedData">
  <div class="row">
    <div class="col-md-6 col-12">
      <h2 class="mt-1 ml-1" style="color: #FF649D">Association members</h2>
    </div>
    <div class="col-md-6 col-12 d-flex justify-content-md-end">
      <button class="btn btn-primary float-right m-1 mr-2" (click)="modalOpen(modalMemberForm)" rippleEffect>
        Add New member
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-12">
      <div class="d-flex justify-content-between align-items-center m-1">
        <label class="d-flex align-items-center">Show
          <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
            <option value="5">5</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          entries</label>
      </div>
    </div>
    <div class="col-md-6 col-12 d-flex justify-content-md-end">
      <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
        <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
            class="form-control ml-25" (keyup)="filterMembers($event)" (search)="filterMembers($event)" /></label>
      </div>
      <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
        <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary mr-1" rippleEffect>
          Export CSV</a>
      </div>
    </div>
  </div>

  <!-- Members Datatable -->
  <ngx-datatable [rows]="memberRows" [rowHeight]="58" class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force"
    [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
    [limit]="basicSelectedOption" (select)="onSelectMember($event)">
    <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
      [resizeable]="false">
      <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
            (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
          <label class="custom-control-label" for="headerChkbxRef"></label>
        </div>
      </ng-template>
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" [checked]="isSelected"
            (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
          <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Email" prop="email" [width]="250"></ngx-datatable-column>
    <ngx-datatable-column name="role" prop="role" [width]="120"></ngx-datatable-column>
    <ngx-datatable-column name="cin" prop="cin" [width]="120"></ngx-datatable-column>
    <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
      <ng-template ngx-datatable-cell-template let-row="row">
        <div class="d-flex align-items-center">
          <button type="button" (click)="modalOpen(modalMemberForm, row)"
            class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
            <i data-feather="edit"></i>
          </button>
          <button type="button" (click)="confirmDeleteMember(row)" class="btn btn-raised btn-outline-danger btn-sm"
            rippleEffect>
            <i data-feather="trash"></i>
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
  <!--/ Members Datatable -->
</div>


<!-- Member Modal -->
<ng-template #modalMemberForm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">New member</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <form [formGroup]="memberForm" (ngSubmit)="submitMember()">
      <label>Email: </label>
      <div class="form-group">
        <input type="email" class="form-control" formControlName="email"
          [ngClass]="{ 'is-invalid': memberSubmitted && m.email.errors }" />
        <div *ngIf="memberSubmitted && m.email.errors" class="invalid-feedback">
          <div *ngIf="m.email.errors.required">Email is required</div>
        </div>
      </div>
      <label>Role: </label>
      <div class="form-group">
        <ng-select formControlName="role" [ngClass]="{ 'is-invalid': memberSubmitted && m.role.errors }">
          <ng-option value="Association">Association</ng-option>
          <ng-option value="Member">Member</ng-option>
        </ng-select>
        <div *ngIf="memberSubmitted && m.role.errors" class="invalid-feedback">
          <div *ngIf="m.role.errors.required">Role is required</div>
        </div>
      </div>
      <label>Cin: </label>
      <div class="form-group">
        <input type="number" class="form-control" formControlName="cin"
          [ngClass]="{ 'is-invalid': memberSubmitted && m.cin.errors }" />
        <div *ngIf="memberSubmitted && m.cin.errors" class="invalid-feedback">
          <div *ngIf="m.cin.errors.required">Cin is required</div>
        </div>
      </div>
      <div *ngIf="!currentMember">
        <label>Password: </label>
        <div class="form-group">
          <input type="password" class="form-control" formControlName="password"
            [ngClass]="{ 'is-invalid': memberSubmitted && m.password.errors }" [required]="!currentMember" />
          <div *ngIf="memberSubmitted && m.password.errors" class="invalid-feedback">
            <div *ngIf="m.password.errors.required">Password is required</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="loadingSubmitMember">
          <span *ngIf="loadingSubmitMember" class="spinner-border spinner-border-sm mr-1"></span>
          Submit
        </button>
      </div>
    </form>
  </div>
</ng-template>
<!-- / Member Modal -->