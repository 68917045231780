import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  private _refreshMaterial = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshMaterial() {
    return this._refreshMaterial;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/material`);
  }

  create(association, title, description, reference, date, material_category, cost) {
    return this._httpClient
      .post(`${environment.apiUrl}/create/material`, { association, title, description, reference, date, material_category, cost })
      .pipe(
        tap(() => {
          this._refreshMaterial.next()
        })
      );
  }
}
