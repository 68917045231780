<div *ngIf="loadedData">

    <!-- dashboard content -->
    <div class='row justify-content-center'>
        <div class="card col-4 mx-1">
            <div class="card-body pb-50">
                <div class='row justify-content-center align-self-center align-items-center'>
                    <div class='col-7'>
                        <h6>Remaining Balance For This association</h6>
                        <h2 class="fw-bolder mb-1">{{ (associationTotalAmount).toFixed(2) }} Dt</h2>
                        <!-- sum of current balance of the associations accounts -->
                    </div>
                    <div class='col-5'>
                        <img class='float-end' width="150"
                            src="https://img.freepik.com/vecteurs-premium/sac-argent-pieces-or-isoles-fond-blanc_186930-724.jpg?w=900" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card card-body pb-50 row mb-50">
                <div class='row'>
                    <div class='col-8'>
                        <h6> Total Bank balance</h6>
                        <h2 class="fw-bolder">{{ (associationBankAmount).toFixed(2) }} DT</h2>
                    </div>
                    <div class='col-2'>
                        <img width="80"
                            src="https://img.freepik.com/vecteurs-premium/cartes-credit-icone-carte-debit-credit-vecteur-vues-avant-arriere-systeme-technologie-paiement-sans-contact-maquettes-vectorielles-carte-credit-payer-acheter_435184-640.jpg?w=1060" />
                    </div>
                </div>
            </div>
            <div class="card card-body pb-50 row">
                <div class='row'>
                    <div class='col-8'>
                        <h6>Total Cash balance</h6>
                        <h2 class="fw-bolder">{{ (associationCashAmount).toFixed(2) }} DT</h2>
                    </div>
                    <div class='col-2'>
                        <img width="80"
                            src="https://img.freepik.com/premium-vector/banknote-gold-coins-with-silver-coins_383392-209.jpg?w=740 " />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section id="card-text-alignment">
        <h5 class="mb-2">Let's start here</h5>
        <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
                <div class="card text-center mb-3 border-primary">
                    <div class="card-body">
                        <h4 class="card-title">Add a bank account</h4>
                        <p class="card-text">Click below and fill the form.</p>
                        <button type="button" (click)="modalOpenForm(modalAccountForm)"
                            class="btn btn-primary text-right mb-2" rippleEffect>
                            New account
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card text-center mb-3 border-primary">
                    <div class="card-body">
                        <h4 class="card-title">Projects</h4>
                        <p class="card-text">Create a new project.</p>
                        <button type="button" class="btn btn-primary text-right mb-2 mr-1"
                            routerLink="/association/projects" rippleEffect [disabled]="!accounts">
                            Projects
                        </button>
                        <button type="button" class="btn btn-primary text-right mb-2"
                            routerLink="/association/new/project" rippleEffect [disabled]="!accounts">
                            New project
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="card text-center mb-3 border-primary">
                    <div class="card-body">
                        <h4 class="card-title">Association</h4>
                        <p class="card-text">Create a new budget.</p>
                        <button type="button" class="btn btn-primary text-right mb-2 mr-1"
                            routerLink="/association/budgets" rippleEffect [disabled]="!accounts">
                            Budgets
                        </button>
                        <button type="button" class="btn btn-primary text-right mb-2"
                            routerLink="/association/new/budget" rippleEffect [disabled]="!accounts">
                            New budget
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--/ dashboard content -->

</div>

<!-- Account Modal -->
<ng-template #modalAccountForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add a new account</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="createAccountForm" (ngSubmit)="submitAccount()">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label>Bank name: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="bank_name" placeholder="Bank name"
                    [ngClass]="{ 'is-invalid': accountSubmitted && a.bank_name.errors }" />
                <div *ngIf="accountSubmitted && a.bank_name.errors" class="invalid-feedback">
                    <div *ngIf="a.bank_name.errors.required">Bank name is required</div>
                </div>
            </div>
            <label>Initial balance (DT): </label>
            <div class="form-group">
                <input type="number" class="form-control" formControlName="initial_balance"
                    placeholder="initial Balance"
                    [ngClass]="{ 'is-invalid': accountSubmitted && a.initial_balance.errors }" />
                <div *ngIf="accountSubmitted && a.initial_balance.errors" class="invalid-feedback">
                    <div *ngIf="a.initial_balance.errors.required">Initial balance is required</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="loadingSubmitAccount" class="btn btn-primary">
                <span *ngIf="loadingSubmitAccount" class="spinner-border spinner-border-sm mr-1"></span>
                Submit
            </button>
        </div>
    </form>
</ng-template>
<!-- / Account Modal -->