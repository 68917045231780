import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'app/service/user/user.service';

import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  loadedData = false

  public user
  public passwordTextTypeOld = false;
  public passwordTextTypeNew = false;
  public passwordTextTypeRetype = false;

  public editUserForm: FormGroup;
  public userSubmitted = false
  public editPasswordForm: FormGroup;
  public passowrdSubmitted = false
  public loadingSubmit = false


  constructor(private authenticationService: AuthenticationService,
    private userService: UserService,
    private _formBuilder: FormBuilder,
    private _router: Router
  ) { }

  // convenience getter for easy access to user form fields
  get u() {
    return this.editUserForm.controls;
  }

  submitUpdateUser() {
    console.log(this.u.role.value)
    // this.userSubmitted = true
    // if (this.editUserForm.invalid) {
    //   return
    // } else {
    //   this.loadingSubmit = true
    //   this.userService.update(this.currentUser.id, this.u.email.value, this.u.cin.value, this.u.role.value)
    //     .subscribe({
    //       next: (response) => {
    //         this.userSubmitted = false
    //         this.loadingSubmit = false
    //         this.editUserForm.reset()
    //       },
    //       error: (e) => {
    //         this.loadingSubmit = false
    //         this.userSubmitted = false
    //         console.error(e)
    //       }
    //     });
    // }
  }

  getUserData() {
    this.userService.getUser(this.currentUser.id)
      .subscribe({
        next: (data) => {
          this.user = data;
          this.editUserForm.patchValue({
            email: this.user.email,
            cin: this.user.cin,
            role: this.user.roles,
          });
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.userService.refreshUser.subscribe(() => {
      this.getUserData();
    })
    this.getUserData()

    //user
    this.editUserForm = this._formBuilder.group({
      email: ['', Validators.required],
      cin: ['', Validators.required],
      role: ['', Validators.required],
    });
    this.editPasswordForm = this._formBuilder.group({
    });
  }

  /**
   * Toggle Password Text Type Old
   */
  togglePasswordTextTypeOld() {
    this.passwordTextTypeOld = !this.passwordTextTypeOld;
  }

  /**
   * Toggle Password Text Type New
   */
  togglePasswordTextTypeNew() {
    this.passwordTextTypeNew = !this.passwordTextTypeNew;
  }

  /**
   * Toggle Password Text Type Retype
   */
  togglePasswordTextTypeRetype() {
    this.passwordTextTypeRetype = !this.passwordTextTypeRetype;
  }
}
