import { Component, Input, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap/datepicker/adapters/ngb-date-adapter';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { OperationService } from 'app/service/operation/operation.service';
import { AlertService } from 'app/service/alert/alert.service';
import { ProofComponent } from '../../operation/proof/proof.component';
import { BudgetService } from 'app/service/budget/budget.service';
import { ParameterService } from 'app/service/parameter/parameter.service';
import { DomainService } from 'app/service/domain/domain.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-budget-bank-donation',
  templateUrl: './budget-bank-donation.component.html',
  styleUrls: ['./budget-bank-donation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BudgetBankDonationComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  @ViewChild(ProofComponent) child !: any;
  @ViewChildren(ProofComponent)

  public currentBudget
  public transactionTypes
  public domains
  public subdomains

  proofExist = false
  bankProof

  public createDonationOperationForm: FormGroup;
  public donationOperationSubmitted = false
  public loadingSubmitBankDonation = false

  constructor(
    private authenticationService: AuthenticationService,
    private budgetService: BudgetService,
    private parameterService: ParameterService,
    private domainService: DomainService,
    private operationService: OperationService,
    private _formBuilder: FormBuilder,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) { }

  // convenience getter for easy access to donation operation form fields
  get d_o() {
    return this.createDonationOperationForm.controls;
  }

  getBankProof(event) {
    this.bankProof = event.target.files[0];
  }

  submitBankDonation(res) {
    if (res.value == true) {
      this.loadingSubmitBankDonation = true
      let label = 'bank_donation'
      let bank_amount = this.currentBudget.bank_amount + this.d_o.amount.value
      console.log(this.currentBudget)
      this.operationService.bankDonation(this.currentUser.id, null, this.currentBudget.id, label, this.d_o.title.value, this.d_o.note.value, this.d_o.reference.value, this.d_o.transaction_type.value, this.d_o.domain.value, this.d_o.operation_date.value, this.d_o.amount.value, this.currentBudget.cash_amount, bank_amount, this.bankProof)
        .subscribe({
          next: (operation) => {
            this.child.submitProof(operation)
            this.loadingSubmitBankDonation = false
            this.donationOperationSubmitted = false
          },
          error: (e) => {
            this.loadingSubmitBankDonation = false
            this.donationOperationSubmitted = false
            console.error(e)
          }
        });
    }
  }

  confirmOperation() {
    this.donationOperationSubmitted = true
    if (this.createDonationOperationForm.invalid) {
      return
    } else {
      if (this.proofExist) {
        Swal.fire({
          title: 'Are you sure you want to go through this transaction ?',
          text: `${this.d_o.amount.value} DT, will be transferred into your budget !`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          confirmButtonText: 'Yes, commit it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary ml-1'
          }
        }).then(res => this.submitBankDonation(res));
      } else {
        this.alertService.errorAlert('You cant commit this yet', 'You have to import at least one justification file');
      }
    }
  }

  handleProof(e) {
    if (!e) {
      this.proofExist = false
    } else {
      this.proofExist = true
    }
  }

  getTransactionType() {
    this.parameterService.getTransactionTypeParameter()
      .subscribe({
        next: (response) => {
          this.transactionTypes = response
        },
        error: (e) => console.error(e)
      });
  }

  getDomains(): void {
    this.domainService.getAll()
      .subscribe({
        next: (data) => {
          this.domains = data
          this.subdomains = this.domains.flatMap(item => item.subdomains)
        },
        error: (e) => console.error(e)
      });
  }

  getBudgetData(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.budgetService.getBudget(id)
      .subscribe({
        next: (data) => {
          this.currentBudget = data;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getBudgetData();
    this.getTransactionType()

    this.domainService.refreshDomain.subscribe(() => {
      this.getDomains();
    })
    this.getDomains();

    //donation operation
    this.createDonationOperationForm = this._formBuilder.group({
      title: ['', Validators.required],
      note: [''],
      reference: ['', Validators.required],
      transaction_type: ['', Validators.required],
      domain: ['', Validators.required],
      operation_date: [''],
      amount: ['', Validators.required],
      bank_proof: ['', Validators.required],
    });
  }

}