<form [formGroup]="createPayementOperationForm" class="form form-horizontal" (ngSubmit)="confirmOperation()">
    <div class="form-group">
        <div class="col-sm-3 col-form-label">
            <label>Choose a budget:</label>
        </div>
        <div class="row justify-content-center text-center">
            <div class="card col-3 mr-1 budgetOption" *ngFor="let budget of currentProject.budgets">
                <label>
                    <input type="radio" value="{{budget.id}}" class="card-input-element" formControlName="budget"
                        [ngClass]="{ 'is-invalid': payementOperationSubmitted && p_o.budget.errors }"
                        (change)="handleChosenBudget(budget)" />
                    <div class="card card-input border-secondary bg-light p-1">
                        <h4>{{ budget.title }}</h4>
                        In this budget you have
                        <span><b>{{ budget.cash_amount }} DT</b> in cash </span>
                        and
                        <span><b>{{ budget.cash_amount + budget.bank_amount }} DT</b> in total.</span>
                    </div>
                    <div *ngIf="payementOperationSubmitted && p_o.budget.errors" class="invalid-feedback">
                        <div *ngIf="p_o.budget.errors.required">Budget is required</div>
                    </div>
                </label>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group row">
            <div class="col-sm-3 col-form-label">
                <label>Operation name:</label>
            </div>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="title"
                    [ngClass]="{ 'is-invalid': payementOperationSubmitted && p_o.title.errors }" />
                <div *ngIf="payementOperationSubmitted && p_o.title.errors" class="invalid-feedback">
                    <div *ngIf="p_o.title.errors.required">Operation name is required</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group row">
            <div class="col-sm-3 col-form-label">
                <label>Note</label>
            </div>
            <div class="col-sm-6">
                <textarea class="form-control" formControlName="note"></textarea>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group row">
            <div class="col-sm-3 col-form-label">
                <label>Operation reference (cheque or transfer number):</label>
            </div>
            <div class="col-sm-6">
                <input type="text" class="form-control" formControlName="reference"
                    [ngClass]="{ 'is-invalid': payementOperationSubmitted && p_o.reference.errors }" />
                <div *ngIf="payementOperationSubmitted && p_o.reference.errors" class="invalid-feedback">
                    <div *ngIf="p_o.reference.errors.required">Operation reference is required</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group row">
            <div class="col-sm-3 col-form-label">
                <label>Activity</label>
            </div>
            <div class="col-sm-6">
                <ng-select formControlName="activity"
                    [ngClass]="{ 'is-invalid': payementOperationSubmitted && p_o.activity.errors }">
                    <ng-option *ngFor="let activity of currentProject.activities" value="{{activity.id}}">
                        {{ activity.title }}
                    </ng-option>
                </ng-select>
                <div *ngIf="payementOperationSubmitted && p_o.activity.errors" class="invalid-feedback">
                    <div *ngIf="p_o.activity.errors.required">Activity is required</div>
                </div>
            </div>
            <button type="button" class="btn btn-outline-primary" (click)="modalOpenForm(modalActivityForm)"
                rippleEffect>+</button>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group row">
            <div class="col-sm-3 col-form-label">
                <label>Material</label>
            </div>
            <div class="col-sm-6">
                <ng-select formControlName="material" multiple="true">
                    <ng-option *ngFor="let material of materials" value="{{material.id}}">
                        {{ material.title }}</ng-option>
                </ng-select>
            </div>
            <button type="button" class="btn btn-outline-primary" (click)="modalOpenForm(modalMaterialForm)"
                rippleEffect>+</button>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group row">
            <div class="col-sm-3 col-form-label">
                <label>Line</label>
            </div>
            <div class="col-sm-6">
                <ng-select formControlName="line" (change)="getLimit($event)"
                    [ngClass]="{ 'is-invalid': payementOperationSubmitted && p_o.line.errors }">
                    <ng-option *ngFor="let line of currentBudget.lines" [value]="line">
                        {{ line.title }}
                    </ng-option>
                </ng-select>
                <div *ngIf="payementOperationSubmitted && p_o.line.errors" class="invalid-feedback">
                    <div *ngIf="p_o.line.errors.required">Line is required</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group row">
            <div class="col-sm-3 col-form-label">
                <label>Operation date</label>
            </div>
            <div class="col-sm-6">
                <input type="date" class="form-control" formControlName="operation_date" />
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group row">
            <div class="col-sm-3 col-form-label">
                <label>Amount</label>
            </div>
            <div class="col-sm-6">
                <input type="number" class="form-control" formControlName="amount"
                    [ngClass]="{ 'is-invalid': payementOperationSubmitted && p_o.amount.errors }" />
                <div *ngIf="payementOperationSubmitted && p_o.amount.errors" class="invalid-feedback">
                    <div *ngIf="p_o.amount.errors.required">Amount is required</div>
                </div>
                <br>
                <div class="progress-wrapper">
                    <ngb-alert [type]="'secondary'" [dismissible]="false" *ngIf="limitAmount">
                        The amount must not exceed
                        {{ limitAmount }} DT
                    </ngb-alert>
                    <ngb-progressbar type="{{totalSpendingBarColor}}" *ngIf="spendingMoneyPercentage != 0"
                        [value]="spendingMoneyPercentage" showValue="true"></ngb-progressbar>
                </div>
            </div>
        </div>
    </div>
    <app-proof objectType="project" [objectId]="currentProject.id" (checkProof)="handleProof($event)"></app-proof>
    <div class="card-footer text-right">
        <button type="submit" class="btn btn-success" [disabled]="loadingSubmitCashPayement">
            <span *ngIf="loadingSubmitCashPayement" class="spinner-border spinner-border-sm mr-1"></span>
            Submit
        </button>
    </div>
</form>


<!-- Activity Modal -->
<ng-template #modalActivityForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">New activity</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" ngbAutofocus>
        <form [formGroup]="createActivityForm" (ngSubmit)="submitActivity()">
            <label>Title: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="title"
                    [ngClass]="{ 'is-invalid': activitySubmitted && a.title.errors }" />
                <div *ngIf="activitySubmitted && a.title.errors" class="invalid-feedback">
                    <div *ngIf="a.title.errors.required">Title is required</div>
                </div>
            </div>
            <label>Description: </label>
            <div class="form-group">
                <textarea type="text" class="form-control" formControlName="description"
                    [ngClass]="{ 'is-invalid': activitySubmitted && a.description.errors }"></textarea>
                <div *ngIf="activitySubmitted && a.description.errors" class="invalid-feedback">
                    <div *ngIf="a.description.errors.required">Description is required</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" [disabled]="loadingSubmitActivity">
                    <span *ngIf="loadingSubmitActivity" class="spinner-border spinner-border-sm mr-1"></span>
                    Submit
                </button>
            </div>
        </form>
    </div>
</ng-template>
<!-- / Activity Modal -->

<!-- Material Modal -->
<ng-template #modalMaterialForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">New material</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form [formGroup]="createMaterialForm" (ngSubmit)="submitMaterial()">
            <label>Title: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="title"
                    [ngClass]="{ 'is-invalid': materialSubmitted && m.title.errors }" />
                <div *ngIf="materialSubmitted && m.title.errors" class="invalid-feedback">
                    <div *ngIf="m.title.errors.required">Title is required</div>
                </div>
            </div>
            <label>Description: </label>
            <div class="form-group">
                <textarea type="text" class="form-control" formControlName="description"
                    [ngClass]="{ 'is-invalid': materialSubmitted && m.description.errors }"></textarea>
                <div *ngIf="materialSubmitted && m.description.errors" class="invalid-feedback">
                    <div *ngIf="m.description.errors.required">Description is required</div>
                </div>
            </div>
            <label>Reference: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="reference"
                    [ngClass]="{ 'is-invalid': materialSubmitted && m.reference.errors }" />
                <div *ngIf="materialSubmitted && m.reference.errors" class="invalid-feedback">
                    <div *ngIf="m.reference.errors.required">Reference is required</div>
                </div>
            </div>
            <label>Category: </label>
            <div class="form-group">
                <ng-select formControlName="category"
                    [ngClass]="{ 'is-invalid': materialSubmitted && m.category.errors }">
                    <ng-option *ngFor="let material of materialCategories" value="{{material.title}}">
                        {{ material.title }}</ng-option>
                </ng-select>
                <div *ngIf="materialSubmitted && m.category.errors" class="invalid-feedback">
                    <div *ngIf="m.category.errors.required">Category is required</div>
                </div>
            </div>
            <label>Date: </label>
            <div class="form-group">
                <input type="date" class="form-control" placeholder="yyyy-mm-dd" formControlName="material_date"
                    [ngClass]="{ 'is-invalid': materialSubmitted && m.material_date.errors }">
                <div *ngIf="materialSubmitted && m.material_date.errors" class="invalid-feedback">
                    <div *ngIf="m.material_date.errors.required">Date is required</div>
                </div>
            </div>
            <label>Cost (DT): </label>
            <div class="form-group">
                <input type="number" class="form-control" formControlName="cost"
                    [ngClass]="{ 'is-invalid': materialSubmitted && m.cost.errors }" />
                <div *ngIf="materialSubmitted && m.cost.errors" class="invalid-feedback">
                    <div *ngIf="m.cost.errors.required">Cost is required</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" [disabled]="loadingSubmitMaterial">
                    <span *ngIf="loadingSubmitMaterial" class="spinner-border spinner-border-sm mr-1"></span>
                    Submit
                </button>
            </div>
        </form>
    </div>
</ng-template>
<!-- / Material Modal -->