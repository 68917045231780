import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  {
    id: 'dashboard_association',
    title: 'Dashboard',
    //translate: 'MENU.DASHBOARD.ANALYTICS',
    type: 'item',
    role: ["Association"], //? To set multiple role: ['Admin', 'Client']
    icon: 'home',
    url: 'association/dashboard'
  },
  {
    id: 'dashboard_funder',
    title: 'Dashboard',
    //translate: 'MENU.DASHBOARD.ANALYTICS',
    type: 'item',
    role: ["Funder"], //? To set multiple role: ['Admin', 'Client']
    icon: 'home',
    url: 'funder/dashboard'
  },
  {
    id: 'member',
    title: 'Members',
    //translate: 'MENU.APPS.MAIL',
    type: 'item',
    role: ["Association"],
    icon: 'users',
    url: 'association/list/member'
  },
  {
    id: 'account',
    title: 'Accounts',
    //translate: 'MENU.APPS.MAIL',
    type: 'item',
    role: ["Association"],
    icon: 'credit-card',
    url: 'association/list/account'
  },
  {
    id: 'domain',
    title: 'Domains',
    //translate: 'MENU.APPS.MAIL',
    type: 'item',
    role: ["Association"],
    icon: 'square',
    url: 'association/list/domain'
  },
  {
    id: 'project',
    title: 'Projects',
    //translate: 'MENU.APPS.MAIL',
    type: 'item',
    role: ["Association"],
    icon: 'file',
    url: 'association/projects'
  },
  {
    id: 'budget',
    title: 'Budgets',
    //translate: 'MENU.APPS.MAIL',
    type: 'item',
    role: ["Association"],
    icon: 'file',
    url: 'association/budgets'
  },
  {
    id: 'association_profile',
    title: 'Association profile',
    //translate: 'MENU.DASHBOARD.ANALYTICS',
    type: 'item',
    role: ["Association"], //? To set multiple role: ['Admin', 'Client']
    icon: 'settings',
    url: 'association/profile'
  },
  {
    id: 'responsible',
    title: 'Responsibles',
    //translate: 'MENU.APPS.MAIL',
    type: 'item',
    role: ["Funder"],
    icon: 'users',
    url: 'funder/list/responsible'
  },
]