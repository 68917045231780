<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Blog Edit -->
    <div class="blog-edit-wrapper">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="avatar mr-75">
                  <img [src]="data.blogEdit.avatar" width="38" height="38" alt="Avatar" />
                </div>
                <div class="media-body">
                  <h6 class="mb-25">{{ data.blogEdit.username }}</h6>
                  <p class="card-text">{{ data.blogEdit.postedAt }}</p>
                </div>
              </div>
              <!-- Form -->
              <form action="javascript:;" class="mt-2">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group mb-2">
                      <label for="blog-edit-title">Title</label>
                      <input
                        type="text"
                        id="blog-edit-title"
                        name="blog-edit-title"
                        class="form-control"
                        [(ngModel)]="data.blogEdit.blogTitle"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group mb-2">
                      <label for="blog-edit-category">Category</label>

                      <ng-select
                        [items]="selectCategories"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        [searchable]="false"
                        bindLabel="name"
                        placeholder="Select people"
                        [(ngModel)]="selectCategoriesSelected"
                        name="selectTitle"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group mb-2">
                      <label for="blog-edit-slug">Slug</label>
                      <input
                        type="text"
                        id="blog-edit-slug"
                        class="form-control"
                        value="the-best-features-coming-to-ios-and-web-design"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group mb-2">
                      <label for="blog-edit-status">Status</label>
                      <select
                        class="form-control"
                        [(ngModel)]="data.blogEdit.status"
                        id="blog-edit-status"
                        name="blog-edit-status"
                      >
                        <option value="Published">Published</option>
                        <option value="Pending">Pending</option>
                        <option value="Draft">Draft</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group mb-2">
                      <label>Content</label>
                      <quill-editor [(ngModel)]="data.blogEdit.blogText" name="quill-content"> </quill-editor>
                    </div>
                  </div>
                  <div class="col-12 mb-2">
                    <div class="border rounded p-2">
                      <h4 class="mb-1">Featured Image</h4>
                      <div class="media flex-column flex-md-row">
                        <img
                          [src]="featuredImage"
                          id="blog-feature-image"
                          class="rounded mr-2 mb-1 mb-md-0"
                          width="170"
                          height="110"
                          alt="Blog Featured Image"
                        />
                        <div class="media-body">
                          <small class="text-muted">Required image resolution 800x400, image size 10mb.</small>
                          <p class="my-50">
                            <a href="javascript:void(0);" id="blog-image-text"
                              >C:\fakepath\{{ fileName === undefined ? 'banner.jpg' : fileName }}</a
                            >
                          </p>
                          <div class="d-inline-block">
                            <div class="form-group mb-0">
                              <div class="custom-file">
                                <input
                                  type="file"
                                  class="custom-file-input"
                                  id="blogCustomFile"
                                  accept="image/*"
                                  (change)="uploadImage($event)"
                                />
                                <label class="custom-file-label" for="blogCustomFile">Choose file</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-50">
                    <button type="submit" class="btn btn-primary mr-1" rippleEffect>Save Changes</button>
                    <button type="reset" class="btn btn-outline-secondary" rippleEffect>Cancel</button>
                  </div>
                </div>
              </form>
              <!--/ Form -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ Blog Edit -->
  </div>
</div>
