<div class="text-center" *ngIf="!loadedData">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div class="card" *ngIf="loadedData">
    <div class="row">
        <div class="col-md-6 col-12">
            <h2 class="mt-1 ml-1" style="color: #FF649D">Association projects</h2>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <button class="btn btn-primary float-right m-1 mr-2" routerLink="/association/new/project" rippleEffect>
                Add New project
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center">Show
                    <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    entries</label>
            </div>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                        class="form-control ml-25" (keyup)="filterProjects($event)"
                        (search)="filterProjects($event)" /></label>
            </div>
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary mr-1" rippleEffect>
                    Export CSV</a>
            </div>
        </div>
    </div>

    <!-- Projects Datatable -->
    <ngx-datatable [rows]="projectRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="5"
        [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
        [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption" (select)="onSelectProject($event)">
        <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
            [resizeable]="false">
            <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                        (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                    <label class="custom-control-label" for="headerChkbxRef"></label>
                </div>
            </ng-template>
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                        (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                    <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Title" prop="title" [width]="250"></ngx-datatable-column>
        <ngx-datatable-column name="Description" prop="description" [width]="120"></ngx-datatable-column>
        <ngx-datatable-column name="Date" [width]="140">
            <ng-template ngx-datatable-cell-template let-row="row">
                <strong>De</strong> {{ row.start_date }} <br>
                <strong>à</strong> {{ row.end_date }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <div class="d-flex align-items-center">
                    <button type="button" routerLink="/association/edit/project/{{ row.id }}"
                        class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                        <i data-feather="edit"></i>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <!--/ Projects Datatable -->
</div>