import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OperationService {

  private _refreshOperation = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshOperation() {
    return this._refreshOperation;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/operation`);
  }

  getOperation(id) {
    return this._httpClient.get(`${environment.apiUrl}/operation/${id}`,
      // {headers: new HttpHeaders({'Content-Type': 'application/json'})}
    );
  }

  cashWithdrawal(user, budget, title, label, amount, bank_amount, cash_amount) {
    return this._httpClient
      .post(`${environment.apiUrl}/create/withdrawal/operation`, { user, budget, title, label, amount, bank_amount, cash_amount })
      .pipe(
        tap(() => {
          this._refreshOperation.next()
        })
      );
  }

  cashDonation(user, review, budget, label, title, note, reference, domain, date, amount, cash_amount, bank_amount) {
    const formData: FormData = new FormData();
    formData.append('user', user);
    formData.append('review', review);
    formData.append('budget', budget);
    formData.append('label', label);
    formData.append('title', title);
    formData.append('note', note);
    formData.append('reference', reference);
    formData.append('domain', domain);
    formData.append('operation_date', date);
    formData.append('amount', amount);
    formData.append('cash_amount', cash_amount);
    formData.append('bank_amount', bank_amount);

    return this._httpClient
      .post(`${environment.apiUrl}/create/cash/operation`, formData)
      .pipe(
        tap(() => {
          this._refreshOperation.next()
        })
      );
  }

  cashPayement(user, review, budget, budget_receiver, label, title, note, reference, activity, domain, material, line, date, amount, cash_amount, bank_amount) {
    const formData: FormData = new FormData();
    formData.append('user', user);
    formData.append('review', review);
    formData.append('budget', budget);
    formData.append('budget_receiver', budget_receiver);
    formData.append('label', label);
    formData.append('title', title);
    formData.append('note', note);
    formData.append('reference', reference);
    formData.append('activity', activity);
    formData.append('material', material);
    formData.append('line', line);
    formData.append('domain', domain);
    formData.append('operation_date', date);
    formData.append('amount', amount);
    formData.append('cash_amount', cash_amount);
    formData.append('bank_amount', bank_amount);

    return this._httpClient
      .post(`${environment.apiUrl}/create/cash/operation`, formData)
      .pipe(
        tap(() => {
          this._refreshOperation.next()
        })
      );
  }

  bankDonation(user, review, budget, label, title, note, reference, type, domain, date, amount, cash_amount, bank_amount, bankProof) {
    const formData: FormData = new FormData();
    formData.append('user', user);
    formData.append('review', review);
    formData.append('budget', budget);
    formData.append('label', label);
    formData.append('title', title);
    formData.append('note', note);
    formData.append('reference', reference);
    formData.append('type', type);
    formData.append('domain', domain);
    formData.append('operation_date', date);
    formData.append('amount', amount);
    formData.append('cash_amount', cash_amount);
    formData.append('bank_amount', bank_amount);
    formData.append('bankProof', bankProof);

    return this._httpClient
      .post(`${environment.apiUrl}/create/bank/operation`, formData)
      .pipe(
        tap(() => {
          this._refreshOperation.next()
        })
      );
  }

  bankPayement(user, review, budget, budget_receiver, label, title, note, reference, type, activity, material, operation_date, line, domain, amount, cash_amount, bank_amount, bankProof) {
    const formData: FormData = new FormData();
    formData.append('user', user);
    formData.append('review', review);
    formData.append('budget', budget);
    formData.append('budget_receiver', budget_receiver);
    formData.append('label', label);
    formData.append('title', title);
    formData.append('note', note);
    formData.append('reference', reference);
    formData.append('type', type);
    formData.append('activity', activity);
    formData.append('material', material);
    formData.append('domain', domain);
    formData.append('operation_date', operation_date);
    formData.append('line', line);
    formData.append('amount', amount);
    formData.append('cash_amount', cash_amount);
    formData.append('bank_amount', bank_amount);
    formData.append('bankProof', bankProof);

    return this._httpClient
      .post(`${environment.apiUrl}/create/bank/operation`, formData)
      .pipe(
        tap(() => {
          this._refreshOperation.next()
        })
      );
  }

}
