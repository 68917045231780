import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { ResponsibleService } from 'app/service/responsible/responsible.service';

@Component({
  selector: 'app-responsible',
  templateUrl: './responsible.component.html',
  styleUrls: ['./responsible.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResponsibleComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);

  public loadedData = false

  public responsibles;
  public currentResponsible
  private tempResponsibleData = [];
  public responsibleRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 5;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public responsibleForm: FormGroup;
  public responsibleSubmitted = false
  public loadingSubmitResponsible = false


  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private responsibleService: ResponsibleService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder
  ) { }

  // convenience getter for easy access to responsible form fields
  get r() {
    return this.responsibleForm.controls;
  }

  deleteResponsible(res, id) {
    if (res.value == true) {
      this.responsibleService.delete(id)
        .subscribe({
          next: (response) => {
          },
          error: (e) => console.error(e)
        });
    }
  }

  updateResponsible() {
    this.responsibleSubmitted = true
    if (this.responsibleForm.invalid) {
      return
    } else {
      this.loadingSubmitResponsible = true
      this.responsibleService.update(this.currentResponsible.id, this.r.email.value, this.r.role.value, this.r.cin.value)
        .subscribe({
          next: (response) => {
            this.responsibleSubmitted = false
            this.loadingSubmitResponsible = false
            this.modalService.dismissAll()
            this.responsibleForm.reset()
            this.responsibleForm.get('password').setValidators(Validators.required)
          },
          error: (e) => {
            this.loadingSubmitResponsible = false
            this.responsibleSubmitted = false
            console.error(e)
          }
        });
    }
  }

  createResponsible() {
    this.responsibleSubmitted = true
    if (this.responsibleForm.invalid) {
      return
    } else {
      this.loadingSubmitResponsible = true
      this.responsibleService.create(this.currentUser.funder_id, this.r.email.value, this.r.role.value, this.r.cin.value, this.r.password.value)
        .subscribe({
          next: (response) => {
            this.responsibleSubmitted = false
            this.loadingSubmitResponsible = false
            this.modalService.dismissAll()
            this.responsibleForm.reset()
          },
          error: (e) => {
            this.loadingSubmitResponsible = false
            this.responsibleSubmitted = false
            console.error(e)
          }
        });
    }
  }

  submitResponsible() {
    if (this.currentResponsible) {
      this.updateResponsible()
    } else {
      this.createResponsible()
    }
  }

  retrieveResponsibles(): void {
    this.responsibleService.getResponsiblesOfFunder(this.currentUser.funder_id)
      .subscribe({
        next: (data) => {
          this.responsibles = data;
          this.loadedData = true
          this.tempResponsibleData = this.responsibles;
          this.responsibleRows = this.responsibles;
          this.exportCSVData = this.responsibles;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.responsibleService.refreshResponsible.subscribe(() => {
      this.retrieveResponsibles()
    })
    this.retrieveResponsibles();

    //responsible
    this.responsibleForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      cin: ['', Validators.required],
      password: [''],
    });
  }

  modalOpen(modalForm, responsible) {
    this.currentResponsible = null
    this.responsibleForm.reset()
    this.modalService.open(modalForm);
    if (responsible) {
      this.currentResponsible = responsible
      this.responsibleForm.patchValue({
        email: responsible.email,
        role: responsible.role,
        cin: responsible.cin,
      });
    }
  }

  filterResponsibles(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempResponsibleData.filter(function (d) {
      return d.email.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.responsibleRows = temp;
  }

  onSelectResponsible({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
  }

  confirmDeleteResponsible(responsible) {
    Swal.fire({
      title: `Are you sure you want to delete ${responsible.email}?`,
      text: 'You wont be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteResponsible(res, responsible.id));
  }

}
