<div *ngIf="loadedData">

    <div class='card col-12'>
        <div class="row m-2">
            <div class="col-6">
                <h2>{{ currentProject.title }}</h2>
                <small class="text-muted">{{ currentProject.description }}</small>
            </div>
        </div>
    </div>

    <div class='row justify-content-center'>
        <div class="card col-4 mx-1">
            <div class="card-body pb-50">
                <div class='row justify-content-center align-items-center '>
                    <div class='col-7 ps-'>
                        <h6>Total Balance For This Project</h6>
                        <h2 class="fw-bolder mb-1">{{ totalProjectAmount }} DT</h2>
                    </div>
                    <div class='col-5'>
                        <img class='float-end' width="150"
                            src="https://img.freepik.com/free-vector/finances-management-budget-assessment-financial-literacy-accounting-idea-financier-with-cash-economist-holding-golden-coin-cartoon-character_335657-1604.jpg?t=st=1657896220~exp=1657896820~hmac=44670ae1eae2f3a1c71ee708bcfd0afbbcb8cb1b56e095b2c8a7aa07591c1ade&w=740" />
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-3">
            <div class="card card-body pb-50 row mb-50">
                <div class='row'>
                    <div class='col-8'>
                        <h6> Total Bank balance</h6>
                        <h2 class="fw-bolder">{{ totalBankAmount }} DT</h2>
                    </div>
                    <div class='col-2'>
                        <img width="80"
                            src="https://img.freepik.com/vecteurs-premium/cartes-credit-icone-carte-debit-credit-vecteur-vues-avant-arriere-systeme-technologie-paiement-sans-contact-maquettes-vectorielles-carte-credit-payer-acheter_435184-640.jpg?w=1060" />
                    </div>
                </div>
            </div>
            <div class="card card-body pb-50 row">
                <div class='row'>
                    <div class='col-8'>
                        <h6>Total Cash balance</h6>
                        <h2 class="fw-bolder">{{ totalCashAmount }} DT</h2>
                    </div>
                    <div class='col-2'>
                        <img width="80"
                            src="https://img.freepik.com/premium-vector/banknote-gold-coins-with-silver-coins_383392-209.jpg?w=740 " />
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-4 mx-1" role="button" routerLink="/association/review/{{ currentProject.review }}">
            <div class="card-body pb-50">
                <div class='row justify-content-center align-items-center '>
                    <div class='col-6 ps-0'>
                        <h3 class="fw-bolder mb-1">Visit Project Statistics</h3>
                    </div>
                    <div class='col-6'>
                        <img class='float-end' width="160"
                            src="https://img.freepik.com/free-vector/clock-increasing-chart-workflow-productivity-increase-work-performance-optimization-efficiency-indicator-rising-effectiveness-metrics-vector-isolated-concept-metaphor-illustration_335657-2718.jpg?t=st=1658759098~exp=1658759698~hmac=fe4c6495f85362e91e938c65d1b87cf52b7e275210f5719ccd8ca7aba18382fe&w=740" />
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <!-- operation card -->
    <!-- operation datatable -->
    <app-operation [budgets]="currentProject.budgets"></app-operation>
    <!--/ operation datatable -->

    <!-- line card -->
    <!-- line datatable -->
    <app-line [association]="currentAssociation" [project]="currentProject"></app-line>
    <!--/ line datatable -->
    <!--/ line card -->

    <!-- activity card -->
    <!-- activity datatable -->
    <app-activity [project]="currentProject.id"></app-activity>
    <!--/ activity datatable -->
</div>