import { Component, OnInit, Input, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap/datepicker/adapters/ngb-date-adapter';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { OperationService } from 'app/service/operation/operation.service';
import { ProofComponent } from '../../proof/proof.component';
import { AlertService } from 'app/service/alert/alert.service';
import { DomainService } from 'app/service/domain/domain.service';

@Component({
  selector: 'app-cash-donation',
  templateUrl: './cash-donation.component.html',
  styleUrls: ['./cash-donation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CashDonationComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  @ViewChild(ProofComponent) child !: any;
  @Input() currentProject;
  @ViewChildren(ProofComponent)

  public domains
  public subdomains

  proofExist = false

  public createDonationOperationForm: FormGroup;
  public donationOperationSubmitted = false
  public loadingSubmitCashDonation = false

  constructor(
    private authenticationService: AuthenticationService,
    private domainService: DomainService,
    private operationService: OperationService,
    private _formBuilder: FormBuilder,
    private alertService: AlertService) { }

  // convenience getter for easy access to donation operation form fields
  get d_o() {
    return this.createDonationOperationForm.controls;
  }

  submitCashDonation(res) {
    if (res.value == true) {
      this.loadingSubmitCashDonation = true
      let label = 'cash_donation'
      let cash_amount = this.d_o.budget.value.cash_amount + this.d_o.amount.value
      this.operationService.cashDonation(this.currentUser.id, this.currentProject.review, this.d_o.budget.value.id, label, this.d_o.title.value, this.d_o.note.value, this.d_o.reference.value, this.d_o.domain.value, this.d_o.operation_date.value, this.d_o.amount.value, cash_amount, this.d_o.budget.value.bank_amount)
        .subscribe({
          next: (operation) => {
            this.child.submitProof(operation)
            this.loadingSubmitCashDonation = false
            this.donationOperationSubmitted = false
          },
          error: (e) => {
            this.loadingSubmitCashDonation = false
            this.donationOperationSubmitted = false
            console.error(e)
          }
        });
    }
  }

  confirmOperation() {
    this.donationOperationSubmitted = true
    if (this.createDonationOperationForm.invalid) {
      return
    } else {
      console.log('here')
      if (this.proofExist) {
        Swal.fire({
          title: 'Are you sure you want to go through this transaction ?',
          text: `${this.d_o.amount.value} DT, will be transferred into your selected budget !`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          confirmButtonText: 'Yes, commit it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary ml-1'
          }
        }).then(res => this.submitCashDonation(res));
      } else {
        this.alertService.errorAlert('You cant commit this yet', 'You have to import at least one justification file');
      }
    }
  }

  handleProof(e) {
    if (!e) {
      this.proofExist = false
    } else {
      this.proofExist = true
    }
    console.log(e, this.proofExist)
  }

  getDomains(): void {
    this.domainService.getDomainsByAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.domains = data
          this.subdomains.flatMap(item => item.subdomains)
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getDomains()

    //cash donation operation
    this.createDonationOperationForm = this._formBuilder.group({
      budget: ['', Validators.required],
      title: ['', Validators.required],
      note: [''],
      reference: ['', Validators.required],
      domain: [''],
      operation_date: [''],
      amount: ['', Validators.required],
    });
  }
}
