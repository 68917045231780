import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private _refreshProject = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshProject() {
    return this._refreshProject;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/project`);
  }

  getProjectsOfAssociation(project) {
    return this._httpClient.get(`${environment.apiUrl}/liste/project_by_association/${project}`);
  }

  getProject(id) {
    return this._httpClient.get(`${environment.apiUrl}/project/${id}`);
  }

  create(association, review, title, description, project_category, startDate, endDate, logo) {
    const formData: FormData = new FormData();
    formData.append('association', association);
    formData.append('review', review);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('project_category', project_category);
    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    formData.append('logo', logo);
    
    return this._httpClient
      .post(`${environment.apiUrl}/create/project`, formData)
      .pipe(
        tap(() => {
          this._refreshProject.next()
        })
      );
  }

  update(id, title, description, category, start_date, end_date, logo) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('project_category', category);
    formData.append('start_date', start_date);
    formData.append('end_date', end_date);
    formData.append('logo', logo);

    return this._httpClient
      .post(`${environment.apiUrl}/update/project/${id}`, formData)
      .pipe(
        tap(() => {
          this._refreshProject.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/delete/project/${id}`)
      .pipe(
        tap(() => {
          this._refreshProject.next()
        })
      );
  }
}
