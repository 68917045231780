<div *ngIf="loadedData">
    <div>
        <div class="text-center">
            <h1 class="mt-1">Bank {{ operationType }}</h1>
        </div>
        <div class='row justify-content-center'>
            <div class="card col-3 me-1">
                <div class="card-body pb-50 ">
                    <div class='row'>
                        <div class='col-6'>
                            <h6> Total bank balance</h6>
                            <h4 class="fw-bolder mb-1">{{ remainingBankAmount }} Dt</h4>
                        </div>
                        <div class='col-6'>
                            <img class='float-end' width="80"
                                src="https://img.freepik.com/vecteurs-premium/cartes-credit-icone-carte-debit-credit-vecteur-vues-avant-arriere-systeme-technologie-paiement-sans-contact-maquettes-vectorielles-carte-credit-payer-acheter_435184-640.jpg?w=1060" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card col-3 mx-1">
                <div class="card-body pb-50 ">
                    <div class='row'>
                        <div class='col-6'>
                            <h6>Total cash balance</h6>
                            <h4 class="fw-bolder mb-1">{{ remainingCashAmount }} Dt</h4>
                        </div>
                        <div class='col-6'>
                            <img class='float-end' width="100"
                                src="https://img.freepik.com/premium-vector/banknote-gold-coins-with-silver-coins_383392-209.jpg?w=740 " />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header">
            <h4>Please provide the operation informations: </h4>
        </div>
        <div class="card-body" *ngIf="operationType === 'payement'">
            <app-bank-payement [currentProject]="currentProject" [currentBudget]="currentBudget"
                [activities]="activities" [transactionTypes]="transactionTypes"></app-bank-payement>
        </div>

        <div class="card-body" *ngIf="operationType === 'donation'">
            <app-bank-donation [currentProject]="currentProject"
                [transactionTypes]="transactionTypes"></app-bank-donation>
        </div>
    </div>
</div>