import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import { MaterialService } from 'app/service/material/material.service';
import { ReviewService } from 'app/service/review/review.service';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  @ViewChild('content') content: ElementRef;

  loadedData = false

  public currentReview
  public materials
  public year

  public incomes
  public totalIncomes = 0
  public expenses
  public totalExpenses = 0
  public margin = 0
  public marginColor = ''

  constructor(
    private authenticationService: AuthenticationService,
    private reviewService: ReviewService,
    private materialService: MaterialService,
    private route: ActivatedRoute) { }

  checkMargin() {
    for (let i = 0; i < this.currentReview.incomes.length; i++) {
      this.totalIncomes += this.currentReview.incomes[i].amount;
    }
    for (let e = 0; e < this.currentReview.expenses.length; e++) {
      this.totalExpenses += this.currentReview.expenses[e].amount;
    }
    this.margin = this.totalIncomes - this.totalExpenses
    if (this.margin >= 0) {
      this.marginColor = 'success'
    } else {
      this.marginColor = 'danger'
    }
  }

  getReview() {
    let id = this.route.snapshot.paramMap.get('id');
    let type = this.route.snapshot.paramMap.get('type');

    if (type == 'project') {
      this.reviewService.getProjectReview(id)
        .subscribe({
          next: (response) => {
            this.currentReview = response
            this.checkMargin()
          },
          error: (e) => console.error(e)
        });
    } else {
      this.reviewService.getBudgetReview(id)
        .subscribe({
          next: (response) => {
            this.currentReview = response
            this.checkMargin()
          },
          error: (e) => console.error(e)
        });
    }
  }

  savePdf() {
    // let content = this.content.nativeElement;
    // let doc = new jsPDF();
    // let _elementHandlers =
    // {
    //   '#editor': function (element, renderer) {
    //     return true;
    //   }
    // };
    // doc.fromHTML(content.innerHTML, 15, 15, {

    //   'width': 190,
    //   'elementHandlers': _elementHandlers
    // });

    // doc.save('test.pdf');
  }
  // getMaterials() {
  //   this.materialService.getAll()
  //     .subscribe({
  //       next: (response) => {
  //         this.materials = response
  //       },
  //       error: (e) => console.error(e)
  //     });
  // }

  ngOnInit(): void {
    this.year = this.route.snapshot.paramMap.get('year');
    this.getReview()
    //this.getMaterials()
  }

}
