import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from 'app/service/project/project.service';

@Component({
  selector: 'app-detail-project',
  templateUrl: './detail-project.component.html',
  styleUrls: ['./detail-project.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailProjectComponent implements OnInit {

  loadedData = false

  public currentProject
  public totalProjectAmount: number
  public totalBankAmount: number
  public totalCashAmount: number

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute
  ) { }

  getProjectData(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.projectService.getProject(id)
      .subscribe({
        next: (data) => {
          this.currentProject = data;
          this.totalProjectAmount = 0; this.totalBankAmount = 0; this.totalCashAmount = 0
          let currentAmount = 0
          for (let b = 0; b < this.currentProject.budgets.length; b++) {
            this.totalProjectAmount += this.currentProject.budgets[b].initial_amount
            currentAmount += this.currentProject.budgets[b].current_amount
            this.totalBankAmount += parseFloat(this.currentProject.budgets[b].bank_amount)
            this.totalCashAmount += parseFloat(this.currentProject.budgets[b].cash_amount)
          }
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getProjectData()
  }

}
