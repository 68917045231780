import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { environment } from 'environments/environment';

import { AuthenticationService } from 'app/auth/service';
import { AssociationService } from 'app/service/association/association.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-association-profile',
  templateUrl: './association-profile.component.html',
  styleUrls: ['./association-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssociationProfileComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  filePlacement = environment.FilePlacementUrl

  currentAssociation: any
  loadedData = false

  public updateAssociationForm: FormGroup;
  public updateAssociationSubmitted = false
  public loadingSubmit = false
  public updateAssociationsubmitted = false

  constructor(
    private authenticationService: AuthenticationService,
    private associationService: AssociationService,
    private _formBuilder: FormBuilder,
    private _router: Router
  ) {
  }

  // convenience getter for easy access to update association form fields
  get a() {
    return this.updateAssociationForm.controls;
  }

  submitUpdateAssociation() {
    this.updateAssociationSubmitted = true
    if (this.updateAssociationForm.invalid) {
      return
    } else {
      this.loadingSubmit = true
      let fundation_date = `${this.a.fundation_date.value.year}/${this.a.fundation_date.value.month}/${this.a.fundation_date.value.day}`
      this.associationService.update(this.currentUser.association_id, this.a.title.value, this.a.email.value,
        fundation_date, this.a.description.value, this.a.address.value, this.a.reference_jort.value,
        this.a.website.value)
        .subscribe({
          next: (response) => {
            this.updateAssociationsubmitted = false
            this.loadingSubmit = false
            this.updateAssociationForm.reset()
          },
          error: (e) => {
            this.loadingSubmit = false
            this.updateAssociationSubmitted = false
            console.error(e)
          }
        });
    }
  }

  getAssociationData() {
    this.associationService.getAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.currentAssociation = data;
          this.updateAssociationForm.patchValue({
            title: this.currentAssociation.title,
            category: this.currentAssociation.category,
            address: this.currentAssociation.address,
            website: this.currentAssociation.website,
            reference_jort: this.currentAssociation.reference_jort,
            fundation_date: this.currentAssociation.fundation_date,
            email: this.currentAssociation.email,
            description: this.currentAssociation.description,
          });
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    if (this.currentUser.role === 'Association') {
      this.associationService.refreshAssociation.subscribe(() => {
        this.getAssociationData()
      })
      this.getAssociationData()
    }

    //association
    this.updateAssociationForm = this._formBuilder.group({
      title: ['', Validators.required], email: ['', Validators.required],
      fundation_date: [''], category: [''],
      description: ['', Validators.required], address: ['', Validators.required],
      reference_jort: ['', Validators.required], website: ['', Validators.required],
    });
  }

}
