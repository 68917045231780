import { Component, OnInit, ViewChild, Input, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap/datepicker/adapters/ngb-date-adapter';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { ActivityService } from 'app/service/activity/activity.service';
import { ParameterService } from 'app/service/parameter/parameter.service';
import { MaterialService } from 'app/service/material/material.service';
import { OperationService } from 'app/service/operation/operation.service';
import { ProofComponent } from '../../proof/proof.component';
import { AlertService } from 'app/service/alert/alert.service';

@Component({
  selector: 'app-bank-payement',
  templateUrl: './bank-payement.component.html',
  styleUrls: ['./bank-payement.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BankPayementComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  @ViewChild(ProofComponent) child !: any;
  @Input() currentProject;
  @Input() transactionTypes;
  @ViewChildren(ProofComponent)
  parseFloat = parseFloat


  public basicDPdata: NgbDateStructAdapter;

  loadedData = false

  public operationType: string

  public currentBudget;
  proofExist = false

  public materialCategories
  public materials

  public limitAmount = 0
  spendingMoneyPercentage = 0
  public amountLimitColor = ""

  public bankProof

  public createActivityForm: FormGroup;
  public activitySubmitted = false
  public loadingSubmitActivity = false

  public createMaterialForm: FormGroup;
  public materialSubmitted = false;
  public loadingSubmitMaterial = false

  public createPayementOperationForm: FormGroup;
  public payementOperationSubmitted = false
  public loadingSubmitBankPayement = false

  constructor(
    private authenticationService: AuthenticationService,
    private operationService: OperationService,
    private activityService: ActivityService,
    private parameterService: ParameterService,
    private materialService: MaterialService,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private alertService: AlertService) { }

  // convenience getter for easy access to activty form fields
  get a() {
    return this.createActivityForm.controls;
  }
  // convenience getter for easy access to material form fields
  get m() {
    return this.createMaterialForm.controls;
  }
  // convenience getter for easy access to payement operation form fields
  get p_o() {
    return this.createPayementOperationForm.controls;
  }

  getBankProof(event) {
    this.bankProof = event.target.files[0];
  }

  getLimit(event) {
    let limit = this.p_o.line.value.cost_limit
    if (this.currentBudget.bank_amount < limit) {
      this.limitAmount = this.currentBudget.bank_amount
    } else {
      this.limitAmount = limit
    }
    let sum = 0
    for (let o = 0; o < event.operations.length; o++) {
      sum += parseFloat(event.operations[o].amount)
    }
    this.spendingMoneyPercentage = Math.round((sum / limit) * 100)
    return this.spendingMoneyPercentage
  }

  submitBankPayement(res) {
    if (res.value == true) {
      this.loadingSubmitBankPayement = true
      let label = 'bank_payement'
      let bank_amount = this.currentBudget.bank_amount - this.p_o.amount.value
      this.operationService.bankPayement(this.currentUser.id, this.currentProject.review, this.p_o.budget.value, null, label, this.p_o.title.value, this.p_o.note.value, this.p_o.reference.value, this.p_o.transaction_type.value, this.p_o.activity.value, this.p_o.material.value, this.p_o.operation_date.value, this.p_o.line.value.id, this.p_o.line.value.domain.id, this.p_o.amount.value, this.currentBudget.cash_amount, bank_amount, this.bankProof)
        .subscribe({
          next: (operation) => {
            this.child.submitProof(operation)
            this.loadingSubmitBankPayement = false
            this.payementOperationSubmitted = false
          },
          error: (e) => {
            this.loadingSubmitBankPayement = false
            this.payementOperationSubmitted = false
            console.error(e)
          }
        });
    }
  }


  confirmOperation() {
    this.payementOperationSubmitted = true
    if (this.createPayementOperationForm.invalid) {
      console.log('no')
      return
    } else {
      if (this.p_o.amount.value <= this.limitAmount) {
        if (this.proofExist) {
          console.log('here')
          Swal.fire({
            title: 'Are you sure you want to go through this transaction ?',
            text: `${this.p_o.amount.value} DT, will be debited  from your selected budget !`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            confirmButtonText: 'Yes, commit it!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-secondary ml-1'
            }
          }).then(res => this.submitBankPayement(res));
        } else {
          this.alertService.errorAlert('You cant commit this yet', 'You have to import at least one justification file');
        }
      } else {
        this.alertService.errorAlert('This operation cannot be commited', 'The entered amount exceeds the given limits please try again');
      }
    }
  }

  handleProof(e) {
    if (!e) {
      this.proofExist = false
    } else {
      this.proofExist = true
    }
  }

  handleChosenBudget(budget) {
    this.limitAmount = null
    this.spendingMoneyPercentage = 0
    this.currentBudget = budget
  }

  submitActivity() {
    this.activitySubmitted = true
    if (this.createActivityForm.invalid) {
      return
    } else {
      this.loadingSubmitActivity = true
      this.activityService.create(this.currentProject.id, this.a.title.value, this.a.description.value)
        .subscribe({
          next: (response) => {
            this.activitySubmitted = false
            this.loadingSubmitActivity = false
            this.modalService.dismissAll()
            this.createActivityForm.reset()
          },
          error: (e) => {
            this.loadingSubmitActivity = false
            this.activitySubmitted = false
            console.error(e)
          }
        });
    }
  }

  submitMaterial() {
    this.materialSubmitted = true
    if (this.createMaterialForm.invalid) {
      return
    } else {
      this.loadingSubmitMaterial = true
      this.materialService.create(this.currentUser.association_id, this.m.title.value, this.m.description.value, this.m.reference.value, this.m.material_date.value, this.m.category.value, this.m.cost.value)
        .subscribe({
          next: (response) => {
            this.loadingSubmitMaterial = false
            this.materialSubmitted = false
            this.modalService.dismissAll()
            this.createMaterialForm.reset()
          },
          error: (e) => {
            this.loadingSubmitMaterial = false
            this.materialSubmitted = false
            console.error(e)
          }
        });
    }
  }

  getMaterialCatgory() {
    this.parameterService.getMaterialCategoryParameter()
      .subscribe({
        next: (response) => {
          this.materialCategories = response
        },
        error: (e) => console.error(e)
      });
  }

  getMaterials(): void {
    this.materialService.getAll()
      .subscribe({
        next: (data) => {
          this.getMaterialCatgory()
          this.materials = data
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.currentBudget = this.currentProject.budgets[0]
    this.materialService.refreshMaterial.subscribe(() => {
      this.getMaterials();
    })
    this.getMaterials();

    //activity
    this.createActivityForm = this._formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });

    //material
    this.createMaterialForm = this._formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      reference: ['', Validators.required],
      category: ['', Validators.required],
      material_date: ['', Validators.required],
      cost: ['', Validators.required],
    });

    //payement operation
    this.createPayementOperationForm = this._formBuilder.group({
      budget: ['', Validators.required],
      title: ['', Validators.required],
      note: [''],
      reference: ['', Validators.required],
      transaction_type: ['', Validators.required],
      activity: ['', Validators.required],
      material: [''],
      line: ['', Validators.required],
      operation_date: [''],
      amount: ['', Validators.required],
      bank_proof: ['', Validators.required],
    });
  }

  modalOpenForm(modal) {
    this.modalService.open(modal);
  }
}
