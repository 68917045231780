<div *ngIf="loadedData">
    <!-- operation card -->
    <!-- operation table -->
    <div class="row" id="basic-table">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h3 style="color: #FF649D">{{ currentOperation.title }}</h3>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th *ngIf="currentOperation.note">Note</th>
                                    <th>Type</th>
                                    <th>Affected budget</th>
                                    <th *ngIf="currentOperation.activity_title">Activity</th>
                                    <th *ngIf="currentOperation.line_title">Line</th>
                                    <th *ngIf="currentOperation.bankOperation_id">Transaction type</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ currentOperation.title }}</td>
                                    <td *ngIf="currentOperation.note">{{ currentOperation.note }}</td>
                                    <td>{{ currentOperation.label }}</td>
                                    <td>{{ currentOperation.budget_title }}</td>
                                    <td *ngIf="currentOperation.activity_title">{{ currentOperation.activity_title }}
                                    </td>
                                    <td *ngIf="currentOperation.line_title">{{ currentOperation.line_title }}</td>
                                    <td *ngIf="currentOperation.bankOperation_id">{{ currentOperation.bankOperation_type
                                        }}</td>
                                    <td>
                                        <span class="badge badge-pill badge-light-primary mr-1">{{
                                            currentOperation.amount
                                            }} DT</span>
                                    </td>
                                    <td>{{ currentOperation.date }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--/ operation table -->
    <!-- <div class='row justify-content-center'>
        <div class="card col-3 mx-1" *ngFor="let project of currentAssociation.projects" role="button"
            routerLink="/association/edit/project/{{ project.id }}">
            <div class="card-body pb-50 ">
                <div class='row'>
                    <div class='col-6'>
                        <h4>{{ project.title }}</h4>
                        <h6 class="fw-bolder mb-1">{{ project.description }}</h6>
                    </div>
                    <div class='col-6' *ngIf="project.logo">
                        <img class='float-end' width="80" src="{{ filePlacement }}/uploads/{{ project.logo }}" />
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- operation proof -->
    <div class='row justify-content-center'>
        <div class="card col-3 mx-1" *ngFor="let proof of currentOperation.proofs">
            <div class="card-body pb-50 ">
                <div class='row'>
                    <div class='col-6'>
                        <h4>{{ proof.title }}</h4>
                        <h6 class="fw-bolder mb-1">{{ proof.description }}</h6>
                    </div>
                    <div class='col-6 float-end'>
                        <button class="btn btn-icon btn-outline-primary"
                            (click)="modalOpenLG(modalProofVisualisation, proof)">
                            <i data-feather="file-text" [size]="30" class="text-primary"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--/ operation proof -->
</div>

<!-- Bank transfer Modal -->
<ng-template #modalProofVisualisation let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">{{currentProof.title}}</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="text-center m-1">
            <img *ngIf="!currentProof.document.includes('.pdf')"
                src="{{ filePlacement }}/uploads/{{ currentProof.document }}">

            <embed *ngIf="currentProof.document.includes('.pdf')" [src]="safePdfUrl" type="application/pdf"
                [render-text]="true" [original-size]="false" width="700px" height="700px">
        </div>
    </div>
</ng-template>
<!-- / Bank transfer Modal -->