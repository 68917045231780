import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { LineService } from 'app/service/line/line.service';
import { AlertService } from 'app/service/alert/alert.service';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LineComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  @Input() association;
  @Input() project;

  disableSubmit = false
  parseFloat = parseFloat

  public lines
  public currentLine

  private tempLineData = [];
  public lineRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 5;

  calculatedPercentage: number
  disableBudget = false

  public totalSpendingBarColor
  public sumOfSpendings = 0

  public leftPercentColor = null
  public costLimit = 0

  public lineForm: FormGroup;
  public lineSubmitted = false;
  public loadingSubmitLine = false;

  public modalRef

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private lineService: LineService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private alertService: AlertService
  ) { }

  // convenience getter for easy access to line form fields
  get l() {
    return this.lineForm.controls;
  }

  handleChosenBudget(budget) {
    this.costLimit = 0
    this.calculatedPercentage = 0
    if (budget.lines.length == 0) {
      this.leftPercentColor = "success"
      return this.costLimit = budget.initial_amount
    } else {
      let somme = 0
      for (let i = 0; i < budget.lines.length; i++) {
        somme += parseFloat(budget.lines[i].cost_limit)
      }
      if (somme == budget.initial_amount) {
        this.disableBudget = true
      } else {
        let fiftyPercent = ((50 / 100) * budget.initial_amount)
        let eightyPercent = ((80 / 100) * budget.initial_amount)
        if (somme >= 0 && somme < fiftyPercent) {
          this.leftPercentColor = "success"
        } else {
          if (somme >= fiftyPercent && somme < eightyPercent) {
            this.leftPercentColor = "warning"
          } else if (somme >= eightyPercent && somme <= budget.initial_amount) {
            this.leftPercentColor = "danger"
          }
        }
        return this.costLimit = budget.initial_amount - somme
      }
    }
  }

  getPercentage(e) {
    if (e.target.value > this.costLimit) {
      this.disableSubmit = true
    } else {
      this.disableSubmit = false
    }
    let percentage = (e.target.value / this.l.budget.value.initial_amount) * 100
    return this.calculatedPercentage = Math.round(percentage)
  }

  calculateTotalExpenses(row) {
    let sum = 0
    for (let o = 0; o < row.operations.length; o++) {
      sum += parseFloat(row.operations[o].amount)
    }
    let remaining = row.cost_limit
    let percentage = Math.round((sum / remaining) * 100)
    this.sumOfSpendings = sum;
    if (percentage == 100) {
      //this.disableOperation = true
      //this.toastBasic('Youve spent your entire budgets', 3000)
    } else {
      if (percentage >= 0 && percentage < 60) {
        this.totalSpendingBarColor = "success"
      } else {
        if (percentage >= 60 && percentage <= 80) {
          this.totalSpendingBarColor = "warning"
        } else {
          if (percentage >= 80 && percentage <= 100) {
            this.totalSpendingBarColor = "danger"
          }
        }
      }
    }
    return percentage
  }

  deleteLine(res, id) {
    if (res.value == true) {
      this.lineService.delete(id)
        .subscribe({
          next: (data) => {
            console.log(data)
          },
          error: (e) => console.error(e)
        });
    }
  }

  submitLine() {
    this.lineSubmitted = true
    if (this.lineForm.invalid) {
      return
    } else {
      this.loadingSubmitLine = true
      this.lineService.create(this.l.budget.value.id, this.l.title.value, this.l.description.value, this.l.domain.value, this.l.cost_limit.value)
        .subscribe({
          next: (response) => {
            this.lineSubmitted = false
            this.loadingSubmitLine = false
            this.calculatedPercentage = 0
            this.costLimit = 0
            this.modalService.dismissAll()
            this.lineForm.reset()
          },
          error: (e) => {
            this.lineSubmitted = false
            this.loadingSubmitLine = false
            console.error(e)
          }
        });
    }
  }

  retrieveLines() {
    this.lineService.getLinesOfProject(this.project.id)
      .subscribe({
        next: (data) => {
          this.lines = data;
          this.tempLineData = this.lines;
          this.lineRows = this.lines;
          this.exportCSVData = this.lines;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.lineService.refreshLine.subscribe(() => {
      this.retrieveLines();
    })
    this.retrieveLines();

    //line
    this.lineForm = this._formBuilder.group({
      budget: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      domain: ['', Validators.required],
      cost_limit: ['', Validators.required],
    });
  }

  modalOpen(modalForm, line) {
    this.currentLine = null
    this.modalRef = this.modalService.open(modalForm);
    this.costLimit = 0
    this.calculatedPercentage = 0
    this.lineForm.reset()
    if (line) {
      this.currentLine = line
      this.lineForm.patchValue({
        budget: line.budget_id,
        title: line.title,
        description: line.description,
        domain: line.domain,
        cost_limit: line.cost_limit,
      });
    }
  }

  filterLines(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempLineData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.lineRows = temp;
  }

  confirmDeleteAlert(line) {
    let operationName = ''
    if (line.operations.length != 0) {
      operationName = line.operations.map(function (item) { return item.title; }).join(", ");
      this.alertService.errorAlert(`Cant delete ${line.title}`, `This line is related to ${operationName} operations !`)
    } else {
      Swal.fire({
        title: `Are you sure you want to delete ${line.title}?`,
        text: `You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(res => this.deleteLine(res, line.id));
    }
  }

}
