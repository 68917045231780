import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { DatePickerI18nModule } from 'app/main/forms/form-elements/date-time-picker/date-picker-i18n/date-picker-i18n.module';
import { CsvModule } from '@ctrl/ngx-csv';

import { AuthGuard } from 'app/auth/helpers';

import { UserProfileComponent } from './user-profile/user-profile.component';

import { OperationComponent } from './operation/operation.component';
import { DetailOperationComponent } from './operation/detail-operation/detail-operation.component';
import { ActivityComponent } from './activity/activity.component';
import { LineComponent } from './line/line.component';
import { DomainDatatableComponent } from './domain-datatable/domain-datatable.component';


const routes = [
  {
    path: 'user/profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'detail/operation/:id',
    component: DetailOperationComponent,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  declarations: [
    UserProfileComponent,
    OperationComponent,
    DetailOperationComponent,
    LineComponent,
    ActivityComponent,
    DomainDatatableComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgxDatatableModule,
    CsvModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    DatePickerI18nModule,
    SweetAlert2Module.forRoot(),
  ],
  exports: [UserProfileComponent, OperationComponent, DetailOperationComponent, ActivityComponent, LineComponent, DomainDatatableComponent]
})
export class CommonComponentsModule { }
