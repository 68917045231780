import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  private _refreshActivity = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshActivity() {
    return this._refreshActivity;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/activities`);
  }

  getActivitiesOfProject(project) {
    return this._httpClient.get(`${environment.apiUrl}/activities_by_project/${project}`);
  }

  create(project, title, description) {
    return this._httpClient
      .post(`${environment.apiUrl}/create/activities`, { project, title, description })
      .pipe(
        tap(() => {
          this._refreshActivity.next()
        })
      );
  }

  update(id, title, description) {
    return this._httpClient
      .put(`${environment.apiUrl}/update/activities/${id}`, { title, description })
      .pipe(
        tap(() => {
          this._refreshActivity.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/delete/activities/${id}`)
      .pipe(
        tap(() => {
          this._refreshActivity.next()
        })
      );
  }

}
