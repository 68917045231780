import { Role } from './role';

export class User {
  id: number;
  email: string;
  password: string;
  association_id: number;
  funder_id: number;
  avatar: string;
  role: Role;
  token?: string;
}
