import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';
import { AssociationService } from 'app/service/association/association.service';
import { BudgetService } from 'app/service/budget/budget.service';

@Component({
  selector: 'app-new-budget',
  templateUrl: './new-budget.component.html',
  styleUrls: ['./new-budget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewBudgetComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);

  public accounts

  public createBudgetForm: FormGroup;
  public loadingSubmitBudget = false
  public budgetSubmitted = false

  constructor(private authenticationService: AuthenticationService,
    private associationService: AssociationService,
    private budgetService: BudgetService,
    private _formBuilder: FormBuilder,
    private _router: Router
  ) { }

  // convenience getter for easy access to budget form fields
  get b() {
    return this.createBudgetForm.controls;
  }

  submitBudget() {
    this.budgetSubmitted = true
    if (this.createBudgetForm.invalid) {
      return
    } else {
      this.loadingSubmitBudget = true
      this.budgetService.create(this.currentUser.association_id, this.b.account.value, null, null, this.b.title.value, this.b.type.value, this.b.initial_amount.value)
        .subscribe({
          next: (budget) => {
            this.loadingSubmitBudget = false
            this._router.navigate([`/association/edit/budget/${budget}`]);
          },
          error: (e) => {
            this.loadingSubmitBudget = false
            console.error(e)
          }
        });
    }
  }

  getAssociationAccounts() {
    this.associationService.getAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.accounts = data['accounts']
        },
        error: (e) => console.error(e)
      });
  }
  ngOnInit(): void {
    this.getAssociationAccounts();

    //budget
    this.createBudgetForm = this._formBuilder.group({
      title: ['', Validators.required],
      type: ['', Validators.required],
      account: [''],
      initial_amount: ['', Validators.required],
    });
  }

}
