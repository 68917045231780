import { Role } from 'app/auth/models';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { DatePickerI18nModule } from 'app/main/forms/form-elements/date-time-picker/date-picker-i18n/date-picker-i18n.module';
import { CsvModule } from '@ctrl/ngx-csv';

import { AuthGuard } from 'app/auth/helpers';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountComponent } from './account/account.component';
import { MemberComponent } from './member/member.component';
import { AssociationProfileComponent } from './association-profile/association-profile.component';
import { NewProjectComponent } from './project/new-project/new-project.component';
import { ProjectComponent } from './project/project.component';
import { MoneySliceComponent } from './operation/money-slice/money-slice.component';
import { WithdrawalComponent } from './operation/withdrawal/withdrawal.component';
import { CashOperationComponent } from './operation/cash-operation/cash-operation.component';
import { CashPayementComponent } from './operation/cash-operation/cash-payement/cash-payement.component';
import { CashDonationComponent } from './operation/cash-operation/cash-donation/cash-donation.component';
import { BankOperationComponent } from './operation/bank-operation/bank-operation.component';
import { BankPayementComponent } from './operation/bank-operation/bank-payement/bank-payement.component';
import { BankDonationComponent } from './operation/bank-operation/bank-donation/bank-donation.component';
import { ProofComponent } from './operation/proof/proof.component';
import { ReviewComponent } from './review/review.component';

import { ProjectDatatableComponent } from './project/project-datatable/project-datatable.component';

import { DomainComponent } from './domain/domain.component';
import { CommonComponentsModule } from 'app/common-components/common-components.module';
import { SubdomainComponent } from './domain/detail-domain/subdomain/subdomain.component';
import { DetailDomainComponent } from './domain/detail-domain/detail-domain.component';
import { BudgetComponent } from './budget/budget.component';
import { NewBudgetComponent } from './budget/new-budget/new-budget.component';
import { BudgetDatatableComponent } from './budget/budget-datatable/budget-datatable.component';
import { BudgetCashPayementComponent } from './budget-operation/budget-cash-payement/budget-cash-payement.component';
import { BudgetCashDonationComponent } from './budget-operation/budget-cash-donation/budget-cash-donation.component';
import { BudgetBankDonationComponent } from './budget-operation/budget-bank-donation/budget-bank-donation.component';
import { BudgetBankPayementComponent } from './budget-operation/budget-bank-payement/budget-bank-payement.component';

const routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'list/member',
    component: MemberComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'list/account',
    component: AccountComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'list/domain',
    component: DomainComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'detail/domain/:id',
    component: DetailDomainComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'profile',
    component: AssociationProfileComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'projects',
    component: ProjectDatatableComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] }
  },
  {
    path: 'new/project',
    component: NewProjectComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] }
  },
  {
    path: 'edit/project/:id',
    component: ProjectComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'budgets',
    component: BudgetDatatableComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] }
  },
  {
    path: 'new/budget',
    component: NewBudgetComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] }
  },
  {
    path: 'edit/budget/:id',
    component: BudgetComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: ':type/review/:id',
    component: ReviewComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'project/new/operation/money_slice/:id',
    component: MoneySliceComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'project/new/operation/withdrawal/:id',
    component: WithdrawalComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'project/new/operation/cash/:type/:id',
    component: CashOperationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'project/new/operation/bank/:type/:id',
    component: BankOperationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'budget/new/operation/cash_donation/:id',
    component: BudgetCashDonationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'budget/new/operation/cash_payement/:id',
    component: BudgetCashPayementComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'budget/new/operation/bank_donation/:id',
    component: BudgetBankDonationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
  {
    path: 'budget/new/operation/bank_payement/:id',
    component: BudgetBankPayementComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Association] },
  },
];

@NgModule({
  declarations: [
    DashboardComponent,
    AccountComponent,
    MemberComponent,
    AssociationProfileComponent,
    NewProjectComponent,
    ProjectComponent,
    MoneySliceComponent,
    WithdrawalComponent,
    CashOperationComponent,
    CashPayementComponent,
    CashDonationComponent,
    BankOperationComponent,
    BankPayementComponent,
    BankDonationComponent,
    ProofComponent,
    ReviewComponent,

    ProjectDatatableComponent,

    DomainComponent,
    SubdomainComponent,
    DetailDomainComponent,
    BudgetComponent,
    NewBudgetComponent,
    BudgetDatatableComponent,
    BudgetCashPayementComponent,
    BudgetCashDonationComponent,
    BudgetBankDonationComponent,
    BudgetBankPayementComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgxDatatableModule,
    CsvModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    DatePickerI18nModule,
    SweetAlert2Module.forRoot(),
    CommonComponentsModule,
  ],
  providers: [DatePipe],
  exports: []
})
export class AssociationModule { }