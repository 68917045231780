import { SafePipe } from '@core/pipes/safe.pipe';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'environments/environment';

import { AuthenticationService } from 'app/auth/service';
import { OperationService } from 'app/service/operation/operation.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-detail-operation',
  templateUrl: './detail-operation.component.html',
  styleUrls: ['./detail-operation.component.scss'],
})
export class DetailOperationComponent implements OnInit {

  filePlacement = environment.FilePlacementUrl

  public currentOperation
  public currentProof
  safePdfUrl
  loadedData = false

  constructor(
    private authenticationService: AuthenticationService,
    private operationService: OperationService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer) { }


  getOperation(id) {
    this.operationService.getOperation(id)
      .subscribe({
        next: (response) => {
          this.loadedData = true
          this.currentOperation = response
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.getOperation(id)
  }

  modalOpenLG(modalLG, proof) {
    this.modalService.open(modalLG, {
      centered: true,
      size: 'lg',
    });
    this.currentProof = proof
    this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.filePlacement + '/uploads/' + this.currentProof.document)
  }

}
