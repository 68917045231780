import { Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { SubdomainService } from 'app/service/subdomain/subdomain.service';

@Component({
  selector: 'app-subdomain',
  templateUrl: './subdomain.component.html',
  styleUrls: ['./subdomain.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubdomainComponent implements OnInit {

  @Input() domain;
  public currentSubdomain
  private tempSubdomainData = [];
  public subdomainRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedSubdomain: number = 25;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public subdomainForm: FormGroup;
  public subdomainSubmitted = false
  public loadingSubmitSubdomain = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private subdomainService: SubdomainService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder
  ) { }

  // convenience getter for easy access to subdomain form fields
  get o() {
    return this.subdomainForm.controls;
  }

  deleteSubdomain(res, id) {
    if (res.value == true) {
      this.subdomainService.delete(id)
        .subscribe({
          next: (response) => {
          },
          error: (e) => console.error(e)
        });
    }
  }

  updateSubdomain() {
    this.subdomainSubmitted = true
    if (this.subdomainForm.invalid) {
      return
    } else {
      this.loadingSubmitSubdomain = true
      this.subdomainService.update(this.currentSubdomain.id, this.o.title.value)
        .subscribe({
          next: (response) => {
            this.subdomainSubmitted = false
            this.loadingSubmitSubdomain = false
            this.modalService.dismissAll()
            this.subdomainForm.reset()
          },
          error: (e) => {
            this.loadingSubmitSubdomain = false
            this.subdomainSubmitted = false
            console.error(e)
          }
        });
    }
  }

  createSubdomain() {
    this.subdomainSubmitted = true
    if (this.subdomainForm.invalid) {
      return
    } else {
      this.loadingSubmitSubdomain = true
      this.subdomainService.create(this.domain.id, this.o.title.value)
        .subscribe({
          next: (response) => {
            this.subdomainSubmitted = false
            this.loadingSubmitSubdomain = false
            this.modalService.dismissAll()
            this.subdomainForm.reset()
          },
          error: (e) => {
            this.loadingSubmitSubdomain = false
            this.subdomainSubmitted = false
            console.error(e)
          }
        });
    }
  }
  submitSubdomain() {
    if (this.currentSubdomain) {
      this.updateSubdomain()
    } else {
      this.createSubdomain()
    }
  }

  ngOnInit(): void {

    //subdomain
    this.subdomainForm = this._formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  modalOpen(modalForm, subdomain) {
    this.currentSubdomain = null
    this.subdomainForm.reset()
    this.modalService.open(modalForm);
    if (subdomain) {
      this.currentSubdomain = subdomain
      this.subdomainForm.patchValue({
        title: subdomain.title,
      });
    }
  }

  filterSubdomains(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempSubdomainData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.subdomainRows = temp;
  }

  confirmDeleteSubdomain(subdomain) {
    Swal.fire({
      title: `Vous êtes sûr de vouloir supprimer ${subdomain.title}?`,
      text: 'Vous ne serez pas en mesure de la récupérer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteSubdomain(res, subdomain.id));
  }
}
