<div class="card" *ngIf="domains">
    <div class="row">
        <div class="col-md-6 col-12">
            <h2 class="mt-1 ml-1" style="color: #FF649D">Association domains</h2>
        </div>
        <div *ngIf="currentUser.role == 'Association'" class="col-md-6 col-12 d-flex justify-content-md-end">
            <button class="btn btn-primary float-right m-1 mr-2" (click)="modalOpen(modalDomainForm)" rippleEffect>
                Add New domain
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center">Show
                    <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    entries</label>
            </div>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                        class="form-control ml-25" (keyup)="filterActivities($event)"
                        (search)="filterActivities($event)" /></label>
            </div>
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary mr-1" rippleEffect>
                    Export CSV</a>
            </div>
        </div>
    </div>

    <!-- domains Datatable -->
    <ngx-datatable [rows]="domainRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="basicSelectedOption"
        [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true">
        <ngx-datatable-column name="Title" prop="title" [width]="120"></ngx-datatable-column>
        <ngx-datatable-column name="Description" prop="description" [width]="180"></ngx-datatable-column>
        <ngx-datatable-column name="Total expenses" [width]="60" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span class="badge badge-pill badge-light-primary text-right mr-1">
                    {{ calculateExpensesInDomain(row.operations) }}DT
                </span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="currentUser.role == 'Association'" name="Actions" [width]="120" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <div class="d-flex align-items-center">
                    <button type="button" routerLink="/association/detail/domain/{{row.id}}"
                        class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                        <i data-feather="edit"></i>
                    </button>
                    <button type="button" (click)="confirmDeleteAlert(row)"
                        class="btn btn-raised btn-outline-danger btn-sm" rippleEffect>
                        <i data-feather="trash"></i>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <!--/ domains Datatable -->
</div>

<!-- Domain Modal -->
<ng-template #modalDomainForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">New domain</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form [formGroup]="domainForm" (ngSubmit)="createDomain()">
            <label>Title: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="title"
                    [ngClass]="{ 'is-invalid': domainSubmitted && a.title.errors }" />
                <div *ngIf="domainSubmitted && a.title.errors" class="invalid-feedback">
                    <div *ngIf="a.title.errors.required">Title is required</div>
                </div>
            </div>
            <label>Description: </label>
            <div class="form-group">
                <textarea type="text" class="form-control" formControlName="description"
                    [ngClass]="{ 'is-invalid': domainSubmitted && a.description.errors }"></textarea>
                <div *ngIf="domainSubmitted && a.description.errors" class="invalid-feedback">
                    <div *ngIf="a.description.errors.required">Description is required</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" [disabled]="loadingSubmitDomain">
                    <span *ngIf="loadingSubmitDomain" class="spinner-border spinner-border-sm mr-1"></span>
                    Submit
                </button>
            </div>
        </form>
    </div>
</ng-template>
<!-- / Domain Modal -->