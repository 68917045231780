import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DatePipe } from '@angular/common';


import { AuthenticationService } from 'app/auth/service';
import { AssociationService } from 'app/service/association/association.service';
import { ParameterService } from 'app/service/parameter/parameter.service';
import { ReviewService } from 'app/service/review/review.service';
import { ProjectService } from 'app/service/project/project.service';
import { AccountService } from 'app/service/account/account.service';
import { FunderService } from 'app/service/funder/funder.service';
import { BudgetService } from 'app/service/budget/budget.service';

import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewProjectComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);

  public projectCategories
  public accounts
  public funders

  public project_step = false;
  public budget_step = false;
  public step = 1;

  public logo;
  public logoURL

  public createAccountForm: FormGroup;
  public accountSubmitted = false
  public loadingSubmitAccount = false

  public createBudgetForm: FormGroup;
  public budgetSubmitted = false

  public createProjectForm: FormGroup;
  public projectSubmitted = false
  public loadingSubmitProject = false

  constructor(
    private authenticationService: AuthenticationService,
    private associationService: AssociationService,
    private parameterService: ParameterService,
    private projectService: ProjectService,
    private accountService: AccountService,
    private funderService: FunderService,
    private budgetService: BudgetService,
    private reviewService: ReviewService,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private _router: Router,
    public datepipe: DatePipe
  ) { }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range'
  };

  // convenience getter for easy access to account form fields
  get a() {
    return this.createAccountForm.controls;
  }
  // convenience getter for easy access to project form fields
  get p() {
    return this.createProjectForm.controls;
  }
  // convenience getter for easy access to budget form fields
  get b() {
    return this.createBudgetForm.controls;
  }

  selectLogo(event) {
    this.logo = event.target.files[0];
    let reader = new FileReader();
    reader.onload = function () {
      let output: any = document.getElementById('logo');
      output.src = reader.result;
    }
    reader.readAsDataURL(this.logo);
  }

  next() {
    if (this.step == 1) {
      this.projectSubmitted = true
      this.project_step = true;
      if (this.createProjectForm.invalid) { return }
      this.step++
    }
  }

  previous() {
    this.step--
    if (this.step == 1) {
      this.project_step = false;
    }
  }

  submitAccount() {
    this.accountSubmitted = true
    if (this.createAccountForm.invalid) {
      return;
    } else {
      this.loadingSubmitAccount = true
      this.accountService.create(this.currentUser.association_id, this.a.bank_name.value, this.a.initial_balance.value)
        .subscribe({
          next: (response) => {
            this.loadingSubmitAccount = false
            this.accountSubmitted = false
            this.modalService.dismissAll()
            this.createAccountForm.reset()
          },
          error: (e) => {
            this.loadingSubmitAccount = false
            console.error(e)
          }
        });
    }
  }

  submitBudget(project) {
    this.budgetService.create(this.currentUser.association_id, this.b.account.value, project, this.b.funder.value, this.b.title.value, 'cash_bank', this.b.initial_amount.value)
      .subscribe({
        next: (response) => {
          this.loadingSubmitProject = false
          this._router.navigate([`/association/edit/project/${project}`]);
        },
        error: (e) => {
          this.loadingSubmitProject = false
          console.error(e)
        }
      });
  }

  submitProject(review) {
    let startDate = this.datepipe.transform(this.p.time_frame.value[0], 'yyyy/MM/dd')
    let endDate = this.datepipe.transform(this.p.time_frame.value[1], 'yyyy/MM/dd')
    this.projectService.create(this.currentUser.association_id, review, this.p.title.value, this.p.description.value, this.p.category.value, startDate, endDate, this.logo)
      .subscribe({
        next: (project) => {
          this.submitBudget(project)
        },
        error: (e) => {
          this.loadingSubmitProject = false
          console.error(e)
        }
      });
  }

  submitReview() {
    this.projectSubmitted = false
    this.budgetSubmitted = true
    if (this.createBudgetForm.invalid) {
      return
    } else {
      this.loadingSubmitProject = true
      this.reviewService.create(this.currentUser.association_id, this.p.title.value)
        .subscribe({
          next: (review) => {
            this.submitProject(review)
          },
          error: (e) => {
            this.loadingSubmitProject = false
            console.error(e)
          }
        });
    }
  }

  getFunderList() {
    this.funderService.getAll()
      .subscribe({
        next: (response) => {
          this.funders = response
        },
        error: (e) => console.error(e)
      });
  }

  getAssociationAccounts() {
    this.associationService.getAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.accounts = data['accounts']
        },
        error: (e) => console.error(e)
      });
  }

  getProjectCategory() {
    this.parameterService.getProjectCategoryParameter()
      .subscribe({
        next: (response) => {
          this.projectCategories = response
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getFunderList()
    this.accountService.refreshAccount.subscribe(() => {
      this.getAssociationAccounts();
    })
    this.getAssociationAccounts();

    this.getProjectCategory()

    //account
    this.createAccountForm = this._formBuilder.group({
      bank_name: ['', [Validators.required]],
      initial_balance: ['', Validators.required],
    });

    //project
    this.createProjectForm = this._formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
      time_frame: ['', Validators.required],
      logo: [''],
    });

    //budget
    this.createBudgetForm = this._formBuilder.group({
      title: ['', Validators.required],
      account: ['', Validators.required],
      initial_amount: ['', Validators.required],
      funder: [''],
    });
  }

  modalOpenForm(modal) {
    this.modalService.open(modal);
  }
}
