<div class="blog-wrapper">
  <div class="content-wrapper container-xxl p-0">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="content-detached content-left">
      <div class="content-body">
        <!-- Blog Detail -->
        <div class="blog-detail-wrapper">
          <div class="row">
            <!-- Blog -->
            <div class="col-12">
              <div class="card">
                <img [src]="data.blogDetail.blog.img" class="img-fluid card-img-top" alt="Blog Detail Pic" />
                <div class="card-body">
                  <h4 class="card-title">{{ data.blogDetail.blog.title }}</h4>
                  <div class="media">
                    <div class="avatar mr-50">
                      <img [src]="data.blogDetail.blog.avatar" alt="Avatar" width="24" height="24" />
                    </div>
                    <div class="media-body">
                      <small class="text-muted mr-25">by</small>
                      <small
                        ><a href="javascript:void(0);" class="text-body">{{ data.blogDetail.blog.username }}</a></small
                      >
                      <span class="text-muted ml-50 mr-25">|</span>
                      <small class="text-muted">{{ data.blogDetail.blog.postedAt }}</small>
                    </div>
                  </div>
                  <div class="my-1 py-25">
                    <a href="javascript:void(0);" *ngFor="let tagsRef of data.blogDetail.blog.tags">
                      <div
                        class="badge badge-pill mr-50"
                        [ngClass]="{
                          'bg-light-info': tagsRef === 'Quote',
                          'bg-light-primary': tagsRef === 'Fashion',
                          'bg-light-danger': tagsRef === 'Gaming',
                          'bg-light-warning': tagsRef === 'Video',
                          'bg-light-success': tagsRef === 'Food'
                        }"
                      >
                        {{ tagsRef }}
                      </div>
                    </a>
                  </div>
                  <p class="card-text mb-2" [innerHTML]="data.blogDetail.blog.content"></p>
                  <div class="media">
                    <div class="avatar mr-2">
                      <img src="assets/images/portrait/small/avatar-s-6.jpg" width="60" height="60" alt="Avatar" />
                    </div>
                    <div class="media-body">
                      <h6 class="font-weight-bolder">Willie Clark</h6>
                      <p class="card-text mb-0">
                        Based in London, Uncode is a blog by Willie Clark. His posts explore modern design trends
                        through photos and quotes by influential creatives and web designer around the world.
                      </p>
                    </div>
                  </div>
                  <hr class="my-2" />
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <div class="d-flex align-items-center mr-1">
                        <a href="javascript:void(0);" class="mr-50">
                          <i data-feather="message-square" class="font-medium-5 text-body align-middle"></i>
                        </a>
                        <a href="javascript:void(0);">
                          <div class="text-body align-middle">{{ data.blogDetail.blog.comments }}</div>
                        </a>
                      </div>
                      <div class="d-flex align-items-center">
                        <a href="javascript:void(0);" class="mr-50">
                          <i data-feather="bookmark" class="font-medium-5 text-body align-middle"></i>
                        </a>
                        <a href="javascript:void(0);">
                          <div class="text-body align-middle">{{ data.blogDetail.blog.bookmarked }}</div>
                        </a>
                      </div>
                    </div>
                    <div ngbDropdown class="blog-detail-share">
                      <a type="button" ngbDropdownToggle>
                        <i
                          data-feather="share-2"
                          class="font-medium-5 text-body cursor-pointer"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></i>
                      </a>
                      <div ngbDropdownMenu class="dropdown-menu-right">
                        <a href="javascript:void(0);" ngbDropdownItem class="py-50 px-1">
                          <i data-feather="github" class="font-medium-3"></i>
                        </a>
                        <a href="javascript:void(0);" ngbDropdownItem class="py-50 px-1">
                          <i data-feather="gitlab" class="font-medium-3"></i>
                        </a>
                        <a href="javascript:void(0);" ngbDropdownItem class="py-50 px-1">
                          <i data-feather="facebook" class="font-medium-3"></i>
                        </a>
                        <a href="javascript:void(0);" ngbDropdownItem class="py-50 px-1">
                          <i data-feather="twitter" class="font-medium-3"></i>
                        </a>
                        <a href="javascript:void(0);" ngbDropdownItem class="py-50 px-1">
                          <i data-feather="linkedin" class="font-medium-3"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Blog -->

            <!-- Blog Comment -->
            <div class="col-12 mt-1" id="blogComment">
              <h6 class="section-label mt-25">Comment</h6>
              <div class="card" *ngFor="let commentsRef of data.blogDetail.comments">
                <div class="card-body">
                  <div class="media">
                    <div class="avatar mr-75">
                      <img [src]="commentsRef.avatar" width="38" height="38" alt="Avatar" />
                    </div>
                    <div class="media-body">
                      <h6 class="font-weight-bolder mb-25">{{ commentsRef.username }}</h6>
                      <p class="card-text">{{ commentsRef.commentedAt }}</p>
                      <p class="card-text">{{ commentsRef.commentText }}</p>
                      <a href="javascript:void(0);">
                        <div class="d-inline-flex align-items-center">
                          <i data-feather="corner-up-left" class="font-medium-3 mr-50"></i>
                          <span>Reply</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Blog Comment -->

            <!-- Leave a Blog Comment -->
            <div class="col-12 mt-1">
              <h6 class="section-label mt-25">Leave a Comment</h6>
              <div class="card">
                <div class="card-body">
                  <form action="javascript:void(0);" class="form">
                    <div class="row">
                      <div class="col-sm-6 col-12">
                        <div class="form-group mb-2">
                          <input type="text" class="form-control" placeholder="Name" />
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="form-group mb-2">
                          <input type="email" class="form-control" placeholder="Email" />
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="form-group mb-2">
                          <input type="url" class="form-control" placeholder="Website" />
                        </div>
                      </div>
                      <div class="col-12">
                        <textarea class="form-control mb-2" rows="4" placeholder="Comment"></textarea>
                      </div>
                      <div class="col-12">
                        <div class="custom-control custom-checkbox mb-2">
                          <input type="checkbox" class="custom-control-input" id="blogCheckbox" />
                          <label class="custom-control-label" for="blogCheckbox"
                            >Save my name, email, and website in this browser for the next time I comment.</label
                          >
                        </div>
                      </div>
                      <div class="col-12">
                        <button type="submit" class="btn btn-primary" rippleEffect>Post Comment</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!--/ Leave a Blog Comment -->
          </div>
        </div>
        <!--/ Blog Detail -->
      </div>
    </div>
    <div class="sidebar-detached sidebar-right">
      <div class="sidebar">
        <div class="blog-sidebar my-2 my-lg-0">
          <!-- Search bar -->
          <div class="blog-search">
            <div class="input-group input-group-merge">
              <input type="text" class="form-control" placeholder="Search here" />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer">
                  <i data-feather="search"></i>
                </span>
              </div>
            </div>
          </div>
          <!--/ Search bar -->

          <!-- Recent Posts -->
          <div class="blog-recent-posts mt-3">
            <h6 class="section-label">Recent Posts</h6>
            <div class="mt-75">
              <div class="media mb-2" *ngFor="let recentPostsRef of data.blogSidebar.recentPosts">
                <a routerLink="/pages/blog-details/{{ recentPostsRef.id }}" class="mr-2">
                  <img class="rounded" [src]="recentPostsRef.img" width="100" height="70" alt="Recent Post Pic" />
                </a>
                <div class="media-body">
                  <h6 class="blog-recent-post-title">
                    <a routerLink="/pages/blog-details/{{ recentPostsRef.id }}" class="text-body-heading">{{
                      recentPostsRef.title
                    }}</a>
                  </h6>
                  <div class="text-muted mb-0">{{ recentPostsRef.postedAt }}</div>
                </div>
              </div>
            </div>
          </div>
          <!--/ Recent Posts -->

          <!-- Categories -->
          <div class="blog-categories mt-3">
            <h6 class="section-label">Categories</h6>
            <div class="mt-1" *ngFor="let categoriesRef of data.blogSidebar.categories">
              <div class="d-flex justify-content-start align-items-center mb-75">
                <a href="javascript:void(0);" class="mr-75">
                  <div
                    class="avatar bg-light-primary rounded"
                    class="avatar bg-light-primary rounded"
                    [ngClass]="{
                      'bg-light-primary': categoriesRef.icon === 'watch',
                      'bg-light-success': categoriesRef.icon === 'shopping-cart',
                      'bg-light-danger': categoriesRef.icon === 'command',
                      'bg-light-info': categoriesRef.icon === 'hash',
                      'bg-light-warning': categoriesRef.icon === 'video'
                    }"
                  >
                    <div class="avatar-content">
                      <i [data-feather]="categoriesRef.icon" class="avatar-icon font-medium-1"></i>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0);">
                  <div class="blog-category-title text-body">{{ categoriesRef.category }}</div>
                </a>
              </div>
            </div>
          </div>
          <!--/ Categories -->
        </div>
      </div>
    </div>
  </div>
</div>
