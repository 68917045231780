import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

import { environment } from 'environments/environment';

import { AuthenticationService } from 'app/auth/service';
import { BudgetService } from 'app/service/budget/budget.service';
import { AccountService } from 'app/service/account/account.service';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BudgetComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  filePlacement = environment.FilePlacementUrl

  @ViewChild('modalCashTransfer') cashModal: any;
  @ViewChild('modalBankTransfer') bankModal: any;

  public currentBudget
  public accounts

  public budgetForm: FormGroup;
  public budgetSubmitted = false
  public loadingSubmitBudget = false

  public modalRef

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private budgetService: BudgetService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _router: Router,
  ) {
  }
  // convenience getter for easy access to budget form fields
  get b() {
    return this.budgetForm.controls;
  }

  deleteBudget(res, id) {
    if (res.value == true) {
      this.budgetService.delete(id)
        .subscribe({
          next: (response) => {
            this._router.navigate([`/association/budgets`]);
          },
          error: (e) => console.error(e)
        });
    }
  }

  updateBudget() {
    this.budgetSubmitted = true
    if (this.budgetForm.invalid) {
      return
    } else {
      this.loadingSubmitBudget = true
      this.budgetService.update(this.currentBudget.id, this.b.account.value, null, this.b.title.value, this.b.initial_amount.value)
        .subscribe({
          next: (response) => {
            this.loadingSubmitBudget = false
            this.budgetSubmitted = false
            this.modalService.dismissAll()
            this.budgetForm.reset()
          },
          error: (e) => {
            this.loadingSubmitBudget = false
            this.budgetSubmitted = false
            console.error(e)
          }
        });
    }
  }

  getAccountsByAssociation(): void {
    this.accountService.getAccountsByAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.accounts = data;
        },
        error: (e) => console.error(e)
      });
  }

  getBudgetData(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.budgetService.getBudget(id)
      .subscribe({
        next: (data) => {
          this.currentBudget = data;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.budgetService.refreshBudget.subscribe(() => {
      this.getBudgetData()
    })
    this.getBudgetData()
    this.getAccountsByAssociation()

    //budget
    this.budgetForm = this._formBuilder.group({
      title: ['', Validators.required],
      account: [''],
      initial_amount: ['', Validators.required],
    });
  }

  modalOpen(modal) {
    this.budgetForm.reset()
    this.modalRef = this.modalService.open(modal);
  }
  modalEditBudgetOpen(modal) {
    this.modalService.open(modal);
    this.budgetForm.patchValue({
      title: this.currentBudget.title,
      account: this.currentBudget.account ? this.currentBudget.account : '',
      initial_amount: this.currentBudget.initial_amount,
    });
  }
  modalOpenLG(modalLG) {
    this.currentBudget = null
    this.modalService.open(modalLG, {
      centered: true,
      size: 'lg'
    });
  }

  confirmDeleteProject(budget) {
    Swal.fire({
      title: `Are you sure you want to delete ${budget.title}?`,
      text: 'You wont be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteBudget(res, budget.id));
  }

}
