<!--  Withdrawal -->
<div *ngIf="loadedData">
    <div class="text-center">
        <h1 class="mt-1">Cash withdrawal</h1>
        <p class="mb-2 pb-75">
            This operation will transfer money from your budget's bank account balance to your budget's cash balance.
        </p>
    </div>
    <div class='row justify-content-center'>
        <div class="card col-3 mx-1">
            <div class="card-body pb-50 ">
                <div class='row'>
                    <div class='col-6'>
                        <h6>Total Bank accounts balance</h6>
                        <h4 class="fw-bolder mb-1">{{ remainingBankAmount }} DT</h4>
                    </div>
                    <div class='col-6'>
                        <img class='float-end' width="80"
                            src="https://img.freepik.com/vecteurs-premium/cartes-credit-icone-carte-debit-credit-vecteur-vues-avant-arriere-systeme-technologie-paiement-sans-contact-maquettes-vectorielles-carte-credit-payer-acheter_435184-640.jpg?w=1060" />
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-3 me-1">
            <div class="card-body pb-50 ">
                <div class='row'>
                    <div class='col-6'>
                        <h6> Total Cash balance</h6>
                        <h4 class="fw-bolder mb-1">{{ remainingCashAmount }} DT</h4>
                    </div>
                    <div class='col-6'>
                        <img class='float-end' width="100"
                            src="https://img.freepik.com/premium-vector/banknote-gold-coins-with-silver-coins_383392-209.jpg?w=740 " />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card standard-pricing popular text-center col-6 offset-3">
        <div class="card-body">
            <img src="https://img.freepik.com/free-vector/atm-machine-with-stack-coins-cash_1308-105380.jpg?t=st=1657230327~exp=1657230927~hmac=f12230f9c0644b664898981b7eaab34a93ba99f052d39221ab55e56cd6f0b8b4&w=740"
                class="mb-1" alt="svg img" width="200" />
            <form [formGroup]="operationWithdrawalForm">
                <h3>Choose a budget</h3>
                <div class="row justify-content-center">
                    <div class="card col-4 budgetOption" *ngFor="let budget of currentProject.budgets">
                        <label>
                            <input type="radio" class="card-input-element" formControlName="budget"
                                value="{{ budget.id }}"
                                (change)="handleChosenBudget(budget.bank_amount, budget.cash_amount)" />
                            <div class="card card-input border-secondary bg-light p-1">
                                <h4>{{ budget.title }}</h4>
                                This budget have
                                <span><b>{{ budget.bank_amount }} DT</b> in bank </span>
                                and
                                <span><b>{{ budget.cash_amount }} DT</b> in cash.</span>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="annual-plan">
                    <div class="plan-price">
                        <span class="pricing-standard-value fw-bolder text-primary">
                            <div class='row custom-options-checkable justify-content-center'>
                                <input type="number" class='col-6 form-control text-center' formControlName="amount"
                                    placeholder='insert the amount to be transfered' />
                            </div>
                        </span>
                    </div>
                </div>
            </form>
            <span class="text-danger">{{ message }}</span>
            <button class="btn btn-success mt-2 waves-effect waves-float waves-light" (click)="transfer()"
                [disabled]="operationWithdrawalForm.invalid || loadingOperation">
                <span *ngIf="loadingOperation" class="spinner-border spinner-border-sm mr-1"></span>
                Transfer
            </button>
        </div>
    </div>
</div>
<!--  /Withdrawal -->