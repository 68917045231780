import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrOptions } from 'ng2-flatpickr';
import Swal from 'sweetalert2';

import { DatePipe } from '@angular/common';

import { environment } from 'environments/environment';

import { AuthenticationService } from 'app/auth/service';
import { AssociationService } from 'app/service/association/association.service';
import { DomainService } from 'app/service/domain/domain.service';
import { ProjectService } from 'app/service/project/project.service';
import { FunderService } from 'app/service/funder/funder.service';
import { BudgetService } from 'app/service/budget/budget.service';
import { ParameterService } from 'app/service/parameter/parameter.service';
import { LineService } from 'app/service/line/line.service';
import { AlertService } from 'app/service/alert/alert.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  filePlacement = environment.FilePlacementUrl

  @ViewChild('modalCashTransfer') cashModal: any;
  @ViewChild('modalBankTransfer') bankModal: any;

  public loadedData = false

  public currentAssociation;
  public currentProject
  public currentBudget = null
  public sumOfBankAmount: number
  public sumOfCashAmount: number
  public sumOfMoneySlices = 0
  public remainingSlice = 0
  public calculateProgress: number
  lines = []

  public funders
  public projectCategories
  public totalProjectAmount: number
  public totalBankAmount: number
  public totalCashAmount: number

  public projectLogo
  disableOperation = 0

  public editProjectForm: FormGroup;
  public projectSubmitted = false
  public loadingSubmitEditProject = false

  public budgetForm: FormGroup;
  public budgetSubmitted = false
  public loadingSubmitBudget = false
  public loadingDeleteBudget = false

  public modalRef

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private associationService: AssociationService,
    private domainService: DomainService,
    private parameterService: ParameterService,
    private projectService: ProjectService,
    private funderService: FunderService,
    private budgetService: BudgetService,
    private lineService: LineService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private alertService: AlertService,
    private datepipe: DatePipe
  ) {
  }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range'
  };

  // convenience getter for easy access to edit project form fields
  get p() {
    return this.editProjectForm.controls;
  }
  // convenience getter for easy access to budget form fields
  get b() {
    return this.budgetForm.controls;
  }

  selectLogo(event) {
    this.projectLogo = event.target.files[0];
    let reader = new FileReader();
    reader.onload = function () {
      let output: any = document.getElementById('projectLogo');
      output.src = reader.result;
    }
    reader.readAsDataURL(this.projectLogo);
  }

  checkOperation(operation, id) {
    this.disableOperation = 0
    for (let b = 0; b < this.currentProject.budgets.length; b++) {
      let sum = 0
      for (let l = 0; l < this.currentProject.budgets[b].lines.length; l++) {
        sum += parseFloat(this.currentProject.budgets[b].lines[l].cost_limit)
      }
      if (sum != this.currentProject.budgets[b].initial_amount) {
        this.disableOperation += 1
      }
    }
    if (this.disableOperation != 0) {
      this.alertService.errorAlert('Cant commit any operation yet', 'All budgets must have lines limits that adds up to a 100% before being able to make any operations')
    } else {
      switch (operation) {
        case 'withdrawal': {
          this._router.navigate([`/association/project/new/operation/withdrawal/${id}`]);
          break;
        }
        case 'cash': {
          this.modalService.open(this.cashModal, {
            centered: true,
            size: 'lg'
          });
          break;
        }
        case 'bank': {
          this.modalService.open(this.bankModal, {
            centered: true,
            size: 'lg'
          });
          break;
        }
      }
    }
  }

  deleteProject(res, id) {
    if (res.value == true) {
      this.projectService.delete(id)
        .subscribe({
          next: (response) => {
            this._router.navigate([`/association/projects`]);
          },
          error: (e) => console.error(e)
        });
    }
  }
  submitEditProject() {
    this.projectSubmitted = true
    if (this.editProjectForm.invalid) {
      return
    } else {
      let startDate; let endDate
      if (!this.p.time_frame.dirty) {
        startDate = null
        endDate = null
      } else {
        startDate = this.datepipe.transform(this.p.time_frame.value[0], 'yyyy/MM/dd')
        endDate = this.datepipe.transform(this.p.time_frame.value[1], 'yyyy/MM/dd')
      }
      this.projectService.update(this.currentProject.id, this.p.title.value, this.p.description.value, this.p.category.value, startDate, endDate, this.projectLogo)
        .subscribe({
          next: (project) => {
            this.projectSubmitted = false
            this.loadingSubmitEditProject = false
            this.modalService.dismissAll()
          },
          error: (e) => {
            this.projectSubmitted = false
            this.loadingSubmitEditProject = false
            console.error(e)
          }
        });
    }
  }

  deleteBudget(res, id) {
    if (res.value == true) {
      this.loadingDeleteBudget = true
      this.budgetService.delete(id)
        .subscribe({
          next: (response) => {
            this.loadingDeleteBudget = false
            this.currentBudget = null
          },
          error: (e) => {
            this.loadingDeleteBudget = false
            console.error(e)
          }
        });
    }
  }
  updateBudget() {
    this.budgetSubmitted = true
    if (this.budgetForm.invalid) {
      return
    } else {
      if (this.b.initial_amount.value >= this.sumOfMoneySlices) {
        this.loadingSubmitBudget = true
        this.budgetService.update(this.currentBudget.id, this.b.account.value, this.b.funder.value, this.b.title.value, this.b.initial_amount.value)
          .subscribe({
            next: (response) => {
              this.loadingSubmitBudget = false
              this.budgetSubmitted = false
              this.modalService.dismissAll()
              this.budgetForm.reset()
            },
            error: (e) => {
              this.loadingSubmitBudget = false
              this.budgetSubmitted = false
              console.error(e)
            }
          });
      } else {
        this.alertService.errorAlert('Cant modify budgets initial amount', `The entered value must exceed ${this.sumOfMoneySlices}!`)
      }
    }
  }
  createBudget() {
    this.budgetSubmitted = true
    if (this.budgetForm.invalid) {
      return
    } else {
      this.loadingSubmitBudget = true
      this.budgetService.create(this.currentAssociation.id, this.b.account.value, this.currentProject.id, this.b.funder.value, this.b.title.value, 'cash_bank', this.b.initial_amount.value)
        .subscribe({
          next: (response) => {
            this.loadingSubmitBudget = false
            this.budgetSubmitted = false
            this.modalService.dismissAll()
            this.budgetForm.reset()
          },
          error: (e) => {
            this.loadingSubmitBudget = false
            this.budgetSubmitted = false
            console.error(e)
          }
        });
    }
  }
  getBudget(budget) {
    this.currentBudget = budget
    this.budgetForm.patchValue({
      title: budget.title,
      account: budget.account,
      funder: budget.funder && budget.funder.id ? budget.funder.fullName : '',
      initial_amount: budget.initial_amount,
    });
    if (budget.operations.length != 0) {
      this.sumOfMoneySlices = 0
      for (let o = 0; o < budget.operations.length; o++) {
        if (budget.operations[o].label === 'money_slice') {
          this.sumOfMoneySlices += parseFloat(budget.operations[o].amount)
        }
      }
      this.remainingSlice = budget.initial_amount - this.sumOfMoneySlices
      this.calculateProgress = Math.round((this.sumOfMoneySlices / budget.initial_amount) * 100)
    } else {
      this.sumOfMoneySlices = 0
      this.remainingSlice = budget.initial_amount
      this.calculateProgress = 0
    }
  }

  getProjectData(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.projectService.getProject(id)
      .subscribe({
        next: (data) => {
          this.currentProject = data; // activities, budgets, lines, operations
          this.totalProjectAmount = 0; this.totalBankAmount = 0; this.totalCashAmount = 0
          let currentAmount = 0
          for (let b = 0; b < this.currentProject.budgets.length; b++) {
            this.totalProjectAmount += this.currentProject.budgets[b].initial_amount
            currentAmount += this.currentProject.budgets[b].current_amount
            this.totalBankAmount += parseFloat(this.currentProject.budgets[b].bank_amount)
            this.totalCashAmount += parseFloat(this.currentProject.budgets[b].cash_amount)
          }
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  getAssociationData() {
    this.associationService.getAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.currentAssociation = data // accounts, domains
        },
        error: (e) => console.error(e)
      });
  }

  getFunderList() {
    this.funderService.getAll()
      .subscribe({
        next: (response) => {
          this.funders = response
        },
        error: (e) => console.error(e)
      });
  }

  getProjectCategory() {
    this.parameterService.getProjectCategoryParameter()
      .subscribe({
        next: (response) => {
          this.projectCategories = response
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getFunderList()
    this.getProjectCategory()

    this.domainService.refreshDomain.subscribe(() => {
      this.getAssociationData()
    })
    this.getAssociationData()

    this.projectService.refreshProject.subscribe(() => {
      this.getProjectData()
    })
    this.budgetService.refreshBudget.subscribe(() => {
      this.getProjectData()
    })
    this.lineService.refreshLine.subscribe(() => {
      this.getProjectData()
    })
    this.getProjectData()

    //project
    this.editProjectForm = this._formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
      time_frame: ['', Validators.required],
      logo: [''],
    });

    //budget
    this.budgetForm = this._formBuilder.group({
      title: ['', Validators.required],
      account: ['', Validators.required],
      initial_amount: ['', Validators.required],
      funder: [''],
    });
  }

  modalOpen(modal) {
    this.budgetForm.reset()
    this.modalRef = this.modalService.open(modal);
  }
  modalEditProjectOpen(modal, project) {
    this.modalService.open(modal);
    this.editProjectForm.patchValue({
      title: project.title,
      description: project.description,
      category: project.category,
      time_frame: project.start_date,
      logo: project.logo
    });
  }
  modalOpenLG(modalLG) {
    this.currentBudget = null
    this.modalService.open(modalLG, {
      centered: true,
      size: 'lg'
    });
  }

  confirmDeleteBudget(budget) {
    Swal.fire({
      title: `Are you sure you want to delete ${budget.title}?`,
      text: 'You wont be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteBudget(res, budget.id));
  }
  confirmDeleteProject(project) {
    Swal.fire({
      title: `Are you sure you want to delete ${project.title}?`,
      text: 'You wont be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteProject(res, project.id));
  }

}
