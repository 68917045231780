<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Brand logo--><a class="brand-logo" href="javascript:void(0);">
      <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
      <h2 class="brand-text text-primary ml-1">SoftBudget</h2>
    </a>
    <!-- /Brand logo-->
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <img class="img-fluid" [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/register-v2-dark.svg'
              : 'assets/images/pages/register-v2.svg'
          " alt="Register V2" />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Register-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">Adventure starts here 🚀</h2>
        <p class="card-text mb-2">Make your project budget management easy!</p>
        <form [formGroup]="registerAssociationForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="title-column">Name</label>
                <input type="text" id="title-column" class="form-control" placeholder="Name" name="title-column"
                  formControlName="title" aria-describedby="registerAssociation-title" tabindex="1" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="description-column">Description</label>
                <input type="text" id="description-column" class="form-control" placeholder="Description"
                  name="description-column" formControlName="description"
                  aria-describedby="registerAssociation-description" tabindex="2" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="address-column">Address</label>
                <input type="text" id="address-column" class="form-control" placeholder="Address" name="address-column"
                  formControlName="address" aria-describedby="registerAssociation-address" tabindex="3" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="association_website-column">Website</label>
                <input type="text" id="association_website-column" class="form-control" placeholder="Website"
                  name="association_website-column" formControlName="association_website"
                  aria-describedby="registerAssociation-association_website" tabindex="4" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="basicInputFile">Banner</label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="banner-column" name="banner-column"
                    formControlName="banner" aria-describedby="registerAssociation-banner" tabindex="8"
                    (change)="uploadBanner($event)" />
                  <label class="custom-file-label" for="banner">Choose banner</label>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="reference_jort-column">Reference</label>
                <input type="text" id="reference_jort-column" class="form-control" placeholder="Reference"
                  name="reference_jort-column" formControlName="reference_jort"
                  aria-describedby="registerAssociation-reference_jort" tabindex="6" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="fundation_date-column">Fundation date</label>
                <div class="input-group">
                  <input class="form-control" id="fundation_date-column" placeholder="yyyy-mm-dd" name="fundation_date"
                    [(ngModel)]="basicDPdata" formControlName="fundation_date"
                    aria-describedby="registerAssociation-fundation_date" ngbDatepicker #basicDP="ngbDatepicker"
                    tabindex="7">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()"
                      type="button" rippleEffect></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="basicInputFile">Logo</label>
                <div class="custom-file">
                  <input type="file" class="custom-file-input" id="logo-column" name="logo-column"
                    formControlName="logo" aria-describedby="registerAssociation-logo" tabindex="8"
                    (change)="uploadLogo($event)" />
                  <label class="custom-file-label" for="logo">Choose logo</label>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="category-column">Catgory</label>
                <select id="category-column" class="form-control" name="category-column" formControlName="category"
                  aria-describedby="createProject-category">
                  <option *ngFor="let category of categories" value="{{category.title}}">{{ category.title }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="cin-column">Cin</label>
                <input type="text" id="cin-column" class="form-control" placeholder="Description" name="cin-column"
                  formControlName="cin" aria-describedby="registerAssociation-cin" tabindex="2" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="email-id-column">Email</label>
                <input type="email" id="email-id-column" class="form-control" name="email-id-column" placeholder="Email"
                  formControlName="email" aria-describedby="registerAssociation-email" tabindex="9"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="registerAssociation-password" class="form-label">Password</label>

                <div class="input-group input-group-merge form-password-toggle">
                  <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password"
                    class="form-control form-control-merge" placeholder="············" formControlName="password"
                    aria-describedby="registerAssociation-password" tabindex="10"
                    [ngClass]="{ 'is-invalid error': submitted && f.password.errors }" />
                  <div class="input-group-append">
                    <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                          'icon-eye-off': passwordTextType,
                          'icon-eye': !passwordTextType
                        }" (click)="togglePasswordTextType()"></i></span>
                  </div>
                </div>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback"
                  [ngClass]="{ 'd-block': submitted && f.password.errors }">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 text-right">
              <button [disabled]="loading" class="btn btn-primary" rippleEffect>
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign up
              </button>
            </div>
          </div>
        </form>
        <p class="text-center mt-2">
          <span>Already have an account?</span><a routerLink="/pages/authentication/login-v2"><span>&nbsp;Sign in
              instead</span></a>
        </p>
      </div>
    </div>
    <!-- /Register-->
  </div>
</div>