import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);

  public role;

  constructor(private authenticationService: AuthenticationService, private _router: Router) {
  }

  redirectUser() {
    this.role = this.currentUser.role
    switch (this.role) {
      case "Association":
        this._router.navigate([`/association/dashboard`]);
        break;
      case "Funder":
        this._router.navigate([`/funder/dashboard`]);
        break;
      default:
        console.log('admin')
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.redirectUser()
  }
}
