import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { repeaterAnimation } from 'app/main/forms/form-repeater/form-repeater.animation';

import { ProofService } from 'app/service/proof/proof.service';

@Component({
  selector: 'app-proof',
  templateUrl: './proof.component.html',
  styleUrls: ['./proof.component.scss'],
  animations: [repeaterAnimation],
})
export class ProofComponent implements OnInit {

  @Input() objectType
  @Input() objectId
  @Output() checkProof = new EventEmitter<boolean>();

  operationProof = []

  public createProofForm: FormGroup;
  public proofSubmitted = false

  constructor(
    private proofService: ProofService,
    private _formBuilder: FormBuilder,
    private _router: Router) {
  }
  // convenience getter for easy access to proof form fields
  get p() {
    return this.createProofForm.controls;
  }

  selectOperationProof(event) {
    this.operationProof.push(event.target.files[0]);
    if (this.proofs[0].proofFile) {
      this.checkProof.emit(true);
    }
  }

  public proofs = [{ proofId: '', proofTitle: '', proofDescription: '', proofFile: '' }];
  public proof = {
    proofTitle: '',
    proofDescription: '',
    proofFile: ''
  };
  addItem() {
    this.proofs.push({
      proofId: '',
      proofTitle: '',
      proofDescription: '',
      proofFile: ''
    });
  }
  deleteItem(id) {
    for (let i = 0; i < this.proofs.length; i++) {
      if (this.proofs.indexOf(this.proofs[i]) === id) {
        this.proofs.splice(i, 1);
        break;
      }
    }
  }

  submitProof(operation) {
    for (let p = 0; p <= this.proofs.length; p++) {
      if (this.proofs[p].proofFile) {
        this.proofService.create(operation, this.proofs[p].proofTitle, this.proofs[p].proofDescription, this.operationProof[p])
          .subscribe({
            next: (data) => {
              if (p + 1 == this.proofs.length) {
                this._router.navigate([`/association/edit/${this.objectType}/${this.objectId}`]);
              }
            },
            error: (e) => {
              console.error(e)
            }
          });
      } else {
        this.proofs.splice(p, 1);
        if (p + 1 == this.proofs.length) {
          this._router.navigate([`/association/edit/${this.objectType}/${this.objectId}`]);
        }
      }
    }
  }

  ngOnInit(): void {
    //proof
    this.createProofForm = this._formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      operationProof: ['', Validators.required],
    });
  }
}
