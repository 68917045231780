<form [formGroup]="createDonationOperationForm" class="form form-horizontal" (ngSubmit)="confirmOperation()">
    <div class="form-group">
        <div class="col-sm-3 col-form-label">
            <label>Choose a budget:</label>
        </div>
        <div class="row justify-content-center text-center">
            <div class="card col-3 mr-1" *ngFor="let budget of currentProject.budgets">
                <label>
                    <input type="radio" [value]="budget" class="card-input-element" formControlName="budget" />
                    <div class="card card-input border-secondary bg-light p-1">
                        <h4>{{ budget.title }}</h4>
                        In this budget you have
                        <span><b>{{ budget.bank_amount }} DT</b> in bank </span>
                        and
                        <span><b>{{ budget.cash_amount + budget.bank_amount }} DT</b> in total.</span>
                    </div>
                </label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Operation name:</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="title"
                        [ngClass]="{ 'is-invalid': donationOperationSubmitted && d_o.title.errors }" />
                    <div *ngIf="donationOperationSubmitted && d_o.title.errors" class="invalid-feedback">
                        <div *ngIf="d_o.title.errors.required">Title is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Note</label>
                </div>
                <div class="col-sm-6">
                    <textarea class="form-control" formControlName="note"></textarea>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Operation reference (cheque or transfer number):</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="reference"
                        [ngClass]="{ 'is-invalid': donationOperationSubmitted && d_o.reference.errors }" />
                    <div *ngIf="donationOperationSubmitted && d_o.reference.errors" class="invalid-feedback">
                        <div *ngIf="d_o.reference.errors.required">Referrence is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Transaction type</label>
                </div>
                <div class="col-sm-6">
                    <ng-select formControlName="transaction_type"
                        [ngClass]="{ 'is-invalid': donationOperationSubmitted && d_o.transaction_type.errors }">
                        <ng-option *ngFor="let transactionType of transactionTypes" value="{{transactionType.value}}">
                            {{transactionType.value}}
                        </ng-option>
                    </ng-select>
                    <div *ngIf="donationOperationSubmitted && d_o.transaction_type.errors" class="invalid-feedback">
                        <div *ngIf="d_o.transaction_type.errors.required">Transaction type is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Operation date</label>
                </div>
                <div class="col-sm-6">
                    <div class="input-group input-group-merge">
                        <input class="form-control" formControlName="operation_date" [(ngModel)]="basicDPdata"
                            ngbDatepicker #basicDP="ngbDatepicker" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()"
                                type="button" rippleEffect></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Amount to transfer</label>
                </div>
                <div class="col-sm-6">
                    <input type="number" class="form-control" formControlName="amount"
                        [ngClass]="{ 'is-invalid': donationOperationSubmitted && d_o.amount.errors }" />
                    <div *ngIf="donationOperationSubmitted && d_o.amount.errors" class="invalid-feedback">
                        <div *ngIf="d_o.amount.errors.required">Amount is required</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group row">
            <div class="col-sm-3 col-form-label">
                <label>Bank operation proof</label>
            </div>
            <div class="col-sm-6">
                <div class="custom-file">
                    <input type="file" class="custom-file-input" formControlName="bank_proof"
                        (change)="getBankProof($event)" />
                    <label class="custom-file-label">Choose a document</label>
                </div>
            </div>
        </div>
    </div>
    <app-proof objectType="project" [objectId]="currentProject.id" (checkProof)="handleProof($event)"></app-proof>
    <div class="card-footer text-right">
        <button type="submit" class="btn btn-success" [disabled]="loadingSubmitBankDonation">
            <span *ngIf="loadingSubmitBankDonation" class="spinner-border spinner-border-sm mr-1"></span>
            Submit
        </button>
    </div>
</form>