import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/helpers';

import { AuthLoginV2Component } from './main/pages/authentication/auth-login-v2/auth-login-v2.component';
import { AuthRegisterV2AssociationComponent } from './main/pages/authentication/auth-register-v2-association/auth-register-v2-association.component';
import { AuthRegisterV2FunderComponent } from './main/pages/authentication/auth-register-v2-funder/auth-register-v2-funder.component';
import { AuthForgotPasswordV2Component } from './main/pages/authentication/auth-forgot-password-v2/auth-forgot-password-v2.component';

import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'authentication/login-v2',
    component: AuthLoginV2Component
  },
  {
    path: 'authentication/register-v2-association',
    component: AuthRegisterV2AssociationComponent
  },
  {
    path: 'authentication/register-v2-funder',
    component: AuthRegisterV2FunderComponent
  },
  {
    path: 'authentication/forgot-password-v2',
    component: AuthForgotPasswordV2Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
