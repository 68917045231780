<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <!-- Reset Password v1 -->
    <div class="card mb-0">
      <div class="card-body">
        <a href="javascript:void(0);" class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
          <h2 class="brand-text text-primary ml-1">Vuexy</h2>
        </a>

        <h4 class="card-title mb-1">Reset Password 🔒</h4>
        <p class="card-text mb-2">Your new password must be different from previously used passwords</p>

        <form class="auth-reset-password-form mt-2" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-new">New Password</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="newPassword"
                class="form-control form-control-merge"
                placeholder="············"
                aria-describedby="reset-password-new"
                tabindex="1"
                autofocus
                [ngClass]="{ 'is-invalid error': submitted && f.newPassword.errors }"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.newPassword.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.newPassword.errors }"
            >
              <div *ngIf="f.newPassword.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-confirm">Confirm Password</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="confPasswordTextType ? 'text' : 'password'"
                formControlName="confirmPassword"
                class="form-control form-control-merge"
                placeholder="············"
                aria-describedby="reset-password-confirm"
                tabindex="2"
                [ngClass]="{ 'is-invalid error': submitted && f.confirmPassword.errors }"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': confPasswordTextType,
                      'icon-eye': !confPasswordTextType
                    }"
                    (click)="toggleConfPasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.confirmPassword.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.confirmPassword.errors }"
            >
              <div *ngIf="f.confirmPassword.errors.required">Password is required</div>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="3" rippleEffect>Set New Password</button>
        </form>

        <p class="text-center mt-2">
          <a routerLink="/pages/authentication/login-v1">
            <i data-feather="chevron-left" class="mb-25"></i> Back to login
          </a>
        </p>
      </div>
    </div>
    <!-- /Reset Password v1 -->
  </div>
</div>
