<div class="card" *ngIf="domains">
    <div class="row">
        <div class="col-md-6 col-12">
            <h2 class="mt-1 ml-1" style="color: #FF649D">Association domains</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center">Show
                    <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    entries</label>
            </div>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                        class="form-control ml-25" (keyup)="filterActivities($event)"
                        (search)="filterActivities($event)" /></label>
            </div>
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary mr-1" rippleEffect>
                    Export CSV</a>
            </div>
        </div>
    </div>

    <!-- domains Datatable -->
    <ngx-datatable [rows]="domainRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="5"
        [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true">

        <ngx-datatable-column name="Title" prop="title" [width]="120"></ngx-datatable-column>
        <ngx-datatable-column name="Description" prop="description" [width]="180"></ngx-datatable-column>
        <ngx-datatable-column name="Total expenses" [width]="60" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span class="badge badge-pill badge-light-primary text-right mr-1">
                    {{ calculateExpensesInDomain(row.operations) }}DT
                </span>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <!--/ domains Datatable -->
</div>