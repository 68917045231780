<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <!-- Register v1 -->
    <div class="card mb-0">
      <div class="card-body">
        <a href="javascript:void(0);" class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
          <h2 class="brand-text text-primary ml-1">Vuexy</h2>
        </a>

        <h4 class="card-title mb-1">Adventure starts here 🚀</h4>
        <p class="card-text mb-2">Make your app management easy and fun!</p>

        <form class="auth-register-form mt-2" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="register-username" class="form-label">Username</label>
            <input
              type="text"
              formControlName="username"
              class="form-control"
              placeholder="johndoe"
              aria-describedby="register-username"
              tabindex="1"
              autofocus
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
            />
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Username is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="register-email" class="form-label">Email</label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
              placeholder="john@example.com"
              aria-describedby="register-email"
              tabindex="2"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>

          <div class="form-group">
            <label for="register-password" class="form-label">Password</label>

            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="password"
                class="form-control form-control-merge"
                placeholder="············"
                aria-describedby="register-password"
                tabindex="3"
                [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.password.errors }"
            >
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" type="checkbox" id="register-privacy-policy" tabindex="4" />
              <label class="custom-control-label" for="register-privacy-policy">
                I agree to <a href="javascript:void(0);">privacy policy & terms</a>
              </label>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="5" rippleEffect>Sign up</button>
        </form>

        <p class="text-center mt-2">
          <span>Already have an account? </span>
          <a routerLink="/pages/authentication/login-v1">
            <span> Sign in instead</span>
          </a>
        </p>

        <div class="divider my-2">
          <div class="divider-text">or</div>
        </div>

        <div class="auth-footer-btn d-flex justify-content-center">
          <a href="javascript:void(0)" class="btn btn-facebook">
            <i data-feather="facebook"></i>
          </a>
          <a href="javascript:void(0)" class="btn btn-twitter white">
            <i data-feather="twitter"></i>
          </a>
          <a href="javascript:void(0)" class="btn btn-google">
            <i data-feather="mail"></i>
          </a>
          <a href="javascript:void(0)" class="btn btn-github">
            <i data-feather="github"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- /Register v1 -->
  </div>
</div>
