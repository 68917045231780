import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LineService {

  private _refreshLine = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshLine() {
    return this._refreshLine;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/line`);
  }

  getLinesOfProject(project) {
    return this._httpClient.get(`${environment.apiUrl}/liste/lines_of_project/${project}`);
  }
  getLinesOfSection(section) {
    return this._httpClient.get(`${environment.apiUrl}/liste/lines_of_section/${section}`);
  }

  create(budget, title, description, domain, cost_limit) {
    return this._httpClient
      .post(`${environment.apiUrl}/create/line`, { budget, title, description, domain, cost_limit })
      .pipe(
        tap(() => {
          this._refreshLine.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/delete/line/${id}`)
      .pipe(
        tap(() => {
          this._refreshLine.next()
        })
      );
  }
}
