<div class="card" *ngIf="lines">
    <div class="row">
        <div class="col-md-6 col-12">
            <h2 class="mt-1 ml-1" style="color: #FF649D">Project lines</h2>
        </div>
        <div *ngIf="currentUser.role == 'Association'" class="col-md-6 col-12 d-flex justify-content-md-end">
            <button class="btn btn-primary float-right m-1 mr-2" (click)="modalOpen(modalLineForm)" rippleEffect>
                Add New line
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center">Show
                    <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    entries</label>
            </div>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                        class="form-control ml-25" (keyup)="filterLines($event)"
                        (search)="filterLines($event)" /></label>
            </div>
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary mr-1" rippleEffect>
                    Export CSV</a>
            </div>
        </div>
    </div>

    <!-- lines Datatable -->
    <ngx-datatable [rows]="lineRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="basicSelectedOption"
        [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true">

        <ngx-datatable-column name="Title" prop="title" [width]="90"></ngx-datatable-column>
        <ngx-datatable-column name="Description" prop="description" [width]="180"></ngx-datatable-column>
        <ngx-datatable-column name="Budget balance (DT)" [width]="90">
            <ng-template ngx-datatable-cell-template let-row="row" class="text-right">
                {{ row.budget_initial_amount.toFixed(2) }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Cost limit" [width]="120" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span class="badge badge-pill badge-light-primary mr-1">
                    {{ ((row.cost_limit/row.budget_initial_amount)*100).toFixed(2) }}%
                </span>
                <span>
                    {{ parseFloat(row.cost_limit).toFixed(2) }}
                </span>DT
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="currentUser.role == 'Association'" name="Total expenses" [width]="120"
            [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <span class="badge badge-pill badge-light-primary mr-1">
                    {{ (calculateTotalExpenses(row)).toFixed(2) }}%
                </span>
                {{ (sumOfSpendings).toFixed(2) }}DT
                <br>
                <div class="progress-wrapper mt-1">
                    <ngb-progressbar type="{{totalSpendingBarColor}}"
                        [value]="calculateTotalExpenses(row)"></ngb-progressbar>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="currentUser.role == 'Association'" name="Actions" [width]="90" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <div class="d-flex align-items-center">
                    <button type="button" (click)="confirmDeleteAlert(row)"
                        class="btn btn-raised btn-outline-danger btn-sm" rippleEffect>
                        <i data-feather="trash"></i>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <!--/ lines Datatable -->
</div>

<!-- Line Modal -->
<ng-template #modalLineForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">New line</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form [formGroup]="lineForm" (ngSubmit)="submitLine()">
            <label>Choose a budget:</label>
            <div class="row justify-content-center">
                <div class="col-4" *ngFor="let budget of  project ? project.budgets:section.budgets; index as b">
                    <label>
                        <input type="radio" formControlName="budget" [value]="budget" class="card-input-element"
                            name="budget" (change)="handleChosenBudget(budget)" />
                        <div class="card-input bg-light p-1">
                            <h6>{{ budget.title }}</h6>
                            <small>Total: {{ budget.initial_amount }}DT</small>
                        </div>
                    </label>
                </div>
            </div>
            <!-- ngb-alert component -->
            <ngb-alert [type]="'danger'" *ngIf="disableBudget && costLimit==0" class="mt-1" [dismissible]="false">
                <div class="alert-body">
                    <span [data-feather]="'info'" [class]="'mr-50 align-middle'"></span>
                    <span>This budget have reached <strong>100%</strong> of their line limits</span>
                </div>
            </ngb-alert>
            <!--/ ngb-alert component -->
            <!-- ngb-alert component -->
            <ngb-alert [type]="leftPercentColor" *ngIf="costLimit" class="mt-1" [dismissible]="false">
                <div class="alert-body">
                    <span [data-feather]="'info'" [class]="'mr-50 align-middle'"></span>
                    <span><strong>{{(costLimit).toFixed(2)}}</strong>DT of the budget is left</span>
                </div>
            </ngb-alert>
            <!--/ ngb-alert component -->
            <div *ngIf="costLimit != 0 || currentLine">
                <label>Title: </label>
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="title"
                        [ngClass]="{ 'is-invalid': lineSubmitted && l.title.errors }" />
                    <div *ngIf="lineSubmitted && l.description.errors" class="invalid-feedback">
                        <div *ngIf="l.description.errors.required">Title is required</div>
                    </div>
                </div>
                <label>Description: </label>
                <div class="form-group">
                    <textarea class="form-control" formControlName="description"
                        [ngClass]="{ 'is-invalid': lineSubmitted && l.description.errors }"></textarea>
                    <div *ngIf="lineSubmitted && l.description.errors" class="invalid-feedback">
                        <div *ngIf="l.description.errors.required">Description is required</div>
                    </div>
                </div>
                <label>Domain: </label>
                <div class="form-group">
                    <ng-select formControlName="domain" [ngClass]="{ 'is-invalid': lineSubmitted && l.domain.errors }">
                        <ng-option *ngFor="let domain of association.domains" value="{{domain.id}}">
                            {{ domain.title }}
                        </ng-option>
                    </ng-select>
                    <div *ngIf="lineSubmitted && l.domain.errors" class="invalid-feedback">
                        <div *ngIf="l.domain.errors.required">Domain is required</div>
                    </div>
                </div>
                <label>Cost limit: </label>
                <div class="form-group">
                    <div class="progress-wrapper mb-1">
                        <ngb-progressbar [type]="primary" showValue="true"
                            [value]="calculatedPercentage"></ngb-progressbar>
                    </div>
                    <div class="input-group input-group-merge">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><span [data-feather]="'dollar-sign'"></span></span>
                        </div>
                        <input type="number" class="form-control" formControlName="cost_limit"
                            (input)="getPercentage($event)"
                            [ngClass]="{ 'is-invalid': lineSubmitted && l.cost_limit.errors }" />
                    </div>
                    <div *ngIf="lineSubmitted && l.cost_limit.errors" class="invalid-feedback">
                        <div *ngIf="l.cost_limit.errors.required">Cost limit is required</div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" [disabled]="disableSubmit || loadingSubmitLine">
                        <span *ngIf="loadingSubmitLine" class="spinner-border spinner-border-sm mr-1"></span>
                        Submit
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<!-- / Line Modal -->