<div class="text-center" *ngIf="!currentBudget">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="currentBudget">
    <div class='card col-12'>
        <div class="row m-1">
            <div class="col-9">
                <h3>{{ currentBudget.title }}</h3>
                <span>Type:
                    {{currentBudget.type === 'cash_bank' ? 'Cash and Bank':currentBudget.type === 'cash' ?
                    'Cash':'Bank'}}
                </span>
            </div>
            <div class="col-3 text-right">
                <button type="button" (click)="modalEditBudgetOpen(modalBudgetForm)"
                    class="btn btn-outline-primary mr-1" rippleEffect>
                    <i data-feather="edit"></i>
                </button>
                <button type="button" class="btn btn-outline-danger" (click)="confirmDeleteProject(currentBudget)"
                    rippleEffect>
                    <i data-feather="archive"></i>
                </button>
            </div>
        </div>
    </div>

    <div class='row justify-content-center'>
        <div class="card col-4 mx-1">
            <div class="card-body pb-50">
                <div class='row justify-content-center align-items-center '>
                    <div class='col-7 ps-'>
                        <h6>Current Balance For This Budget</h6>
                        <h2 class="fw-bolder mb-1">{{ (currentBudget.current_amount).toFixed(2) }} DT</h2>
                    </div>
                    <div class='col-5'>
                        <img class='float-end' width="150"
                            src="https://img.freepik.com/free-vector/finances-management-budget-assessment-financial-literacy-accounting-idea-financier-with-cash-economist-holding-golden-coin-cartoon-character_335657-1604.jpg?t=st=1657896220~exp=1657896820~hmac=44670ae1eae2f3a1c71ee708bcfd0afbbcb8cb1b56e095b2c8a7aa07591c1ade&w=740" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card card-body pb-50 row mb-50">
                <div class='row'>
                    <div class='col-8'>
                        <h6>Bank balance</h6>
                        <h2 class="fw-bolder">{{ (currentBudget.bank_amount).toFixed(2) }} DT</h2>
                    </div>
                    <div class='col-2'>
                        <img width="80"
                            src="https://img.freepik.com/vecteurs-premium/cartes-credit-icone-carte-debit-credit-vecteur-vues-avant-arriere-systeme-technologie-paiement-sans-contact-maquettes-vectorielles-carte-credit-payer-acheter_435184-640.jpg?w=1060" />
                    </div>
                </div>
            </div>
            <div class="card card-body pb-50 row">
                <div class='row'>
                    <div class='col-8'>
                        <h6>Cash balance</h6>
                        <h2 class="fw-bolder">{{ (currentBudget.cash_amount).toFixed(2) }} DT</h2>
                    </div>
                    <div class='col-2'>
                        <img width="80"
                            src="https://img.freepik.com/premium-vector/banknote-gold-coins-with-silver-coins_383392-209.jpg?w=740 " />
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-4 mx-1" role="button" routerLink="/association/budget/review/{{ currentBudget.id }}">
            <div class="card-body pb-50">
                <div class='row justify-content-center align-items-center '>
                    <div class='col-6 ps-0'>
                        <h3 class="fw-bolder mb-1">Visit Budget Statistics</h3>
                    </div>
                    <div class='col-6'>
                        <img class='float-end' width="160"
                            src="https://img.freepik.com/free-vector/clock-increasing-chart-workflow-productivity-increase-work-performance-optimization-efficiency-indicator-rising-effectiveness-metrics-vector-isolated-concept-metaphor-illustration_335657-2718.jpg?t=st=1658759098~exp=1658759698~hmac=fe4c6495f85362e91e938c65d1b87cf52b7e275210f5719ccd8ca7aba18382fe&w=740" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class='row justify-content-center'>
        <div *ngIf="currentBudget.type != 'bank'" class="card col-2 mx-1 border-primary" role="button"
            routerLink="/association/budget/new/operation/cash_payement/{{ currentBudget.id }}">
            <div class="card-body pb-50">
                <div class='col-12 text-center'>
                    <h6>Cash payement</h6>
                    <img width="100"
                        src="https://img.freepik.com/free-vector/cash-payment-concept-illustration_114360-3320.jpg?t=st=1658438234~exp=1658438834~hmac=84cf239fcf7cee9250f338faaa198d35d7617c8b9253e3161435985ae31899e5&w=740">
                </div>
            </div>
        </div>
        <div *ngIf="currentBudget.type != 'bank'" class="card col-2 mx-1 border-primary" role="button"
            routerLink="/association/budget/new/operation/cash_donation/{{ currentBudget.id }}">
            <div class="card-body pb-50">
                <div class='col-12 text-center'>
                    <h6>Cash donation</h6>
                    <img width="100"
                        src="https://img.freepik.com/free-vector/chargeback-abstract-concept-illustration_335657-3904.jpg?t=st=1658438837~exp=1658439437~hmac=fbe8cd9fa71d83572081f103c403533a271063f248f992be0b0888fffc404b8a&w=740">
                </div>
            </div>
        </div>
        <div *ngIf="currentBudget.type != 'cash'" class="card col-2 mx-1 border-primary" role="button"
            routerLink="/association/budget/new/operation/bank_payement/{{ currentBudget.id }}">
            <div class="card-body pb-50">
                <div class='col-12 text-center'>
                    <h6>Bank payement</h6>
                    <img width="100"
                        src="https://img.freepik.com/vecteurs-premium/illustrations-vectorielles-dessin-anime-isole-virement-bancaire_107173-21789.jpg?w=740" />
                </div>
            </div>
        </div>
        <div *ngIf="currentBudget.type != 'cash'" class="card col-2 mx-1 border-primary" role="button"
            routerLink="/association/budget/new/operation/bank_donation/{{ currentBudget.id }}">
            <div class="card-body pb-50">
                <div class='col-12 text-center'>
                    <h6>Bank donation</h6>
                    <img width="100"
                        src="https://img.freepik.com/free-vector/chargeback-abstract-concept-illustration_335657-3904.jpg?t=st=1658438837~exp=1658439437~hmac=fbe8cd9fa71d83572081f103c403533a271063f248f992be0b0888fffc404b8a&w=740">
                </div>
            </div>
        </div>
    </div>

    <!-- operation datatable -->
    <app-operation [budget]="currentBudget"></app-operation>
    <!--/ operation datatable -->

    <!-- domain datatable -->
    <app-domain-datatable></app-domain-datatable>
    <!--/ domain datatable -->

</div>

<!-- Budget Modal -->
<ng-template #modalBudgetForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">New budget</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form [formGroup]="budgetForm" (ngSubmit)="updateBudget()">
            <label>Title: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="title" placeholder="Title"
                    [ngClass]="{ 'is-invalid': budgetSubmitted && b.title.errors }" />
                <div *ngIf="budgetSubmitted && b.title.errors" class="invalid-feedback">
                    <div *ngIf="b.title.errors.required">Title is required</div>
                </div>
            </div>
            <div *ngIf="currentBudget.type != 'cash'">
                <label>Account: </label>
                <div class="form-group">
                    <ng-select formControlName="account"
                        [ngClass]="{ 'is-invalid': budgetSubmitted && b.account.errors }">
                        <ng-option *ngFor="let account of accounts" value="{{account.id}}">
                            {{ account.bank_name }}
                        </ng-option>
                    </ng-select>
                    <div *ngIf="budgetSubmitted && b.account.errors" class="invalid-feedback">
                        <div *ngIf="b.account.errors.required">Account is required</div>
                    </div>
                </div>
            </div>
            <label>Amount: </label>
            <div class="form-group">
                <input type="number" class="form-control" formControlName="initial_amount"
                    [disabled]="!currentBudget.operations && currentBudget.operations.length == 0" placeholder="Amount"
                    [ngClass]="{ 'is-invalid': budgetSubmitted && b.initial_amount.errors }" />
                <div *ngIf="budgetSubmitted && b.initial_amount.errors" class="invalid-feedback">
                    <div *ngIf="b.initial_amount.errors.required">Amount is required</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" [disabled]="loadingSubmitBudget">
                    <span *ngIf="loadingSubmitBudget" class="spinner-border spinner-border-sm mr-1"></span>
                    Submit
                </button>
            </div>
        </form>
    </div>
</ng-template>
<!-- / Budget Modal -->