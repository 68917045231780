import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssociationService {

  private _refreshAssociation = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshAssociation() {
    return this._refreshAssociation;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/associations`);
  }

  getAssociation(id) {
    return this._httpClient.get(`${environment.apiUrl}/associations/${id}`);
  }

  update(id, title, email, fundation_date, description, address, reference_jort, website) {
    return this._httpClient
      .put(`${environment.apiUrl}/update/associations/${id}`, { title, email, fundation_date, description, address, reference_jort, website })
      .pipe(
        tap(() => {
          this._refreshAssociation.next()
        })
      );
  }

}
