import { Component, Input, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap/datepicker/adapters/ngb-date-adapter';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { OperationService } from 'app/service/operation/operation.service';
import { ProofComponent } from '../../proof/proof.component';
import { AlertService } from 'app/service/alert/alert.service';

@Component({
  selector: 'app-bank-donation',
  templateUrl: './bank-donation.component.html',
  styleUrls: ['./bank-donation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BankDonationComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  @ViewChild(ProofComponent) child !: any;
  @Input() currentProject;
  @Input() transactionTypes;
  @ViewChildren(ProofComponent)

  public basicDPdata: NgbDateStructAdapter;

  loadedData = false
  proofExist = false
  bankProof 

  public createDonationOperationForm: FormGroup;
  public donationOperationSubmitted = false
  public loadingSubmitBankDonation = false


  constructor(
    private authenticationService: AuthenticationService,
    private operationService: OperationService,
    private _formBuilder: FormBuilder,
    private alertService: AlertService,
  ) { }

  // convenience getter for easy access to donation operation form fields
  get d_o() {
    return this.createDonationOperationForm.controls;
  }

  getBankProof(event) {
    this.bankProof = event.target.files[0];
  }

  submitBankDonation(res) {
    if (res.value == true) {
      this.loadingSubmitBankDonation = true
      let label = 'bank_donation'
      let bank_amount = this.d_o.budget.value.bank_amount + this.d_o.amount.value
      let operationDate = `${this.d_o.operation_date.value.year}/${this.d_o.operation_date.value.month}/${this.d_o.operation_date.value.day}`
      this.operationService.bankDonation(this.currentUser.id, this.currentProject.review, this.d_o.budget.value.id, label, this.d_o.title.value, this.d_o.note.value, this.d_o.reference.value, this.d_o.transaction_type.value, null, operationDate, this.d_o.amount.value, this.d_o.budget.value.cash_amount, bank_amount, this.bankProof)
        .subscribe({
          next: (operation) => {
            this.child.submitProof(operation)
            this.loadingSubmitBankDonation = false
            this.donationOperationSubmitted = false
          },
          error: (e) => {
            this.loadingSubmitBankDonation = false
            this.donationOperationSubmitted = false
            console.error(e)
          }
        });
    }
  }

  confirmOperation() {
    this.donationOperationSubmitted = true
    if (this.createDonationOperationForm.invalid) {
      return
    } else {
      if (this.proofExist) {
        Swal.fire({
          title: 'Are you sure you want to go through this transaction ?',
          text: `${this.d_o.amount.value} DT, will be transferred into your selected budget !`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          confirmButtonText: 'Yes, commit it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary ml-1'
          }
        }).then(res => this.submitBankDonation(res));
      } else {
        this.alertService.errorAlert('You cant commit this yet', 'You have to import at least one justification file');
      }
    }
  }

  handleProof(e) {
    if (!e) {
      this.proofExist = false
    } else {
      this.proofExist = true
    }
  }

  ngOnInit(): void {
    //donation operation
    this.createDonationOperationForm = this._formBuilder.group({
      budget: ['', Validators.required],
      title: ['', Validators.required],
      note: [''],
      reference: ['', Validators.required],
      transaction_type: ['', Validators.required],
      operation_date: [''],
      amount: ['', Validators.required],
      bank_proof: ['', Validators.required],
    });
  }

}
