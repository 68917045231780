<div class="card">
    <div class="card-body">
        <h4 class="mb-1">
            <i data-feather="user" [size]="16" class="mr-75 font-medium-4"></i>
            <span class="align-middle">Personal Information</span>
        </h4>

        <form [formGroup]="editUserForm" class="validate-form" (ngSubmit)="submitUpdateUser()">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" formControlName="email" />
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label>Cin</label>
                        <input type="number" class="form-control" formControlName="cin" />
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="form-group">
                        <label>Role</label>
                        <ng-select formControlName="role">
                            <ng-option>Association</ng-option>
                            <ng-option>Member</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-12 text-right">
                    <button type="submit" [disabled]="loadingSubmit" class="btn btn-primary mr-1 mt-1"
                        rippleEffect><span *ngIf="loadingSubmit" class="spinner-border spinner-border-sm mr-1"></span>
                        Save changes
                    </button>
                    <button type="reset" class="btn btn-outline-secondary mt-1" rippleEffect>Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>