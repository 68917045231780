import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { DomainService } from 'app/service/domain/domain.service';
import { SubdomainService } from 'app/service/subdomain/subdomain.service';

@Component({
  selector: 'app-detail-domain',
  templateUrl: './detail-domain.component.html',
  styleUrls: ['./detail-domain.component.scss']
})
export class DetailDomainComponent implements OnInit {

  public currentDomain
  public editableQuantityNotice = ''

  public domainForm: FormGroup;
  public domainSubmitted = false
  public loadingSubmitDomain = false

  constructor(
    private route: ActivatedRoute,
    private domainService: DomainService,
    private subdomainService: SubdomainService,
    private _formBuilder: FormBuilder
  ) { }

  // convenience getter for easy access to domain form fields
  get d() {
    return this.domainForm.controls;
  }

  updateDomain() {
    this.domainSubmitted = true
    if (this.domainForm.invalid) {
      return
    } else {
      this.loadingSubmitDomain = true
      this.domainService.update(this.currentDomain.id, this.d.title.value, this.d.description.value)
        .subscribe({
          next: (response) => {
            this.domainSubmitted = false
            this.loadingSubmitDomain = false
            this.domainForm.reset()
          },
          error: (e) => {
            this.loadingSubmitDomain = false
            this.domainSubmitted = false
            console.error(e)
          }
        });
    }
  }

  getDomain(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.domainService.getDomain(id)
      .subscribe({
        next: (data) => {
          this.currentDomain = data
          this.domainForm.patchValue({
            title: this.currentDomain.title,
            description: this.currentDomain.description,
          });
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.domainService.refreshDomain.subscribe(() => {
      this.getDomain()
    })
    this.subdomainService.refreshSubdomain.subscribe(() => {
      this.getDomain()
    })
    this.getDomain()

    //domain
    this.domainForm = this._formBuilder.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });
  }

}
