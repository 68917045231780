import { Component, OnInit, ViewEncapsulation, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap/datepicker/adapters/ngb-date-adapter';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { ProjectService } from 'app/service/project/project.service';
import { ParameterService } from 'app/service/parameter/parameter.service';
import { BudgetService } from 'app/service/budget/budget.service';
import { OperationService } from 'app/service/operation/operation.service';
import { AlertService } from 'app/service/alert/alert.service';
import { ProofComponent } from '../proof/proof.component';
import { DomainService } from 'app/service/domain/domain.service';

@Component({
  selector: 'app-money-slice-operation',
  templateUrl: './money-slice.component.html',
  styleUrls: ['./money-slice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoneySliceComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  @ViewChild(ProofComponent) child !: any;
  @ViewChildren(ProofComponent)

  public basicDPdata: NgbDateStructAdapter;

  public loadedData = false
  proofExist = false

  public currentProject;
  public currentBudget;

  public transactionTypes
  public domains
  public subdomains

  public sumOfBankAmount: number
  public sumOfCashAmount: number
  public sumOfMoneySlices = 0
  public remainingSlice = 0
  public calculateProgress: number

  public bankProof
  public operationProof

  public createOperationForm: FormGroup
  public operationSubmitted = false
  public loadingOperation = false


  constructor(
    private authenticationService: AuthenticationService,
    private projectService: ProjectService,
    private parameterService: ParameterService,
    private domainService: DomainService,
    private budgetService: BudgetService,
    private operationService: OperationService,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private _router: Router,
    private alertService: AlertService) { }

  // convenience getter for easy access to operation form fields
  get o() {
    return this.createOperationForm.controls;
  }

  getBankProof(event) {
    this.bankProof = event.target.files[0];
  }

  submitMoneySlice(res) {
    if (res.value) {
      this.loadingOperation = true
      let label = 'money_slice'
      let bank_amount = this.currentBudget.bank_amount + this.o.amount.value
      this.operationService.bankDonation(this.currentUser.id, this.currentProject.review, this.o.budget.value, label, this.o.title.value, this.o.note.value, this.o.reference.value, this.o.transaction_type.value, this.o.domain.value, this.o.operation_date.value, this.o.amount.value, this.currentBudget.cash_amount, bank_amount, this.bankProof)
        .subscribe({
          next: (operation) => {
            this.child.submitProof(operation)
            this.loadingOperation = false
          },
          error: (e) => {
            this.loadingOperation = false
            this.operationSubmitted = true
            console.error(e)
          }
        });
    }
  }

  confirmOperation() {
    this.operationSubmitted = true
    if (this.createOperationForm.invalid) {
      return;
    } else {
      if (this.proofExist) {
        console.log('here')
        Swal.fire({
          title: 'By this operation ',
          text: `${this.o.amount.value} DT, will be transferred into your selected budget !`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          confirmButtonText: 'Yes, commit it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary ml-1'
          }
        }).then(res => this.submitMoneySlice(res));
      } else {
        this.alertService.errorAlert('You cant commit this yet', 'You have to import at least one justification file');
      }
    }
  }

  handleChosenBudget(id, amount): void {
    this.budgetService.getBudget(id)
      .subscribe({
        next: (data) => {
          this.currentBudget = data;
          this.sumOfMoneySlices = 0
          for (let o = 0; o < this.currentBudget.operations.length; o++) {
            if (this.currentBudget.operations[o].label === 'money_slice') {
              this.sumOfMoneySlices += parseFloat(this.currentBudget.operations[o].amount)
            }
          }
          this.remainingSlice = this.currentBudget.initial_amount - this.currentBudget.current_amount
          this.calculateProgress = Math.round((this.sumOfMoneySlices / amount) * 100)
        },
        error: (e) => console.error(e)
      });
  }

  handleProof(e) {
    if (!e) {
      this.proofExist = false
    } else {
      this.proofExist = true
    }
  }

  getDomains() {
    this.domainService.getDomainsByAssociation(this.currentUser.association_id)
      .subscribe({
        next: (response) => {
          this.domains = response
          this.subdomains = this.domains.flatMap(item => item.subdomains)
        },
        error: (e) => console.error(e)
      });
  }

  getTransactionType() {
    this.parameterService.getTransactionTypeParameter()
      .subscribe({
        next: (response) => {
          this.transactionTypes = response
        },
        error: (e) => console.error(e)
      });
  }

  getProjectData(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.projectService.getProject(id)
      .subscribe({
        next: (data) => {
          this.currentProject = data;
          this.sumOfBankAmount = 0; this.sumOfCashAmount = 0
          for (let b = 0; b < this.currentProject.budgets.length; b++) {
            this.sumOfBankAmount += parseFloat(this.currentProject.budgets[b].bank_amount)
            this.sumOfCashAmount += parseFloat(this.currentProject.budgets[b].cash_amount)
          }
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getProjectData();
    this.getTransactionType();
    this.getDomains()

    //operation
    this.createOperationForm = this._formBuilder.group({
      budget: ['', Validators.required],
      title: ['', Validators.required],
      note: [''],
      reference: ['', Validators.required],
      transaction_type: ['', Validators.required],
      operation_date: [''],
      domain: [''],
      amount: ['', Validators.required],
      bank_proof: ['', Validators.required],
    });
  }

  modalOpenForm(modal) {
    this.modalService.open(modal);
  }

}
