import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  getAssociationCategoryParameter() {
    return this._httpClient.get(`${environment.apiUrl}/parameter/association/category`);
  }

  getProjectCategoryParameter() {
    return this._httpClient.get(`${environment.apiUrl}/parameter/project/category`);
  }

  getTransactionTypeParameter() {
    return this._httpClient.get(`${environment.apiUrl}/parameter/transaction/type`);
  }

  getMaterialCategoryParameter() {
    return this._httpClient.get(`${environment.apiUrl}/parameter/material/category`);
  }
  
}
