import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubdomainService {

  private _refreshSubdomain = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshSubdomain() {
    return this._refreshSubdomain;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/subdomain`);
  }

  create(domain, title) {
    return this._httpClient
      .post(`${environment.apiUrl}/create/subdomain`, { domain, title })
      .pipe(
        tap(() => {
          this._refreshSubdomain.next()
        })
      );
  }

  update(id, title) {
    return this._httpClient
      .put(`${environment.apiUrl}/update/subdomain/${id}`, { title })
      .pipe(
        tap(() => {
          this._refreshSubdomain.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/delete/subdomain/${id}`)
      .pipe(
        tap(() => {
          this._refreshSubdomain.next()
        })
      );
  }
}
