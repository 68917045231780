import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FunderService {

  private _refreshFunder = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshFunder() {
    return this._refreshFunder;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/funder`);
  }

  getFunder(id) {
    return this._httpClient.get(`${environment.apiUrl}/funder/${id}`);
  }
}
