import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { ProjectService } from 'app/service/project/project.service';
import { OperationService } from 'app/service/operation/operation.service';

@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.scss']
})
export class WithdrawalComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);

  loadedData = false

  public currentProject;
  public remainingCashAmount: number
  public remainingBankAmount: number
  public bankAmount
  public cashAmount
  message = ""

  public operationWithdrawalForm: FormGroup;
  public loadingOperation = false

  constructor(private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private projectService: ProjectService,
    private operationService: OperationService,
    private _formBuilder: FormBuilder,
    private _router: Router
  ) { }

  // convenience getter for easy access to opeartionwithdrawal form fields
  get o() {
    return this.operationWithdrawalForm.controls;
  }

  transfer() {
    if (this.o.amount.value <= this.bankAmount) {
      this.loadingOperation = true
      let title = "cash_withdrawal";
      let label = title
      let bank_amount = this.bankAmount - this.o.amount.value;
      let cash_amount = this.cashAmount + this.o.amount.value;
      this.operationService.cashWithdrawal(this.currentUser.id, this.o.budget.value, title, label, this.o.amount.value, bank_amount, cash_amount)
        .subscribe({
          next: (response) => {
            this.loadingOperation = false
            this._router.navigate([`/association/edit/project/${this.currentProject.id}`]);
          },
          error: (e) => {
            this.loadingOperation = false
            console.error(e)
          }
        });
    } else {
      this.message = "The entered value exceeds the amount of the chosen budget!"
    }
  }

  handleChosenBudget(bankAmount, cashAmount) {
    this.bankAmount = bankAmount;
    this.cashAmount = cashAmount;
  }

  getProjectData(): void {
    let id = this.route.snapshot.paramMap.get('id');

    this.projectService.getProject(id)
      .subscribe({
        next: (data) => {
          this.currentProject = data;
          this.remainingBankAmount = 0; this.remainingCashAmount = 0;
          for (let b = 0; b < this.currentProject.budgets.length; b++) {
            this.remainingBankAmount += parseFloat(this.currentProject.budgets[b].bank_amount)
            this.remainingCashAmount += parseFloat(this.currentProject.budgets[b].cash_amount)
          }
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getProjectData();

    //operation
    this.operationWithdrawalForm = this._formBuilder.group({
      budget: ['', Validators.required],
      amount: ['', Validators.required],
    });
  }

}
