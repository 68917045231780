<div *ngIf="loadedData">
    <!-- Project -->
    <div class='row justify-content-center'>
        <div class="card col-3 mx-1" *ngFor="let project of currentFunder.projects" role="button"
            routerLink="/funder/detail/project/{{ project.id }}">
            <div class="card-body pb-50 ">
                <div class='row'>
                    <div class='col-6'>
                        <h4>{{ project.title }}</h4>
                        <h6 class="fw-bolder mb-1">{{ project.description }}</h6>
                    </div>
                    <div class='col-6' *ngIf="project.logo">
                        <img class='float-end' width="80" src="{{ filePlacement }}/uploads/{{ project.logo }}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Project -->
</div>