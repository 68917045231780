<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Brand logo--><a class="brand-logo" href="javascript:void(0);">
      <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
      <h2 class="brand-text text-primary ml-1">SoftBudget</h2>
    </a>
    <!-- /Brand logo-->
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <img class="img-fluid" [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/register-v2-dark.svg'
              : 'assets/images/pages/register-v2.svg'
          " alt="Register V2" />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Register-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-12">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">No one has ever become poor from giving!</h2>
        <p class="card-text mb-2">Sign up to fund our projects.</p>
        <form class="auth-register-funder-form mt-2" [formGroup]="registerFunderForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="fullName-column">Full Name</label>
                <input type="text" id="fullName-column" class="form-control" placeholder="Full Name"
                  name="fullName-column" formControlName="fullName" aria-describedby="register-fullName" tabindex="1" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="phone-column">Phone</label>
                <input type="tel" id="phone-column" class="form-control" placeholder="+111 11 111 111"
                  name="phone-column" formControlName="phone" aria-describedby="register-phone" tabindex="2" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="reference-column">Reference</label>
                <input type="text" id="reference-column" class="form-control" placeholder="City" name="reference-column"
                  formControlName="reference" aria-describedby="register-reference" tabindex="3" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="basicInputFile">Logo</label>
                <input type="file" class="form-control-file" id="basicInputFile" formControlName="logo"
                  aria-describedby="register-logo" tabindex="4" (change)="uploadLogo($event)" />
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group">
                <label for="cin-column">Cin</label>
                <input type="number" id="cin-column" class="form-control" placeholder="City" name="cin-column"
                  formControlName="cin" aria-describedby="register-cin" tabindex="3" />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="email-id-column">Email</label>
                <input type="email" id="email-id-column" class="form-control" name="email-id-column" placeholder="Email"
                  formControlName="email" aria-describedby="register-email" tabindex="5"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="register-password" class="form-label">Password</label>

                <div class="input-group input-group-merge form-password-toggle">
                  <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password"
                    class="form-control form-control-merge" placeholder="············" formControlName="password"
                    aria-describedby="register-password" tabindex="6"
                    [ngClass]="{ 'is-invalid error': submitted && f.password.errors }" />
                  <div class="input-group-append">
                    <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                          'icon-eye-off': passwordTextType,
                          'icon-eye': !passwordTextType
                        }" (click)="togglePasswordTextType()"></i></span>
                  </div>
                </div>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback"
                  [ngClass]="{ 'd-block': submitted && f.password.errors }">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
              </div>
            </div>
            <div class="col-12 text-right">
              <button [disabled]="loading" class="btn btn-primary" rippleEffect>
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign up
              </button>
            </div>
          </div>
        </form>
        <p class="text-center mt-2">
          <span>Already have an account?</span><a routerLink="/pages/authentication/login-v2"><span>&nbsp;Sign in
              instead</span></a>
        </p>
      </div>
    </div>
    <!-- /Register-->
  </div>
</div>