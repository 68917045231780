<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <!-- Forgot Password v1 -->
    <div class="card mb-0">
      <div class="card-body">
        <a href="javascript:void(0);" class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
          <h2 class="brand-text text-primary ml-1">Vuexy</h2>
        </a>

        <h4 class="card-title mb-1">Forgot Password? 🔒</h4>
        <p class="card-text mb-2">Enter your email and we'll send you instructions to reset your password</p>

        <form class="auth-forgot-password-form mt-2" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="forgot-password-email" class="form-label">Email</label>
            <input
              type="text"
              class="form-control"
              placeholder="john@example.com"
              formControlName="email"
              aria-describedby="forgot-password-email"
              tabindex="1"
              autofocus
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="2" rippleEffect>Send reset link</button>
        </form>

        <p class="text-center mt-2">
          <a routerLink="/pages/authentication/login-v1">
            <i data-feather="chevron-left" class="mb-25"></i> Back to login
          </a>
        </p>
      </div>
    </div>
    <!-- /Forgot Password v1 -->
  </div>
</div>
