import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from 'app/auth/service';
import { ProjectService } from 'app/service/project/project.service';

@Component({
  selector: 'app-project-datatable',
  templateUrl: './project-datatable.component.html',
  styleUrls: ['./project-datatable.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectDatatableComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);

  public loadedData = false

  public projects;
  public currentProject
  private tempProjectData = [];
  public projectRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 5;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public projectForm: FormGroup;
  public projectSubmitted = false
  public loadingSubmitProject = false


  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private projectService: ProjectService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder
  ) { }

  // convenience getter for easy access to project form fields
  get m() {
    return this.projectForm.controls;
  }

  retrieveProjects(): void {
    this.projectService.getProjectsOfAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.projects = data;
          this.loadedData = true
          this.tempProjectData = this.projects;
          this.projectRows = this.projects;
          this.exportCSVData = this.projects;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.projectService.refreshProject.subscribe(() => {
      this.retrieveProjects()
    })
    this.retrieveProjects();

    //project
    this.projectForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      cin: ['', Validators.required],
      password: [''],
    });
  }

  modalOpen(modalForm, project) {
    this.currentProject = null
    this.projectForm.reset()
    this.modalService.open(modalForm);
    if (project) {
      this.currentProject = project
      this.projectForm.patchValue({
        email: project.email,
        role: project.role,
        cin: project.cin,
      });
    }
  }

  filterProjects(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempProjectData.filter(function (d) {
      return d.email.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.projectRows = temp;
  }

}
