<div class="text-center" *ngIf="!loadedData">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="loadedData">
    <div class='card col-12'>
        <div class="row m-2">
            <div class="col-9 row">
                <img *ngIf="currentProject.logo" src="{{ filePlacement }}/uploads/{{ currentProject.logo }}"
                    height="40" />
                <div class="col-8 m-0">
                    <h3>{{ currentProject.title }}: <small class="card-title">{{ currentProject.description }}</small>
                    </h3>

                    <small>
                        {{ currentProject.start_date | date: 'dd-MM-yyyy' }} /
                        {{ currentProject.end_date | date: 'dd-MM-yyyy' }}
                    </small>
                </div>
            </div>
            <div class="col-3 text-right align-middle">
                <button type="button" (click)="modalEditProjectOpen(modalEditProjectForm, currentProject)"
                    class="btn btn-outline-primary mr-1" rippleEffect>
                    <i data-feather="edit"></i>
                </button>
                <button type="button" class="btn btn-outline-danger" (click)="confirmDeleteProject(currentProject)"
                    rippleEffect>
                    <i data-feather="archive"></i>
                </button>
            </div>
        </div>
    </div>

    <div class='row justify-content-center'>
        <div class="card col-4 mx-1">
            <div class="card-body pb-50">
                <div class='row justify-content-center align-items-center '>
                    <div class='col-7 ps-'>
                        <h6>Total Balance For This Project</h6>
                        <h2 class="fw-bolder mb-1">{{ (totalProjectAmount).toFixed(2) }} DT</h2>
                        <div class="row">
                            <button type="button" class="btn btn-primary mr-1" rippleEffect
                                (click)="modalOpenLG(modalBudgets)">
                                Budgets
                            </button>
                            <button type="button" class="btn btn-primary" rippleEffect
                                (click)="modalOpen(modalBudgetForm)">
                                +
                            </button>
                        </div>
                    </div>
                    <div class='col-5'>
                        <img class='float-end' width="150"
                            src="https://img.freepik.com/free-vector/finances-management-budget-assessment-financial-literacy-accounting-idea-financier-with-cash-economist-holding-golden-coin-cartoon-character_335657-1604.jpg?t=st=1657896220~exp=1657896820~hmac=44670ae1eae2f3a1c71ee708bcfd0afbbcb8cb1b56e095b2c8a7aa07591c1ade&w=740" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card card-body pb-50 row mb-50">
                <div class='row'>
                    <div class='col-8'>
                        <h6> Total Bank balance</h6>
                        <h2 class="fw-bolder">{{ (totalBankAmount).toFixed(2) }} DT</h2>
                    </div>
                    <div class='col-2'>
                        <img width="80"
                            src="https://img.freepik.com/vecteurs-premium/cartes-credit-icone-carte-debit-credit-vecteur-vues-avant-arriere-systeme-technologie-paiement-sans-contact-maquettes-vectorielles-carte-credit-payer-acheter_435184-640.jpg?w=1060" />
                    </div>
                </div>
            </div>
            <div class="card card-body pb-50 row">
                <div class='row'>
                    <div class='col-8'>
                        <h6>Total Cash balance</h6>
                        <h2 class="fw-bolder">{{ (totalCashAmount).toFixed(2) }} DT</h2>
                    </div>
                    <div class='col-2'>
                        <img width="80"
                            src="https://img.freepik.com/premium-vector/banknote-gold-coins-with-silver-coins_383392-209.jpg?w=740 " />
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-4 mx-1" role="button" routerLink="/association/project/review/{{ currentProject.review }}">
            <div class="card-body pb-50">
                <div class='row justify-content-center align-items-center '>
                    <div class='col-6 ps-0'>
                        <h3 class="fw-bolder mb-1">Visit Project Statistics</h3>
                    </div>
                    <div class='col-6'>
                        <img class='float-end' width="160"
                            src="https://img.freepik.com/free-vector/clock-increasing-chart-workflow-productivity-increase-work-performance-optimization-efficiency-indicator-rising-effectiveness-metrics-vector-isolated-concept-metaphor-illustration_335657-2718.jpg?t=st=1658759098~exp=1658759698~hmac=fe4c6495f85362e91e938c65d1b87cf52b7e275210f5719ccd8ca7aba18382fe&w=740" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class='row justify-content-center'>
        <button data-toggle="tooltip" data-placement="top" data-html="true"
            title="for transfering monney from the bank account to the cash case" class="card col-2 mx-1 border-primary"
            routerLink="/association/project/new/operation/money_slice/{{ currentProject.id }}">
            <div class="card-body pb-50">
                <div class='col-12 text-center'>
                    <h6>Donate the following part of the budget</h6>
                    <img width="100"
                        src="https://img.freepik.com/vecteurs-premium/illustration-vectorielle-don-argent-du-telephone-soutenir-gens_509659-212.jpg?w=740" />
                </div>
            </div>
        </button>
        <button data-toggle="tooltip" data-placement="top" data-html="true"
            title="for transfering money from the bank account to the cash case" class="card col-2 mx-1 border-primary"
            (click)="checkOperation('withdrawal', currentProject.id)">
            <div class="card-body pb-50">
                <div class='col-12 text-center'>
                    <h6>Make A Cash Withdrawal</h6>
                    <img width="100" src="https://cdn-icons-png.flaticon.com/512/1086/1086733.png?w=740" />
                </div>
            </div>
        </button>
        <button data-toggle="modal" data-target="#modalCashTransfer"
            title="cash transactions will effect your budget's cash treasury" class="card col-2 mx-1 border-primary"
            (click)="checkOperation('cash', currentProject.id)">
            <div class="card-body pb-50">
                <div class='col-12 text-center'>
                    <h6>Make A Cash Transfer</h6>
                    <img width="100" src="https://cdn-icons-png.flaticon.com/512/1138/1138552.png?w=740" />
                </div>
            </div>
        </button>
        <button data-toggle="modal" data-target="#modalBankTransfer"
            title="bank transactions will effect your budget's bank account" class="card col-2 mx-1 border-primary"
            (click)="checkOperation('bank', currentProject.id)">
            <div class="card-body pb-50">
                <div class='col-12 text-center'>
                    <h6>Make A Bank Transfer</h6>
                    <img width="100" src="https://cdn-icons-png.flaticon.com/512/1138/1138510.png?w=740" />
                </div>
            </div>
        </button>
    </div>
    <!-- operation datatable -->
    <app-operation [project]="currentProject"></app-operation>
    <!--/ operation datatable -->

    <!-- line datatable -->
    <app-line [association]="currentAssociation" [project]="currentProject"></app-line>
    <!--/ line datatable -->

    <!-- activity datatable -->
    <app-activity [project]="currentProject.id"></app-activity>
    <!--/ activity datatable -->

</div>

<!-- Edit project Modal -->
<ng-template #modalEditProjectForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Edit {{ currentProject.title }}</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form [formGroup]="editProjectForm" (ngSubmit)="submitEditProject()">
            <label>Title: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="title"
                    [ngClass]="{ 'is-invalid': projectSubmitted && p.title.errors }" />
                <div *ngIf="projectSubmitted && p.title.errors" class="invalid-feedback">
                    <div *ngIf="p.title.errors.required">Title is required</div>
                </div>
            </div>
            <label>Description: </label>
            <div class="form-group">
                <textarea class="form-control" formControlName="description"
                    [ngClass]="{ 'is-invalid': projectSubmitted && p.description.errors }"></textarea>
                <div *ngIf="projectSubmitted && p.description.errors" class="invalid-feedback">
                    <div *ngIf="p.description.errors.required">Description is required</div>
                </div>
            </div>
            <label>Category: </label>
            <div class="form-group">
                <ng-select formControlName="category"
                    [ngClass]="{ 'is-invalid': projectSubmitted && p.category.errors }">
                    <ng-option *ngFor="let category of projectCategories" value="{{ category.title }}">
                        {{ category.title }}
                    </ng-option>
                </ng-select>
                <div *ngIf="projectSubmitted && p.category.errors" class="invalid-feedback">
                    <div *ngIf="p.category.errors.required">Category is required</div>
                </div>
            </div>
            <label>Time frame:</label>
            <div class="form-group">
                <ng2-flatpickr [config]="DateRangeOptions" name="DateRange"
                    placeholder="From {{ currentProject.start_date | date: 'dd-MM-yyyy' }} to {{ currentProject.end_date | date: 'dd-MM-yyyy' }}"
                    formControlName="time_frame"
                    [ngClass]="{ 'is-invalid': projectSubmitted && p.time_frame.errors }"></ng2-flatpickr>
                <div *ngIf="projectSubmitted && p.time_frame.errors" class="invalid-feedback">
                    <div *ngIf="p.time_frame.errors.required">Time frame is required</div>
                </div>
            </div>
            <label>Logo: </label>
            <div class="input-group mb-2">
                <div class="custom-file">
                    <input type="file" class="custom-file-input" (change)="selectLogo($event)" />
                    <label class="custom-file-label" for="banner">Choose a logo</label>
                </div>
                <img *ngIf="currentProject.logo" id="projectLogo"
                    src="{{ filePlacement }}/uploads/{{ currentProject.logo }}" width="40" />
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" [disabled]="loadingSubmitEditProject">
                    <span *ngIf="loadingSubmitEditProject" class="spinner-border spinner-border-sm mr-1"></span>
                    Submit
                </button>
            </div>
        </form>
    </div>
</ng-template>
<!-- / Edit project Modal -->

<!-- Budget Modal -->
<ng-template #modalBudgetForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">New budget</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form [formGroup]="budgetForm" (ngSubmit)="createBudget()">
            <label>Title: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="title" placeholder="Title"
                    [ngClass]="{ 'is-invalid': budgetSubmitted && b.title.errors }" />
                <div *ngIf="budgetSubmitted && b.title.errors" class="invalid-feedback">
                    <div *ngIf="b.title.errors.required">Title is required</div>
                </div>
            </div>
            <label>Account: </label>
            <div class="fomr-groupe">
                <ng-select formControlName="account" [ngClass]="{ 'is-invalid': budgetSubmitted && b.account.errors }">
                    <ng-option *ngFor="let account of currentAssociation.accounts" value="{{account.id}}">
                        {{ account.bank_name }}
                    </ng-option>
                </ng-select>
                <div *ngIf="budgetSubmitted && b.account.errors" class="invalid-feedback">
                    <div *ngIf="b.account.errors.required">Account is required</div>
                </div>
            </div>
            <label>Funder: </label>
            <div class="form-group">
                <ng-select formControlName="funder">
                    <ng-option *ngFor="let funder of funders" value="{{funder.id}}">
                        {{ funder.full_name }}
                    </ng-option>
                </ng-select>
            </div>
            <label>Amount: </label>
            <div class="form-group">
                <input type="number" class="form-control" formControlName="initial_amount" placeholder="Amount"
                    [ngClass]="{ 'is-invalid': budgetSubmitted && b.initial_amount.errors }" />
                <div *ngIf="budgetSubmitted && b.initial_amount.errors" class="invalid-feedback">
                    <div *ngIf="b.initial_amount.errors.required">Amount is required</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" [disabled]="loadingSubmitBudget">
                    <span *ngIf="loadingSubmitBudget" class="spinner-border spinner-border-sm mr-1"></span>
                    Submit
                </button>
            </div>
        </form>
    </div>
</ng-template>
<!-- / Budget Modal -->

<!-- Budgets Modal -->
<ng-template #modalBudgets let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Project budgets</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row justify-content-center mb-1">
            <div class="col-3" *ngFor="let budget of currentProject.budgets">
                <label>
                    <input type="radio" class="card-input-element" name="budget" />
                    <div class="card-input bg-light text-center p-1" role="button" (click)="getBudget(budget)">
                        <h6>{{ budget.title }}</h6>
                        In this budget you have
                        <small><b>{{ (budget.initial_amount).toFixed(2) }} DT</b></small>
                    </div>
                </label>
            </div>
        </div>
        <div *ngIf="currentBudget">
            <div class="row justify-content-center">
                <div class="d-flex align-items-center  mr-1">
                    <div class="avatar bg-light-success mr-1">
                        <div class="avatar-content">
                            <i data-feather="credit-card" class="font-medium-3"></i>
                        </div>
                    </div>
                    <strong>{{ (currentBudget.bank_amount).toFixed(2) }} DT</strong>
                </div>
                <div class="d-flex align-items-center mr-1">
                    <div class="avatar bg-light-success mr-1">
                        <div class="avatar-content">
                            <i data-feather="dollar-sign" class="font-medium-3"></i>
                        </div>
                    </div>
                    <strong>{{ (currentBudget.cash_amount).toFixed(2) }} DT</strong>
                </div>
            </div>
            <div class="progress-wrapper col-5 mb-1" *ngIf="sumOfMoneySlices">
                <div class="mb-25">Progress&hellip; {{calculateProgress }}%</div>
                <ngb-progressbar type="primary" [value]="calculateProgress"></ngb-progressbar>
            </div>
            <div class="progress-wrapper col-5 mb-1" *ngIf="currentBudget && sumOfMoneySlices == 0">
                <div class="mb-25">Progress&hellip; 0%</div>
                <ngb-progressbar type="primary" [value]="0"></ngb-progressbar>
            </div>
            <!-- ngb-alert component -->
            <ngb-alert [type]="'primary'" class="ml-1" [dismissible]="false">
                <div class="alert-body" *ngIf="currentBudget && sumOfMoneySlices == 0">
                    No slice yet, {{ remainingSlice }} DT to get.
                </div>
                <div class="alert-body" *ngIf="currentBudget && sumOfMoneySlices != 0">
                    There are <strong>{{ (sumOfMoneySlices).toFixed(2) }}</strong>
                    DT in your budget and <strong>
                        {{ (remainingSlice).toFixed(2) }}</strong>
                    DT left to come.
                </div>
            </ngb-alert>
            <!--/ ngb-alert component -->
            <form [formGroup]="budgetForm" (ngSubmit)="updateBudget()">
                <div class="row">
                    <div class="col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label>Budget title</label>
                            <input type="text" class="form-control" formControlName="title" />
                        </div>
                    </div>
                    <div class="col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label>Account</label>
                            <ng-select formControlName="account">
                                <ng-option *ngFor="let account of currentAssociation.accounts" value="{{account.id}}">
                                    {{ account.bank_name }}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label>Funder</label>
                            <ng-select formControlName="funder">
                                <ng-option *ngFor="let funder of funders" value="{{funder.id}}">
                                    {{ funder.full_name }}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label>Initial amount (DT)</label>
                            <input type="text" class="form-control" formControlName="initial_amount" />
                        </div>
                    </div>
                </div>
                <div class="col-12 row justify-content-center">
                    <button type="submit" class="btn btn-primary col-3 mr-1" [disabled]="loadingSubmitBudget">
                        <span *ngIf="loadingSubmitBudget" class="spinner-border spinner-border-sm mr-1"></span>
                        Update budget</button>
                    <button type="button" class="btn btn-danger col-3" (click)="confirmDeleteBudget(currentBudget)"
                        [disabled]="loadingDeleteBudget" rippleEffect>
                        <span *ngIf="loadingDeleteBudget" class="spinner-border spinner-border-sm mr-1"></span>
                        <i class="text-white" data-feather="trash-2"></i> Delete budget
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!-- / Budgets Modal -->

<!-- Cash transfer Modal -->
<ng-template #modalCashTransfer let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Choose the type of the operation</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row m-1">
            <div class="col-6 operationModal" role="button"
                routerLink="/association/project/new/operation/cash/payement/{{ currentProject.id }}"
                (click)="modal.dismiss('Cross click')">
                <img class="img-fluid"
                    src="https://img.freepik.com/free-vector/cash-payment-concept-illustration_114360-3320.jpg?t=st=1658438234~exp=1658438834~hmac=84cf239fcf7cee9250f338faaa198d35d7617c8b9253e3161435985ae31899e5&w=740">
                <div class="text-center">
                    <h4>Cash payement</h4>
                    <div>This type of operation will withdraw money from your budget</div>
                </div>
            </div>
            <div class="col-6 operationModal" role="button"
                routerLink="/association/project/new/operation/cash/donation/{{ currentProject.id }}"
                (click)="modal.dismiss('Cross click')">
                <img class="img-fluid"
                    src="https://img.freepik.com/free-vector/chargeback-abstract-concept-illustration_335657-3904.jpg?t=st=1658438837~exp=1658439437~hmac=fbe8cd9fa71d83572081f103c403533a271063f248f992be0b0888fffc404b8a&w=740">
                <div class="text-center">
                    <h4>Cash donation</h4>
                    <div>This type of operation will deposit money into your budget</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Cash transfer Modal -->

<!-- Bank transfer Modal -->
<ng-template #modalBankTransfer let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Choose the type of the operation</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row m-1">
            <div class="col-6 operationModal" role="button"
                routerLink="/association/project/new/operation/bank/payement/{{ currentProject.id }}"
                (click)="modal.dismiss('Cross click')">
                <img class="img-fluid"
                    src="https://img.freepik.com/vecteurs-premium/illustrations-vectorielles-dessin-anime-isole-virement-bancaire_107173-21789.jpg?w=740"
                    height="100px">
                <div class="text-center">
                    <h4>Bank payement</h4>
                    <div>This type of operation will withdraw money from your budget</div>
                </div>
            </div>
            <div class="col-6 operationModal" role="button"
                routerLink="/association/project/new/operation/bank/donation/{{ currentProject.id }}"
                (click)="modal.dismiss('Cross click')">
                <img class="img-fluid"
                    src="https://img.freepik.com/free-vector/chargeback-abstract-concept-illustration_335657-3904.jpg?t=st=1658438837~exp=1658439437~hmac=fbe8cd9fa71d83572081f103c403533a271063f248f992be0b0888fffc404b8a&w=740">
                <div class="text-center">
                    <h4>Bank donation</h4>
                    <div>This type of operation will deposit money into your budget</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Bank transfer Modal -->