import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';
import { ProjectService } from 'app/service/project/project.service';
import { ActivityService } from 'app/service/activity/activity.service';

@Component({
  selector: 'app-cash-operation',
  templateUrl: './cash-operation.component.html',
  styleUrls: ['./cash-operation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CashOperationComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  loadedData = false

  public currentProject;
  public currentBudget;
  public remainingCashAmount: number
  public remainingBankAmount: number

  public operationType: string

  constructor(
    private authenticationService: AuthenticationService,
    private projectService: ProjectService,
    private activityService: ActivityService,
    private route: ActivatedRoute) { }

  getProjectData(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.projectService.getProject(id)
      .subscribe({
        next: (data) => {
          this.currentProject = data;
          this.currentBudget = this.currentProject.budgets[0]
          this.remainingBankAmount = 0; this.remainingCashAmount = 0
          for (let b = 0; b < this.currentProject.budgets.length; b++) {
            this.remainingBankAmount += parseFloat(this.currentProject.budgets[b].bank_amount)
            this.remainingCashAmount += parseFloat(this.currentProject.budgets[b].cash_amount)
          }
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.operationType = this.route.snapshot.paramMap.get('type');

    this.activityService.refreshActivity.subscribe(() => {
      this.getProjectData();
    })
    this.getProjectData();
  }

}
