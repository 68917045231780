<div class="card" *ngIf="step == 1">
    <div class="card-header">
        <h4 style="color: #FF649D">Please provide the project informations: </h4>
    </div>
    <div class="card-body">
        <form [formGroup]="createProjectForm" class="form form-horizontal">
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Title</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="text" class="form-control" formControlName="title" placeholder="Title"
                                [ngClass]="{ 'is-invalid': projectSubmitted && p.title.errors }" />
                            <div *ngIf="projectSubmitted && p.title.errors" class="invalid-feedback">
                                <div *ngIf="p.title.errors.required">Title is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Description</label>
                        </div>
                        <div class="col-sm-6">
                            <textarea class="form-control" formControlName="description" placeholder="Description"
                                [ngClass]="{ 'is-invalid': projectSubmitted && p.description.errors }">
                                </textarea>
                            <div *ngIf="projectSubmitted && p.description.errors" class="invalid-feedback">
                                <div *ngIf="p.description.errors.required">Description is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Category</label>
                        </div>
                        <div class="col-sm-6">
                            <ng-select formControlName="category"
                                [ngClass]="{ 'is-invalid': projectSubmitted && p.category.errors }">
                                <ng-option *ngFor="let category of projectCategories" value="{{category.title}}">
                                    {{ category.title }}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="projectSubmitted && p.category.errors" class="invalid-feedback">
                                <div *ngIf="p.category.errors.required">Category is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Time frame</label>
                        </div>
                        <div class="col-sm-6">
                            <ng2-flatpickr [config]="DateRangeOptions" name="DateRange" placeholder="From .. to"
                                formControlName="time_frame"
                                [ngClass]="{ 'is-invalid': projectSubmitted && p.time_frame.errors }"></ng2-flatpickr>
                            <div *ngIf="projectSubmitted && p.time_frame.errors" class="invalid-feedback">
                                <div *ngIf="p.time_frame.errors.required">Time frame is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Logo</label>
                        </div>
                        <div class="col-sm-6">
                            <div class="input-group input-group-merge">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" formControlName="logo"
                                        (change)="selectLogo($event)" />
                                    <label class="custom-file-label">Choose a logo</label>
                                </div>
                                <img *ngIf="logo" id="logo" src="" width="40" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="card-footer text-right">
        <button type="submit" class="btn btn-primary" (click)="next()">
            Next add a budget
        </button>
    </div>
</div>

<div class="card" *ngIf="step == 2">
    <div class="card-header">
        <h4 style="color: #FF649D">Now let's add a budget to our project: </h4>
    </div>
    <div class="card-body">
        <form [formGroup]="createBudgetForm" class="form form-horizontal">
            <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Title</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="text" class="form-control" formControlName="title" placeholder="Title"
                                [ngClass]="{ 'is-invalid': budgetSubmitted && b.title.errors }" />
                            <div *ngIf="budgetSubmitted && b.title.errors" class="invalid-feedback">
                                <div *ngIf="b.title.errors.required">Title is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Account</label>
                        </div>
                        <div class="col-sm-6">
                            <ng-select formControlName="account"
                                [ngClass]="{ 'is-invalid': budgetSubmitted && b.account.errors }">
                                <ng-option *ngFor="let account of accounts" value="{{account.id}}">
                                    {{ account.bank_name }}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="budgetSubmitted && b.account.errors" class="invalid-feedback">
                                <div *ngIf="b.account.errors.required">Account is required</div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-outline-primary" (click)="modalOpenForm(modalAccountForm)"
                            rippleEffect>+</button>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Funder</label>
                        </div>
                        <div class="col-sm-6">
                            <ng-select formControlName="funder">
                                <ng-option *ngFor="let funder of funders" value="{{funder.id}}">
                                    {{ funder.full_name }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-sm-3 col-form-label">
                            <label>Amount</label>
                        </div>
                        <div class="col-sm-6">
                            <input type="number" class="form-control" formControlName="initial_amount"
                                placeholder="Amount"
                                [ngClass]="{ 'is-invalid': budgetSubmitted && b.initial_amount.errors }" />
                            <div *ngIf="budgetSubmitted && b.initial_amount.errors" class="invalid-feedback">
                                <div *ngIf="b.initial_amount.errors.required">Amount is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="card-footer text-right">
        <button type="button" class="btn btn-secondary mr-1" (click)="previous()">
            Previous
        </button>
        <button type="submit" class="btn btn-primary" [disabled]="loadingSubmitProject" (click)="submitReview()">
            <span *ngIf="loadingSubmitProject" class="spinner-border spinner-border-sm mr-1"></span>
            Submit
        </button>
    </div>
</div>

<!-- Account Modal -->
<ng-template #modalAccountForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add a new account</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="createAccountForm" (ngSubmit)="submitAccount()">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label>Bank name: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="bank_name" placeholder="Bank name"
                    [ngClass]="{ 'is-invalid': accountSubmitted && a.bank_name.errors }" />
                <div *ngIf="accountSubmitted && a.bank_name.errors" class="invalid-feedback">
                    <div *ngIf="a.bank_name.errors.required">Bank name is required</div>
                </div>
            </div>

            <label>Initial balance (DT): </label>
            <div class="form-group">
                <input type="number" class="form-control" formControlName="initial_balance"
                    placeholder="initial Balance"
                    [ngClass]="{ 'is-invalid': accountSubmitted && a.initial_balance.errors }" />
                <div *ngIf="accountSubmitted && a.initial_balance.errors" class="invalid-feedback">
                    <div *ngIf="a.initial_balance.errors.required">Initial balance is required</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="loadingSubmitAccount" class="btn btn-primary"> <span
                    *ngIf="loadingSubmitAccount" class="spinner-border spinner-border-sm mr-1"></span>
                Submit
            </button>
        </div>
    </form>
</ng-template>
<!-- / Account Modal -->