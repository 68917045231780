import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private _refreshAccount = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshAccount() {
    return this._refreshAccount;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/accounts`);
  }

  getAccountsByAssociation(id) {
    return this._httpClient.get(`${environment.apiUrl}/accounts_by_association/${id}`);
  }

  create(association, bank_name, initial_balance) {
    let current_balance = initial_balance
    return this._httpClient
      .post(`${environment.apiUrl}/create/accounts`, { association, bank_name, initial_balance, current_balance })
      .pipe(
        tap(() => {
          this._refreshAccount.next()
        })
      );
  }

  update(id, bank_name) {
    return this._httpClient
      .put(`${environment.apiUrl}/update/accounts/${id}`, { bank_name })
      .pipe(
        tap(() => {
          this._refreshAccount.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/delete/accounts/${id}`)
      .pipe(
        tap(() => {
          this._refreshAccount.next()
        })
      );
  }
}
