import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResponsibleService {

  private _refreshResponsible = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshResponsible() {
    return this._refreshResponsible;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/member`);
  }

  getResponsiblesOfFunder(funder) {
    return this._httpClient.get(`${environment.apiUrl}/liste/responsible_by_funder/${funder}`);
  }

  create(funder, email, role, cin, password) {
    return this._httpClient
      .post(`${environment.apiUrl}/create/responsible`, { funder, email, role, cin, password })
      .pipe(
        tap(() => {
          this._refreshResponsible.next()
        })
      );
  }

  update(id, email, role, cin) {
    return this._httpClient
      .put(`${environment.apiUrl}/update/responsible/${id}`, { email, role, cin })
      .pipe(
        tap(() => {
          this._refreshResponsible.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/delete/responsible/${id}`)
      .pipe(
        tap(() => {
          this._refreshResponsible.next()
        })
      );
  }
}
