import { ParameterService } from 'app/service/parameter/parameter.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntil, first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AuthenticationService } from 'app/auth/service';
import { CoreConfigService } from '@core/services/config.service';
import { NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap/datepicker/adapters/ngb-date-adapter';

@Component({
  selector: 'app-auth-register-v2-association',
  templateUrl: './auth-register-v2-association.component.html',
  styleUrls: ['./auth-register-v2-association.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthRegisterV2AssociationComponent implements OnInit {
  // Public
  public coreConfig: any;
  public passwordTextType: boolean;
  public registerAssociationForm: FormGroup;
  public submitted = false;
  public loading = false;
  public returnUrl: string;
  public error = '';
  public basicDPdata: NgbDateStructAdapter;
  public banner
  public logo;
  public categories

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(private _coreConfigService: CoreConfigService, private parameterService: ParameterService,
    private _router: Router, private _formBuilder: FormBuilder, private _authenticationService: AuthenticationService) {
    this._unsubscribeAll = new Subject();

    // redirect to home if already logged in
    if (this._authenticationService.currentUserValue) {
      this._router.navigate(['/']);
    }

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerAssociationForm.controls;
  }
  uploadBanner(event) {
    this.banner = event.target.files[0];
  }
  uploadLogo(event) {
    this.logo = event.target.files[0];
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  /**
   * On Submit
   */
  onSubmit() {

    this.submitted = true;
    this.loading = true;


    // stop here if form is invalid
    if (this.registerAssociationForm.invalid) {
      return;
    }
    let date = `${this.f.fundation_date.value.day}/${this.f.fundation_date.value.month}/${this.f.fundation_date.value.year}`
    // Register association
    this._authenticationService
      .registerAssociation(
        this.f.title.value, this.f.description.value,
        this.f.address.value, this.f.association_website.value,
        this.banner, this.f.reference_jort.value,
        date, this.logo,
        this.f.category.value, this.f.cin.value,
        this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this._authenticationService.login(this.f.email.value, this.f.password.value).pipe(first())
            .subscribe(
              data => {
                this.loading = false;

              },
              error => {
                this.error = error;
              }
            );
        },
        error => {
          this.error = error;
        }
      );
  }

  getAssociationCategory() {
    this.parameterService.getAssociationCategoryParameter()
      .subscribe({
        next: (response) => {
          this.categories = response
        },
        error: (e) => console.error(e)
      });
  }
  /**
   * On init
   */
  ngOnInit(): void {
    this.getAssociationCategory()
    this.registerAssociationForm = this._formBuilder.group({
      title: ['', [Validators.required]], description: ['', [Validators.required]],
      address: ['', [Validators.required]], association_website: ['', [Validators.required]],
      banner: ['', [Validators.required]], reference_jort: ['', [Validators.required]],
      fundation_date: ['', [Validators.required]], logo: ['', [Validators.required]],
      cin: ['', [Validators.required]], category: [''],
      email: ['', [Validators.required, Validators.email]], password: ['', Validators.required]
    });

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
