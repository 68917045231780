import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { takeUntil, first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AuthenticationService } from 'app/auth/service';
import { CoreConfigService } from '@core/services/config.service';

@Component({
  selector: 'app-auth-register-v2-funder',
  templateUrl: './auth-register-v2-funder.component.html',
  styleUrls: ['./auth-register-v2-funder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthRegisterV2FunderComponent implements OnInit {
  // Public
  public coreConfig: any;
  public logo;
  public passwordTextType: boolean;
  public registerFunderForm: FormGroup;
  public submitted = false;
  public loading = false
  public returnUrl: string;
  public error = '';

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(private _coreConfigService: CoreConfigService, private _router: Router, private _formBuilder: FormBuilder, private _authenticationService: AuthenticationService) {
    this._unsubscribeAll = new Subject();

    // redirect to home if already logged in
    if (this._authenticationService.currentUserValue) {
      this._router.navigate(['/']);
    }

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerFunderForm.controls;
  }
  uploadLogo(event) {
    this.logo = event.target.files[0];
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.registerFunderForm.invalid) {
      return;
    }
    // Register funder
    this._authenticationService
      .registerFunder(this.f.fullName.value, this.f.phone.value, this.f.reference.value, this.logo, this.f.cin.value, this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this._authenticationService.login(this.f.email.value, this.f.password.value).pipe(first())
            .subscribe(
              data => {
                this.loading = false
              },
              error => {
                this.error = error;
              }
            );
        },
        error => {
          this.error = error;
        }
      );
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.registerFunderForm = this._formBuilder.group({
      fullName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      reference: ['', [Validators.required]],
      logo: ['', [Validators.required]],
      cin: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
