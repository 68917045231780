import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  private _refreshReview = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshReview() {
    return this._refreshReview;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/review`);
  }

  getProjectReview(id) {
    return this._httpClient.get(`${environment.apiUrl}/review/${id}`);
  }

  getBudgetReview(id) {
    return this._httpClient.get(`${environment.apiUrl}/budget_review/${id}`);
  }

  create(association, title) {
    return this._httpClient
      .post(`${environment.apiUrl}/create/review`, { association, title })
      .pipe(
        tap(() => {
          this._refreshReview.next()
        })
      );
  }
}
