import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  //public
  public currentUser: Observable<User>;
  public currentUserEmail: string;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient, private _router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // getter: currentUserValue
  public get currentUserData() {
    return localStorage.getItem('currentUser');
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is association
   */
  get isAssociation() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Association;
  }

  /**
   *  Confirms if user is funder
   */
  get isFunder() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Funder;
  }

  /**
   * association register
   * @returns association
   */
  registerAssociation(title, description, address, website, banner, reference_jort, fundation_date, logo, category, cin, email, password) {
    const formData: FormData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('address', address);
    formData.append('association_website', website);
    formData.append('banner', banner);
    formData.append('reference_jort', reference_jort);
    formData.append('fundation_date', fundation_date);
    formData.append('logo', logo);
    formData.append('association_category', category);
    formData.append('cin', cin);
    formData.append('email', email);
    formData.append('password', password);
    return this._http
      .post<any>(`${environment.apiUrl}/create/associations`, formData)
      .pipe(
        map(association => {
          // login successful if there's a jwt token in the response
          if (association && association.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(association));
            this._router.navigate(['/']);


            // notify
            this.currentUserSubject.next(association);
          }

          return association;
        })
      );
  }

  /**
  * funder register
  * @returns funder
  */
  registerFunder(fullName, phone, reference, logo, cin, email, password) {
    const formData: FormData = new FormData();
    formData.append('fullName', fullName);
    formData.append('phone', phone);
    formData.append('reference', reference);
    formData.append('logo', logo);
    formData.append('cin', cin);
    formData.append('email', email);
    formData.append('password', password);
    return this._http
      .post<any>(`${environment.apiUrl}/create/funder`, formData)
      .pipe(
        map(funder => {
          // login successful if there's a jwt token in the response
          if (funder && funder.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(funder));
            this._router.navigate(['/']);

            // notify
            this.currentUserSubject.next(funder);
          }

          return funder;
        })
      );
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(username: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/login`, { username, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this._router.navigate(['/']);

            // notify
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  refreshToken(username) {
    return this._http
      .post<any>(`${environment.apiUrl}/refresh/token`, { username })
  }

  forgetPassword(email) {
    return this._http
      .post<any>(`${environment.apiUrl}/forget/password`, { email })
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }


}
