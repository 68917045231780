import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProofService {

  private _refreshProof = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshProof() {
    return this._refreshProof;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/proof`);
  }

  getProof(id) {
    return this._httpClient.get(`${environment.apiUrl}/proof/${id}`);
  }

  create(operation, title, description, operationProof) {
    const formData: FormData = new FormData();
    formData.append('operation', operation);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('operationProof', operationProof);
    return this._httpClient
      .post(`${environment.apiUrl}/create/proof`, formData)
      .pipe(
        tap(() => {
          this._refreshProof.next()
        })
      );
  }

  // edit(id, title, description, logo) {
  //   const formData: FormData = new FormData();
  //   formData.append('title', title);
  //   formData.append('description', description);
  //   formData.append('logo', logo);
  //   return this._httpClient.put(`${environment.apiUrl}/update/project/${id}`, formData);
  // }
}
