<!-- operation card -->
<div class="card" *ngIf="loadedData">
    <h2 class="mt-1 ml-1" style="color: #FF649D">{{ project ? 'Project':'Budget'}} operations</h2>
    <div class="row">
        <div class="col-12 mb-50">
            <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary float-right mr-1" rippleEffect>
                Export CSV</a>
            <div class="row d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <button type="button" class="btn btn-outline-primary round btn-sm mr-1" rippleEffect
                    (click)="getAll()">All</button>
                <button type="button" class="btn btn-outline-primary btn-sm round" rippleEffect
                    (click)="filterByLabel('cash_payement')">Cash payements</button>
            </div>
        </div>
        <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center">Show
                    <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    entries</label>
            </div>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                        class="form-control ml-25" (keyup)="filterOperations($event)"
                        (search)="filterOperations($event)" /></label>
            </div>
        </div>
    </div>

    <!-- Operation Datatables -->
    <ngx-datatable [rows]="operationRows" [rowHeight]="58" class="bootstrap core-bootstrap"
        [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
        [limit]="basicSelectedOption">
        <ngx-datatable-column name="" [width]="90" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <container-element [ngSwitch]="row.label">
                    <span *ngSwitchCase="'cash_payement'">
                        <i data-feather="dollar-sign" [size]="15" class="text-primary"></i>
                        <i data-feather="minus" [size]="20" class="text-danger"></i>
                    </span>
                    <span *ngSwitchCase="'bank_payement'">
                        <i data-feather="credit-card" [size]="15" class="text-warning"></i>
                        <i data-feather="minus" [size]="20" class="text-danger"></i>
                    </span>
                    <span *ngSwitchCase="'cash_donation'">
                        <i data-feather="dollar-sign" [size]="15" class="text-primary"></i>
                        <i data-feather="plus" [size]="20" class="text-success"></i>
                    </span>
                    <span *ngSwitchCase="'bank_donation'">
                        <i data-feather="credit-card" [size]="15" class="text-warning"></i>
                        <i data-feather="plus" [size]="20" class="text-success"></i>
                    </span>
                    <span *ngSwitchCase="'money_slice'">
                        <i data-feather="credit-card" [size]="15" class="text-warning"></i>
                        <i data-feather="plus" [size]="20" class="text-success"></i>
                    </span>
                    <span *ngSwitchDefault>
                        <i data-feather="credit-card" [size]="15" class="text-warning"></i>
                        <i data-feather="dollar-sign" [size]="15" class="text-primary"></i>
                        <i data-feather="arrow-right" [size]="15" class="text-secondary"></i>
                    </span>
                </container-element>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Title" prop="title" [width]="120"></ngx-datatable-column>
        <ngx-datatable-column name="reference" prop="reference" [width]="120"></ngx-datatable-column>
        <ngx-datatable-column name="Amount" prop="amount" [width]="120"></ngx-datatable-column>
        <ngx-datatable-column name="Budget" prop="budget_title" [width]="120"></ngx-datatable-column>
        <ngx-datatable-column name="Activity" prop="activity_title" [width]="120"></ngx-datatable-column>
        <ngx-datatable-column name="Line" prop="line_title" [width]="120"></ngx-datatable-column>
        <ngx-datatable-column name="Date" prop="date" [width]="120"></ngx-datatable-column>
        <ngx-datatable-column name="Details" [width]="120" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <div class="d-flex align-items-center">
                    <a class="btn btn-raised btn-outline-primary btn-sm mr-1"
                        routerLink="/association/detail/operation/{{row.id}}" rippleEffect>
                        <i data-feather="file-text"></i>
                    </a>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <!--/ Operation Datatables -->
</div>
<!--/ operation card -->