<form [formGroup]="createDonationOperationForm" class="form form-horizontal" (ngSubmit)="confirmOperation()">
    <div class="form-group">
        <div class="col-sm-3 col-form-label">
            <label>Choose a budget:</label>
        </div>
        <div class="row justify-content-center text-center">
            <div class="card col-3 mr-1 budgetOption" *ngFor="let budget of currentProject.budgets">
                <label>
                    <input type="radio" [value]="budget" class="card-input-element" formControlName="budget"
                        [ngClass]="{ 'is-invalid': donationOperationSubmitted && d_o.budget.errors }" />
                    <div class="card card-input border-secondary bg-light p-1">
                        <h4>{{ budget.title }}</h4>
                        In this budget you have
                        <span><b>{{ budget.cash_amount }} DT</b> in cash </span>
                        and
                        <span><b>{{ budget.cash_amount + budget.bank_amount }} DT</b> in total.</span>
                    </div>
                    <div *ngIf="donationOperationSubmitted && d_o.budget.errors" class="invalid-feedback">
                        <div *ngIf="d_o.budget.errors.required">Budget is required</div>
                    </div>
                </label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Operation name:</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="title"
                        [ngClass]="{ 'is-invalid': donationOperationSubmitted && d_o.title.errors }" />
                    <div *ngIf="donationOperationSubmitted && d_o.title.errors" class="invalid-feedback">
                        <div *ngIf="d_o.title.errors.required">Title is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Note</label>
                </div>
                <div class="col-sm-6">
                    <textarea class="form-control" formControlName="note"></textarea>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Operation reference (cheque or transfer number):</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" class="form-control" formControlName="reference"
                        [ngClass]="{ 'is-invalid': donationOperationSubmitted && d_o.reference.errors }" />
                    <div *ngIf="donationOperationSubmitted && d_o.reference.errors" class="invalid-feedback">
                        <div *ngIf="d_o.reference.errors.required">Reference is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Domain</label>
                </div>
                <div class="col-sm-6">
                    <select formControlName="domain" class="form-control"
                        [ngClass]="{ 'is-invalid': donationOperationSubmitted && d_o.domain.errors }">
                        <ng-container *ngFor="let subdomain of subdomains">
                            <optgroup label="{{subdomain.domain.title}}">
                                <option value="{{subdomain.domain.id}}">
                                    {{ subdomain.title }}
                                </option>
                            </optgroup>
                        </ng-container>
                    </select>
                    <div *ngIf="donationOperationSubmitted && d_o.domain.errors" class="invalid-feedback">
                        <div *ngIf="d_o.domain.errors.required">Domain is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Operation date</label>
                </div>
                <div class="col-sm-6">
                    <div class="input-group input-group-merge">
                        <input type="date" class="form-control" formControlName="operation_date" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                    <label>Amount</label>
                </div>
                <div class="col-sm-6">
                    <input type="number" class="form-control" formControlName="amount"
                        [ngClass]="{ 'is-invalid': donationOperationSubmitted && d_o.amount.errors }" />
                    <div *ngIf="donationOperationSubmitted && d_o.amount.errors" class="invalid-feedback">
                        <div *ngIf="d_o.amount.errors.required">Amount is required</div>
                    </div>
                </div>
            </div>
        </div>
        <app-proof objectType="project" [objectId]="currentProject.id" (checkProof)="handleProof($event)"></app-proof>
    </div>
    <div class="card-footer text-right">
        <button type="submit" class="btn btn-success" [disabled]="loadingSubmitCashDonation">
            <span *ngIf="loadingSubmitCashDonation" class="spinner-border spinner-border-sm mr-1"></span>
            Submit
        </button>
    </div>
</form>