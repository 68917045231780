<div class="text-center" *ngIf="!currentDomain">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="currentDomain">
    <ul class="timeline">
        <li class="timeline-item">
            <span class="timeline-point">
                <i data-feather="edit-2" [size]="15"></i>
            </span>
            <div class="timeline-event card">
                <h2 class="mt-1 ml-1 text-primary">Update "{{currentDomain.title}}"</h2>
                <form class="card-body" [formGroup]="domainForm" (ngSubmit)="updateDomain()">
                    <label>Title: </label>
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="title"
                            [ngClass]="{ 'is-invalid': domainSubmitted && d.title.errors }" />
                        <div *ngIf="domainSubmitted && d.title.errors" class="invalid-feedback">
                            <div *ngIf="d.title.errors.required">Title is required</div>
                        </div>
                    </div>
                    <label>Description: </label>
                    <div class="form-group">
                        <textarea type="text" class="form-control" formControlName="description"
                            [ngClass]="{ 'is-invalid': domainSubmitted && d.description.errors }"></textarea>
                        <div *ngIf="domainSubmitted && d.description.errors" class="invalid-feedback">
                            <div *ngIf="d.description.errors.required">Description is required</div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" [disabled]="loadingSubmitDomain">
                            <span *ngIf="loadingSubmitDomain" class="spinner-border spinner-border-sm mr-1"></span>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </li>
        <li class="timeline-item">
            <span class="timeline-point timeline-point-warning">
                <i data-feather="plus" [size]="30"></i>
            </span>
            <div class="timeline-event">
                <app-subdomain [domain]="currentDomain"></app-subdomain>
            </div>
        </li>
    </ul>
</div>