import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { DomainService } from 'app/service/domain/domain.service';
import { AlertService } from 'app/service/alert/alert.service';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DomainComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  @Input() section;

  public currentDomain

  public domains;
  private tempDomainData = [];
  public domainRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 5;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public domainForm: FormGroup;
  public domainSubmitted = false
  public loadingSubmitDomain = false

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private domainService: DomainService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private alertService: AlertService,
    private _router: Router
  ) { }

  // convenience getter for easy access to domain form fields
  get a() {
    return this.domainForm.controls;
  }

  calculateExpensesInDomain(operations) {
    let sum = 0
    for (let o = 0; o < operations.length; o++) {
      sum += parseFloat(operations[o].amount)
    }
    return sum
  }

  deleteDomain(res, id): void {
    if (res.value == true) {
      this.domainService.delete(id)
        .subscribe({
          next: (data) => {
            console.log(data)
          },
          error: (e) => console.error(e)
        });
    }
  }

  createDomain() {
    this.domainSubmitted = true
    if (this.domainForm.invalid) {
      return
    } else {
      this.loadingSubmitDomain = true
      this.domainService.create(this.currentUser.association_id, this.a.title.value, this.a.description.value)
        .subscribe({
          next: (domain) => {
            this.domainSubmitted = false
            this.loadingSubmitDomain = false
            this.modalService.dismissAll()
            this._router.navigate([`/association/detail/domain/${domain}`]);
          },
          error: (e) => {
            this.loadingSubmitDomain = false
            this.domainSubmitted = false
            console.error(e)
          }
        });
    }
  }

  retrieveDomains(): void {
    this.domainService.getDomainsByAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.domains = data;
          this.tempDomainData = this.domains;
          this.domainRows = this.domains;
          this.exportCSVData = this.domains;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.domainService.refreshDomain.subscribe(() => {
      this.retrieveDomains()
    })
    this.retrieveDomains();

    //domain
    this.domainForm = this._formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  modalOpen(modalForm, domain) {
    this.currentDomain = null
    this.domainForm.reset()
    this.modalService.open(modalForm);
    if (domain) {
      this.currentDomain = domain
      this.domainForm.patchValue({
        title: domain.title,
        description: domain.description,
      });
    }
  }

  filterDomains(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempDomainData.filter(function (d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.domainRows = temp;
  }

  confirmDeleteAlert(domain) {
    let operationName = ''
    if (domain.operations.length != 0) {
      operationName = domain.operations.map(function (item) { return item.title; }).join(", ");
      this.alertService.errorAlert(`Cant delete ${domain.title}`, `This line is related to ${operationName} operations !`)
    } else {
      Swal.fire({
        title: `Are you sure you want to delete ${domain.title}?`,
        text: 'You wont be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(res => this.deleteDomain(res, domain.id));
    }
  }

}
