import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from 'app/auth/service';
import { BudgetService } from 'app/service/budget/budget.service';

@Component({
  selector: 'app-budget-datatable',
  templateUrl: './budget-datatable.component.html',
  styleUrls: ['./budget-datatable.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BudgetDatatableComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);

  public loadedData = false

  public budgets;
  public currentBudget
  private tempBudgetData = [];
  public budgetRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 5;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public budgetForm: FormGroup;
  public budgetSubmitted = false
  public loadingSubmitBudget = false


  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private budgetService: BudgetService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder
  ) { }

  // convenience getter for easy access to budget form fields
  get m() {
    return this.budgetForm.controls;
  }

  retrieveBudgets(): void {
    this.budgetService.getBudgetsOfAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.budgets = data;
          this.loadedData = true
          this.tempBudgetData = this.budgets;
          this.budgetRows = this.budgets;
          this.exportCSVData = this.budgets;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.budgetService.refreshBudget.subscribe(() => {
      this.retrieveBudgets()
    })
    this.retrieveBudgets();

    //budget
    this.budgetForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      cin: ['', Validators.required],
      password: [''],
    });
  }

  modalOpen(modalForm, budget) {
    this.currentBudget = null
    this.budgetForm.reset()
    this.modalService.open(modalForm);
    if (budget) {
      this.currentBudget = budget
      this.budgetForm.patchValue({
        email: budget.email,
        role: budget.role,
        cin: budget.cin,
      });
    }
  }

  filterBudgets(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempBudgetData.filter(function (d) {
      return d.email.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.budgetRows = temp;
  }

}
