import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProjectService } from 'app/service/project/project.service';
import { ActivityService } from 'app/service/activity/activity.service';
import { ParameterService } from 'app/service/parameter/parameter.service';

@Component({
  selector: 'app-bank-operation',
  templateUrl: './bank-operation.component.html',
  styleUrls: ['./bank-operation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BankOperationComponent implements OnInit {

  loadedData = false

  public operationType: string

  public currentProject;
  public transactionTypes

  public remainingCashAmount: number
  public remainingBankAmount: number

  constructor(
    private projectService: ProjectService,
    private activityService: ActivityService,
    private parameterService: ParameterService,
    private route: ActivatedRoute) { }


  getTransactionType() {
    this.parameterService.getTransactionTypeParameter()
      .subscribe({
        next: (response) => {
          this.transactionTypes = response
        },
        error: (e) => console.error(e)
      });
  }

  getProjectData(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.projectService.getProject(id)
      .subscribe({
        next: (data) => {
          this.currentProject = data;
          this.remainingBankAmount = 0; this.remainingCashAmount = 0
          for (let b = 0; b < this.currentProject.budgets.length; b++) {
            this.remainingBankAmount += parseFloat(this.currentProject.budgets[b].bank_amount)
            this.remainingCashAmount += parseFloat(this.currentProject.budgets[b].cash_amount)
          }
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.operationType = this.route.snapshot.paramMap.get('type');

    this.activityService.refreshActivity.subscribe(() => {
      this.getProjectData();
    })
    this.getProjectData();

    this.getTransactionType();
  }

}
