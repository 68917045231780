<!-- account setting page -->
<section id="page-account-settings" *ngIf="loadedData">
    <div class="row nav-vertical d-flex">
        <div class="col-md-3 mb-2 mb-md-0">
            <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
                <li class="nav-item" ngbNavItem>
                    <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-general" data-toggle="pill"
                        href="#account-vertical-general" aria-expanded="true">
                        <i data-feather="user" class="font-medium-3 mr-1"></i>
                        <span class="font-weight-bold">Association informations</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card">
                            <div class="card-body">
                                <div class="col-12">
                                    <div class="card profile-header mb-2">
                                        <img class="card-img-top"
                                            src="{{ filePlacement }}/uploads/{{ currentAssociation.banner }}"
                                            height="250px" />
                                        <div class="position-relative">
                                            <div class="profile-img-container d-flex align-items-center">
                                                <div class="profile-img">
                                                    <img src="{{ filePlacement }}/uploads/{{ currentAssociation.logo }}"
                                                        class="rounded img-fluid" alt="Card image" />
                                                </div>
                                                <div class="profile-title ml-3">
                                                    <h2>{{ currentAssociation.title }}</h2>
                                                    <p>{{ currentAssociation.description }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem>
                    <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-password" data-toggle="pill"
                        href="#account-vertical-password" aria-expanded="false">
                        <i data-feather="edit" class="font-medium-3 mr-1"></i>
                        <span class="font-weight-bold">Edit profile</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card">
                            <div class="card-body">
                                <!-- form -->
                                <form [formGroup]="updateAssociationForm" class="validate-form"
                                    (ngSubmit)="submitUpdateAssociation()">
                                    <div class="row">
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label>Name</label>
                                                <input type="text" class="form-control" formControlName="title" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input type="email" class="form-control" formControlName="email" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label>Fundation date</label>
                                                <div class="input-group input-group-merge">
                                                    <input class="form-control" formControlName="fundation_date" placeholder="{{currentAssociation.fundation_date}}"
                                                        [(ngModel)]="basicDPdata" ngbDatepicker
                                                        #basicDP="ngbDatepicker" />
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary feather icon-calendar"
                                                            (click)="basicDP.toggle()" type="button"
                                                            rippleEffect></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label>Category</label>
                                                <input type="text" class="form-control" formControlName="category"
                                                    disabled />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label>Description</label>
                                                <textarea class="form-control" formControlName="description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label>Address</label>
                                                <input type="text" class="form-control" formControlName="address" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label>Reference jort</label>
                                                <input type="text" class="form-control"
                                                    formControlName="reference_jort" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label>Website</label>
                                                <input type="text" class="form-control" formControlName="website" />
                                            </div>
                                        </div>
                                        <div class="col-12 text-right">
                                            <button type="submit" class="btn btn-primary mt-2 mr-1" rippleEffect>
                                                <span *ngIf="loadingSubmit"
                                                    class="spinner-border spinner-border-sm mr-1"></span>
                                                Save changes</button>
                                            <button type="reset" class="btn btn-outline-secondary mt-2"
                                                rippleEffect>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                                <!--/ form -->
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
        <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
    </div>
</section>
<!--/ account setting page -->