<div class="col-12">
    <div class="form-group row">
        <div class="col-sm-2 col-form-label">
            <label>Operation proof</label>
        </div>
        <div class="col-sm-10">
            <form class="invoice-repeater">
                <div *ngFor="let proof of proofs; let i = index" @heightIn>
                    <div class="row">
                        <div class="form-group mr-1">
                            <input type="text" class="form-control" [(ngModel)]="proof.proofTitle"
                                name="proofTitle{{ i }}" id="proofTitle{{ i }}" placeholder="Title"
                                [ngClass]="{ 'is-invalid': proofSubmitted && p.title.errors }" />
                            <div *ngIf="proofSubmitted && p.title.errors" class="invalid-feedback">
                                <div *ngIf="p.title.errors.required">Title is required</div>
                            </div>
                        </div>
                        <div class="form-group mr-1">
                            <input type="text" class="form-control" [(ngModel)]="proof.proofDescription"
                                name="proofDescription{{ i }}" id="proofDescription{{ i }}" placeholder="Description"
                                [ngClass]="{ 'is-invalid': proofSubmitted && p.description.errors }" />
                            <div *ngIf="proofSubmitted && p.description.errors" class="invalid-feedback">
                                <div *ngIf="p.description.errors.required">Description is required</div>
                            </div>
                        </div>
                        <div class="form-group mr-1">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="proofFile{{ i }}"
                                    name="proofFile{{ i }}" [(ngModel)]="proof.proofFile"
                                    (change)="selectOperationProof($event)" accept="image/jpeg,image/gif,image/png,application/pdf"
                                    [ngClass]="{ 'is-invalid': proofSubmitted && p.operationProof.errors }" />
                                <label class="custom-file-label">Choose a file</label>

                                <div *ngIf="proofSubmitted && p.operationProof.errors" class="invalid-feedback">
                                    <div *ngIf="p.operationProof.errors.required">File is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-icon btn-outline-danger" (click)="deleteItem(i)" rippleEffect>
                                <i data-feather="x"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <button class="btn btn-icon btn-primary mr-1" type="button" (click)="addItem()" rippleEffect>
                    <i data-feather="plus"></i>
                </button>
            </form>
        </div>
    </div>
</div>