import { Role } from 'app/auth/models';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { DatePickerI18nModule } from 'app/main/forms/form-elements/date-time-picker/date-picker-i18n/date-picker-i18n.module';
import { CsvModule } from '@ctrl/ngx-csv';

import { AuthGuard } from 'app/auth/helpers';

import { FunderDashboardComponent } from './funder-dashboard/funder-dashboard.component';
import { ResponsibleComponent } from './responsible/responsible.component';
import { DetailProjectComponent } from './detail-project/detail-project.component';

import { CommonComponentsModule } from 'app/common-components/common-components.module';

const routes = [
  {
    path: 'dashboard',
    component: FunderDashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Funder] },
  },
  {
    path: 'list/responsible',
    component: ResponsibleComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Funder] }
  },
  {
    path: 'detail/project/:id',
    component: DetailProjectComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Funder] }
  },
];

@NgModule({
  declarations: [
    FunderDashboardComponent,
    ResponsibleComponent,
    DetailProjectComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgxDatatableModule,
    CsvModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    DatePickerI18nModule,
    SweetAlert2Module.forRoot(),
    CommonComponentsModule
  ],
  providers: [],
  exports: [],
})
export class FunderModule { }
