<div class="text-center" *ngIf="!loadedData">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div class="card" *ngIf="loadedData">
    <div class="row">
        <div class="col-md-6 col-12">
            <h2 class="mt-1 ml-1" style="color: #FF649D">Association budgets</h2>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <button class="btn btn-primary float-right m-1 mr-2" routerLink="/association/new/budget" rippleEffect>
                Add New budget
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center">Show
                    <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    entries</label>
            </div>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                        class="form-control ml-25" (keyup)="filterBudgets($event)"
                        (search)="filterBudgets($event)" /></label>
            </div>
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary mr-1" rippleEffect>
                    Export CSV</a>
            </div>
        </div>
    </div>

    <!-- Budgets Datatable -->
    <ngx-datatable [rows]="budgetRows" [rowHeight]="58" class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force"
        [limit]="basicSelectedOption" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true">
        <ngx-datatable-column name="Title" prop="title" [width]="250"></ngx-datatable-column>
        <ngx-datatable-column name="Initial Amount (Dt)" prop="initial_amount" [width]="90"></ngx-datatable-column>
        <ngx-datatable-column name="Current Amount (Dt)" prop="current_amount" [width]="90"></ngx-datatable-column>
        <ngx-datatable-column name="Cash Amount (Dt)" prop="cash_amount" [width]="90"></ngx-datatable-column>
        <ngx-datatable-column name="Bank Amount (Dt)" prop="bank_amount" [width]="90"></ngx-datatable-column>
        <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <div class="d-flex align-items-center">
                    <button type="button" routerLink="/association/edit/budget/{{ row.id }}"
                        class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                        <i data-feather="edit"></i>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <!--/ Budgets Datatable -->
</div>