<div class="text-center" *ngIf="!accounts">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div class="card" *ngIf="accounts">
    <div class="row">
        <div class="col-md-6 col-12">
            <h2 class="mt-1 ml-1" style="color: #FF649D">Association accounts</h2>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <button class="btn btn-primary float-right m-1 mr-2" (click)="modalOpen(modalAccountForm)" rippleEffect>
                Add New account
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center">Show
                    <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    entries</label>
            </div>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                        class="form-control ml-25" (keyup)="filterAccounts($event)"
                        (search)="filterAccounts($event)" /></label>
            </div>
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary mr-1" rippleEffect>
                    Export CSV</a>
            </div>
        </div>
    </div>

    <!-- Accounts Datatable -->
    <ngx-datatable [rows]="accountRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="5"
        [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
        [limit]="basicSelectedOption">
        <ngx-datatable-column name="Bank name" prop="bank_name" [width]="250"></ngx-datatable-column>
        <ngx-datatable-column name="Initial Balance" [width]="120">
            <ng-template ngx-datatable-cell-template let-row="row" class="text-right">
                {{ row.initial_balance.toFixed(2) }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Current Balance" [width]="120">
            <ng-template ngx-datatable-cell-template let-row="row" class="text-right">
                {{ row.current_balance.toFixed(2) }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <div class="d-flex align-items-center">
                    <button type="button" (click)="modalOpen(modalAccountForm, row)"
                        class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                        <i data-feather="edit"></i>
                    </button>
                    <button type="button" (click)="confirmDeleteAccount(row)"
                        class="btn btn-raised btn-outline-danger btn-sm" rippleEffect>
                        <i data-feather="trash"></i>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <!--/ Accounts Datatable -->
</div>

<!-- Account Modal -->
<ng-template #modalAccountForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add a new account</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="accountForm" (ngSubmit)="submitAccount()">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label>Bank name: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="bank_name" placeholder="Bank name"
                    [ngClass]="{ 'is-invalid': accountSubmitted && a.bank_name.errors }" />
                <div *ngIf="accountSubmitted && a.bank_name.errors" class="invalid-feedback">
                    <div *ngIf="a.bank_name.errors.required">Bank name is required</div>
                </div>
            </div>
            <div *ngIf="!currentAccount">
                <label>Initial balance (DT): </label>
                <div class="form-group">
                    <input type="number" class="form-control" formControlName="initial_balance"
                        placeholder="initial Balance" [required]="!currentAccount"
                        [ngClass]="{ 'is-invalid': accountSubmitted && a.initial_balance.errors }" />
                    <div *ngIf="accountSubmitted && a.initial_balance.errors" class="invalid-feedback">
                        <div *ngIf="a.initial_balance.errors.required">Initial balance is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="loadingSubmitAccount" class="btn btn-primary">
                <span *ngIf="loadingSubmitAccount" class="spinner-border spinner-border-sm mr-1"></span>
                Submit
            </button>
        </div>
    </form>
</ng-template>
<!-- / Account Modal -->