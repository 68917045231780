import { Injectable } from '@angular/core';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  errorAlert(title, text) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Okay',
      customClass: {
        confirmButton: 'btn btn-primary mr-1',
        cancelButton: 'btn btn-outline-danger ms-1'
      },
      buttonsStyling: false
    })
  }

  confirmAlert(title, text, methodToExecute) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => methodToExecute(res));
  }
}
