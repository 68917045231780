import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  private _refreshMember = new Subject<void>();

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) { }

  get refreshMember() {
    return this._refreshMember;
  }

  getAll() {
    return this._httpClient.get(`${environment.apiUrl}/liste/member`);
  }

  getMembersOfAssociation(association) {
    return this._httpClient.get(`${environment.apiUrl}/liste/member_by_association/${association}`);
  }

  create(association, email, role, cin, password) {
    return this._httpClient
      .post(`${environment.apiUrl}/create/member`, { association, email, role, cin, password })
      .pipe(
        tap(() => {
          this._refreshMember.next()
        })
      );
  }

  update(id, email, role, cin) {
    return this._httpClient
      .put(`${environment.apiUrl}/update/member/${id}`, { email, role, cin })
      .pipe(
        tap(() => {
          this._refreshMember.next()
        })
      );
  }

  delete(id) {
    return this._httpClient
      .delete(`${environment.apiUrl}/delete/member/${id}`)
      .pipe(
        tap(() => {
          this._refreshMember.next()
        })
      );
  }

}
