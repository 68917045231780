import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { MemberService } from 'app/service/member/member.service';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MemberComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);

  public loadedData = false

  public members;
  public currentMember
  private tempMemberData = [];
  public memberRows: any;
  public exportCSVData

  public ColumnMode = ColumnMode;
  public basicSelectedOption: number = 5;
  public SelectionType = SelectionType;
  public selected = [];
  public chkBoxSelected = [];

  public memberForm: FormGroup;
  public memberSubmitted = false
  public loadingSubmitMember = false


  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private memberService: MemberService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder
  ) { }

  // convenience getter for easy access to member form fields
  get m() {
    return this.memberForm.controls;
  }

  deleteMember(res, id) {
    if (res.value == true) {
      this.memberService.delete(id)
        .subscribe({
          next: (response) => {
          },
          error: (e) => console.error(e)
        });
    }
  }

  updateMember() {
    this.memberSubmitted = true
    if (this.memberForm.invalid) {
      console.log(this.memberForm)
      return
    } else {
      this.loadingSubmitMember = true
      this.memberService.update(this.currentMember.id, this.m.email.value, this.m.role.value, this.m.cin.value)
        .subscribe({
          next: (response) => {
            this.memberSubmitted = false
            this.loadingSubmitMember = false
            this.modalService.dismissAll()
            this.memberForm.reset()
          },
          error: (e) => {
            this.loadingSubmitMember = false
            this.memberSubmitted = false
            console.error(e)
          }
        });
    }
  }

  createMember() {
    this.memberSubmitted = true
    if (this.memberForm.invalid) {
      return
    } else {
      this.loadingSubmitMember = true
      this.memberService.create(this.currentUser.association_id, this.m.email.value, this.m.role.value, this.m.cin.value, this.m.password.value)
        .subscribe({
          next: (response) => {
            this.memberSubmitted = false
            this.loadingSubmitMember = false
            this.modalService.dismissAll()
            this.memberForm.reset()
          },
          error: (e) => {
            this.loadingSubmitMember = false
            this.memberSubmitted = false
            console.error(e)
          }
        });
    }
  }

  submitMember() {
    if (this.currentMember) {
      this.updateMember()
    } else {
      this.createMember()
    }
  }

  retrieveMembers(): void {
    this.memberService.getMembersOfAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.members = data;
          this.loadedData = true
          this.tempMemberData = this.members;
          this.memberRows = this.members;
          this.exportCSVData = this.members;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.memberService.refreshMember.subscribe(() => {
      this.retrieveMembers()
    })
    this.retrieveMembers();

    //member
    this.memberForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      cin: ['', Validators.required],
      password: [''],
    });
  }

  modalOpen(modalForm, member) {
    this.currentMember = null
    this.memberForm.reset()
    this.modalService.open(modalForm);
    if (member) {
      this.currentMember = member
      this.memberForm.patchValue({
        email: member.email,
        role: member.role,
        cin: member.cin,
      });
    }
  }

  filterMembers(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.tempMemberData.filter(function (d) {
      return d.email.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.memberRows = temp;
  }

  onSelectMember({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
  }

  confirmDeleteMember(member) {
    Swal.fire({
      title: `Are you sure you want to delete ${member.email}?`,
      text: 'You wont be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(res => this.deleteMember(res, member.id));
  }
}
