import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'environments/environment';

import { AuthenticationService } from 'app/auth/service';
import { AssociationService } from 'app/service/association/association.service';
import { AccountService } from 'app/service/account/account.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  filePlacement = environment.FilePlacementUrl

  public loadedData = false
  public loadingSubmitAccount = false

  public currentAssociation

  public accounts = false
  public projects = false
  public associationTotalAmount = 0
  public associationCashAmount = 0
  public associationBankAmount = 0

  public createAccountForm: FormGroup;
  accountSubmitted = false

  constructor(
    private authenticationService: AuthenticationService,
    private associationService: AssociationService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder
  ) {
  }

  // convenience getter for easy access to account form fields
  get a() {
    return this.createAccountForm.controls;
  }

  submitAccount() {
    this.accountSubmitted = true
    if (this.createAccountForm.invalid) {
      return;
    } else {
      this.loadingSubmitAccount = true
      this.accountService.create(this.currentUser.association_id, this.a.bank_name.value, this.a.initial_balance.value)
        .subscribe({
          next: (response) => {
            this.loadingSubmitAccount = false
            this.accountSubmitted = false
            this.accounts = true
            this.modalService.dismissAll()
            this.createAccountForm.reset()
          },
          error: (e) => {
            this.loadingSubmitAccount = false
            console.error(e)
          }
        });
    }
  }

  getAssociationData(): void {
    this.associationService.getAssociation(this.currentUser.association_id)
      .subscribe({
        next: (data) => {
          this.currentAssociation = data;
          if (this.currentAssociation.accounts.length != 0) {
            this.accounts = true
            for (let a = 0; a < this.currentAssociation.accounts.length; a++) {
              this.associationTotalAmount += parseFloat(this.currentAssociation.accounts[a].current_balance)
              if (this.currentAssociation.accounts[a].budgets.length != 0) {
                for (let b = 0; b < this.currentAssociation.accounts[a].budgets.length; b++) {
                  this.associationTotalAmount += parseFloat(this.currentAssociation.accounts[a].budgets[b].current_amount)
                  this.associationCashAmount += parseFloat(this.currentAssociation.accounts[a].budgets[b].cash_amount)
                  this.associationBankAmount += parseFloat(this.currentAssociation.accounts[a].budgets[b].bank_amount)
                }
              }
            }
          }
          if (this.currentAssociation.projects.length != 0) {
            this.projects = true
          }
          this.loadedData = true
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit() {
    this.accountService.refreshAccount.subscribe(() => {
      this.getAssociationData()
    })
    this.getAssociationData()

    this.createAccountForm = this._formBuilder.group({
      bank_name: ['', [Validators.required]],
      initial_balance: ['', Validators.required],
    });
  }

  modalOpenForm(modalForm) {
    this.modalService.open(modalForm);
  }

}
