import { Component, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';
import { ParameterService } from 'app/service/parameter/parameter.service';
import { OperationService } from 'app/service/operation/operation.service';
import { AlertService } from 'app/service/alert/alert.service';
import { ProofComponent } from '../../operation/proof/proof.component';
import { DomainService } from 'app/service/domain/domain.service';
import { BudgetService } from 'app/service/budget/budget.service';

@Component({
  selector: 'app-budget-bank-payement',
  templateUrl: './budget-bank-payement.component.html',
  styleUrls: ['./budget-bank-payement.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BudgetBankPayementComponent implements OnInit {

  currentUser = Object.freeze(this.authenticationService.currentUserValue);
  @ViewChild(ProofComponent) child !: any;
  @ViewChildren(ProofComponent)
  parseFloat = parseFloat

  public currentBudget
  public budgets
  public transactionTypes
  public domains
  public subdomains

  public bankProof
  public proofExist = false

  public createPayementOperationForm: FormGroup;
  public payementOperationSubmitted = false
  public loadingSubmitBankPayement = false

  constructor(
    private authenticationService: AuthenticationService,
    private budgetService: BudgetService,
    private operationService: OperationService,
    private domainService: DomainService,
    private parameterService: ParameterService,
    private _formBuilder: FormBuilder,
    private alertService: AlertService,
    private route: ActivatedRoute) { }

  // convenience getter for easy access to payement operation form fields
  get p_o() {
    return this.createPayementOperationForm.controls;
  }

  getBankProof(event) {
    this.bankProof = event.target.files[0];
  }

  submitBankPayement(res) {
    if (res.value == true) {
      this.loadingSubmitBankPayement = true
      let label = 'bank_payement'
      if (this.p_o.budget_receiver.value != null && this.p_o.budget_receiver.value != '') {
        label = 'bank_transfert'
      }
      let bank_amount = this.currentBudget.bank_amount - this.p_o.amount.value
      this.operationService.bankPayement(this.currentUser.id, null, this.currentBudget.id, this.p_o.budget_receiver.value, label, this.p_o.title.value, this.p_o.note.value, this.p_o.reference.value, this.p_o.transaction_type.value, null, null, this.p_o.operation_date.value, null, this.p_o.domain.value, this.p_o.amount.value, this.currentBudget.cash_amount, bank_amount, this.bankProof)
        .subscribe({
          next: (operation) => {
            this.child.submitProof(operation)
            this.loadingSubmitBankPayement = false
            this.payementOperationSubmitted = false
          },
          error: (e) => {
            this.loadingSubmitBankPayement = false
            this.payementOperationSubmitted = false
            console.error(e)
          }
        });
    }
  }


  confirmOperation() {
    this.payementOperationSubmitted = true
    if (this.createPayementOperationForm.invalid) {
      return
    } else {
      if (this.proofExist) {
        Swal.fire({
          title: 'Are you sure you want to go through this transaction ?',
          text: `${this.p_o.amount.value} DT, will be debited from your budget !`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          confirmButtonText: 'Yes, commit it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary ml-1'
          }
        }).then(res => this.submitBankPayement(res));
      } else {
        this.alertService.errorAlert('You cant commit this yet', 'You have to import at least one justification file');
      }
    }
  }

  handleProof(e) {
    if (!e) {
      this.proofExist = false
    } else {
      this.proofExist = true
    }
  }

  getTransactionType() {
    this.parameterService.getTransactionTypeParameter()
      .subscribe({
        next: (response) => {
          this.transactionTypes = response
        },
        error: (e) => console.error(e)
      });
  }

  getDomains(): void {
    this.domainService.getAll()
      .subscribe({
        next: (data) => {
          this.domains = data
          this.subdomains = this.domains.flatMap(item => item.subdomains)
        },
        error: (e) => console.error(e)
      });
  }

  getBudgets(): void {
    this.budgetService.getAll()
      .subscribe({
        next: (data) => {
          this.budgets = data;
        },
        error: (e) => console.error(e)
      });
  }

  getBudgetData(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.budgetService.getBudget(id)
      .subscribe({
        next: (data) => {
          this.currentBudget = data;
        },
        error: (e) => console.error(e)
      });
  }

  ngOnInit(): void {
    this.getBudgetData();
    this.getBudgets();
    this.getTransactionType()
    this.getDomains();

    //payement operation
    this.createPayementOperationForm = this._formBuilder.group({
      budget_receiver: [''],
      title: ['', Validators.required],
      note: [''],
      reference: ['', Validators.required],
      transaction_type: ['', Validators.required],
      domain: ['', Validators.required],
      material: [''],
      operation_date: [''],
      amount: ['', Validators.required],
      bank_proof: ['', Validators.required],
    });
  }
}