<div class="card">
    <div class="row">
        <div class="col-md-6 col-12">
            <h2 class="mt-1 ml-1 text-primary">Subdomains of "{{domain.title}}"</h2>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <button class="btn btn-primary float-right m-1 mr-2" (click)="modalOpen(modalSubdomainForm, null)"
                rippleEffect>
                Add new subdomain
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center">Afficher
                    <select class="form-control mx-25" [(ngModel)]="basicSelectedSubdomain">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    entrées</label>
            </div>
        </div>
        <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center">Recherche<input type="search" placeholder="recherche"
                        class="form-control ml-25" (keyup)="filterSubdomains($event)"
                        (search)="filterSubdomains($event)" /></label>
            </div>
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary mr-1" rippleEffect>
                    Exporter CSV</a>
            </div>
        </div>
    </div>

    <!-- Subdomains Datatable -->
    <ngx-datatable [rows]="domain.subdomains" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="5"
        [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
        [selectionType]="SelectionType.checkbox" [limit]="basicSelectedSubdomain">
        <ngx-datatable-column name="Titre" prop="title" [width]="100"></ngx-datatable-column>
        <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <div class="d-flex align-items-center">
                    <button type="button" (click)="modalOpen(modalSubdomainForm, row)"
                        class="btn btn-raised btn-outline-primary btn-sm mr-1" rippleEffect>
                        <i data-feather="edit"></i>
                    </button>
                    <button type="button" (click)="confirmDeleteSubdomain(row)"
                        class="btn btn-raised btn-outline-danger btn-sm" rippleEffect>
                        <i data-feather="trash"></i>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <!--/ Subdomains Datatable -->
</div>

<!-- Subdomain Modal -->
<ng-template #modalSubdomainForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">
            {{ currentSubdomain ? ('Update ' + currentSubdomain.title) : 'Add new subdomain'}}
        </h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="subdomainForm" (ngSubmit)="submitSubdomain()">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <label>Titre: </label>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="title" placeholder="Titre"
                    [ngClass]="{ 'is-invalid': subdomainSubmitted && o.title.errors }" />
                <div *ngIf="subdomainSubmitted && o.title.errors" class="invalid-feedback">
                    <div *ngIf="o.title.errors.required">Titre est requis</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="loadingSubmitSubdomain" class="btn btn-primary">
                <span *ngIf="loadingSubmitSubdomain" class="spinner-border spinner-border-sm mr-1"></span>
                Enregistrer
            </button>
        </div>
    </form>
</ng-template>
<!-- / Subdomain Modal -->